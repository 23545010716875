import { ERenewVisaCondition } from "@/features/renew-visa/enums/renewVisaCondition";
import { TRenewVisaUploadFileForm } from "@/features/renew-visa/types/renewVisaUploadFileForm";
import { COMPANY_DIVISION, TCompanyDivision } from "@/types/enum";

export type TUploadFileWithCondition = {
  key: keyof TRenewVisaUploadFileForm;
  presentScreenLabel: string;
  uploadScreenLabel: string;
  subUploadScreenLabel?: string;
  companySpecificIndustryIdsCondition?: number[];
  companyDivisionCondition?: TCompanyDivision[];
  tooltipContent?: string;
  required: boolean;
  isExistKey?: "isExistEmploymentContractCopyFilePath" | "isExistEmploymentConditionsCopyFilePaths" | "isExistWagePaymentFilePath";
  multiple?: boolean;
  maxFiles?: number;
  condition: ERenewVisaCondition | ERenewVisaCondition[];
  children?: {
    key: keyof TRenewVisaUploadFileForm;
    uploadScreenLabel: string;
    tooltipContent?: string;
  }[];
  onlyShowWhenHasFile?: boolean;
};

type TGroupUploadFileItem = {
  label: string;
  subLabel: string;
  value: TUploadFileWithCondition[];
};

type TRenewVisaUploadApplicationFile = {
  GROUP_ONE: TGroupUploadFileItem;
  GROUP_TWO: TGroupUploadFileItem;
  GROUP_THREE: TGroupUploadFileItem;
  GROUP_FOUR: TGroupUploadFileItem;
  GROUP_FIVE: TGroupUploadFileItem;
  GROUP_SIX: TGroupUploadFileItem;
};

export const RENEW_VISA_UPLOAD_APPLICATION_FILE: TRenewVisaUploadApplicationFile = {
  GROUP_ONE: {
    label: "毎回更新および提出する書類（新規取得・作成）",
    subLabel: "役所等から書類を取得、または新規で作成が必要な書類を添付します。",
    value: [
      {
        key: "applicantIndividualResidentTaxCertFilePath",
        presentScreenLabel: "申請人の個人住民税の課税証明書",
        uploadScreenLabel: "申請人の個人住民税の課税証明書",
        tooltipContent: "直近1年分が必要。",
        required: true,
        condition: ERenewVisaCondition.ALL,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "applicantResidentTaxPaymentCertFilePath",
        presentScreenLabel: "申請人の住民税の納税証明書",
        uploadScreenLabel: "申請人の住民税の納税証明書",
        required: true,
        tooltipContent: "全ての納期が経過している直近1年度のものが必要。課税証明書と同一年度でない場合もあり発行手続の際に注意。",
        condition: ERenewVisaCondition.ALL,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "applicantWithholdingSlipCopyFilePath",
        presentScreenLabel: "申請人の給与所得の源泉徴収票の写し",
        uploadScreenLabel: "申請人の給与所得の源泉徴収票の写し",
        required: true,
        tooltipContent: "課税証明書で証明されている内容に対応する年度のもの。",
        condition: ERenewVisaCondition.ALL,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "applicantHealthInsuranceCardCopyFilePath",
        presentScreenLabel: "申請人の国民健康保険被保険者証の写し",
        uploadScreenLabel: "申請人の国民健康保険被保険者証の写し",
        required: false,
        tooltipContent: "保険者番号及び被保険者等記号・番号を申請人側でマスキング(黒塗り)すること。",
        condition: ERenewVisaCondition.ALL,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "applicantHealthInsurancePaymentCertFilePath",
        presentScreenLabel: "申請人の国民健康保険料(税)納付証明書",
        uploadScreenLabel: "申請人の国民健康保険料(税)納付証明書",
        required: false,
        tooltipContent: "・保険者番号及び被保険者等記号・番号を申請人側でマスキング(黒塗り)すること。\n・直近１年分が必要",
        condition: ERenewVisaCondition.ALL,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "applicantPensionReceiptCopy1FilePath",
        presentScreenLabel: "申請人の国民年金保険料領収証書または被保険者記録照会",
        uploadScreenLabel: "次の①か②の書類を添付してください",
        required: false,
        tooltipContent: "申請の日の属する月の前々月までの２４か月分が必要。基礎年金番号を申請人側でマスキング(黒塗り)すること。",
        condition: ERenewVisaCondition.ALL,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
        children: [
          {
            key: "applicantPensionReceiptCopy1FilePath",
            uploadScreenLabel: "①申請人の国民年金保険料領収証書の写し",
            tooltipContent: "申請の日の属する月の前々月までの２４か月分が必要。基礎年金番号を申請人側でマスキング(黒塗り)すること。",
          },
          {
            key: "applicantPensionRecordInquiry2FilePath",
            uploadScreenLabel: "②申請人の被保険者記録照会(納付Ⅱ)(被保険者記録照会回答票を含む。)",
            tooltipContent: "基礎年金番号を申請人側でマスキング(黒塗り)すること。",
          },
        ],
      },
      {
        key: "previousApplicationDocumentsFilePath",
        presentScreenLabel: "前回申請時に履行すべきであった公的義務に係る書類",
        uploadScreenLabel: "前回申請時に履行すべきであった公的義務に係る書類",
        required: false,
        tooltipContent: "前回申請時、10の参考様式第1-26号等を提出し、納税義務の履行等を誓約した場合に提出が必要。",
        condition: ERenewVisaCondition.ALL,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "publicDutyPledgeFilePath",
        presentScreenLabel: "公的義務履行に関する誓約書",
        uploadScreenLabel: "（参考様式第1-26）公的義務履行に関する誓約書",
        tooltipContent: "6～8までのいずれかに滞納がある場合にのみ提出。",
        required: false,
        condition: ERenewVisaCondition.ALL,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "publicDutyComplianceFilePath",
        presentScreenLabel: "公的義務履行に関する説明書",
        uploadScreenLabel: "（参考様式第1-27）公的義務履行に関する説明書",
        tooltipContent:
          "(注)上記5から8までに関し、「△(注５)」の適用により、提出不要の適用を受ける場合に必要\n※5から8までのいずれについても滞納がない場合に限る。",
        required: false,
        condition: [],
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "immigrationElectronicSystemPledgeFilePath",
        presentScreenLabel: "出入国在留管理庁電子届出システムに関する誓約書",
        uploadScreenLabel: "（参考様式第1-30）出入国在留管理庁電子届出システムに関する誓約書",
        required: true,
        condition: ERenewVisaCondition.E,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "documentOmissionPledgeFilePath",
        presentScreenLabel: "書類の省略に当たっての誓約書",
        uploadScreenLabel: "（参考様式第1-29）書類の省略に当たっての誓約書",
        required: true,
        condition: [ERenewVisaCondition.A, ERenewVisaCondition.B, ERenewVisaCondition.C, ERenewVisaCondition.D, ERenewVisaCondition.E],
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
    ],
  },
  GROUP_TWO: {
    label: "毎回提出する書類（会社保有）",
    subLabel: "会社に関する書類として、最新のものを添付します。変更がない場合は、そのまま添付します。",
    value: [
      {
        key: "foreignWorkerCompensationStatementFilePaths",
        multiple: true,
        maxFiles: 2,
        presentScreenLabel: "特定技能外国人の報酬に関する説明書",
        uploadScreenLabel: "（参考様式第1-4）特定技能外国人の報酬に関する説明書",
        required: true,
        tooltipContent: "賃金規定に基づき報酬を決定した場合には賃金規定を添付。",
        condition: [],
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "employmentContractCopyFilePath",
        presentScreenLabel: "特定技能雇用契約書の写し",
        uploadScreenLabel: "（参考様式第1-5）特定技能雇用契約書の写し",
        required: true,
        isExistKey: "isExistEmploymentContractCopyFilePath",
        tooltipContent: "雇用当初のもの。",
        condition: ERenewVisaCondition.ALL,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "employmentConditionsCopyFilePaths",
        presentScreenLabel: "雇用条件書の写し",
        multiple: true,
        maxFiles: 3,
        isExistKey: "isExistEmploymentConditionsCopyFilePaths",
        uploadScreenLabel: "（参考様式第1-6）雇用条件書の写し",
        required: true,
        tooltipContent: "最新版（雇用当初から変更なければ、当初のもの）",
        subUploadScreenLabel:
          "(注)１年単位の変形労働時間制を採用している場合は次のものも添付\n・申請人が十分に理解できる言語が併記された年間カレンダーの写し\n・１年単位の変形労働時間制に関する協定書の写し",
        condition: ERenewVisaCondition.ALL,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "wagePaymentFilePath",
        presentScreenLabel: "賃金の支払",
        uploadScreenLabel: "（参考様式第1-6別紙）賃金の支払",
        required: true,
        isExistKey: "isExistWagePaymentFilePath",
        tooltipContent: "最新版（雇用当初から変更なければ、当初のもの）",
        condition: ERenewVisaCondition.ALL,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "quarterlyReportCopyFilePath",
        presentScreenLabel: "四季報の写し又は日本の証券取引所に上場していることを証明する文書（写し）",
        uploadScreenLabel: "四季報の写し又は日本の証券取引所に上場していることを証明する文書（写し）",
        condition: ERenewVisaCondition.A,
        required: true,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "innovationCompanyProofFilePath",
        required: true,
        presentScreenLabel:
          "高度専門職省令第１条第１項各号の表の特別加算の項の中欄イ又はロの対象企業（イノベーション創出企業）であることを証明する文書",
        uploadScreenLabel:
          "高度専門職省令第１条第１項各号の表の特別加算の項の中欄イ又はロの対象企業（イノベーション創出企業）であることを証明する文書（例えば、補助金交付決定通知書の写し）",
        condition: ERenewVisaCondition.B,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "certifiedCompanyProofFilePath",
        required: true,
        presentScreenLabel: "「一定の条件を満た企業等」であることを証明する文書",
        uploadScreenLabel: "「一定の条件を満た企業等」であることを証明する文書（例えば、認定証等の写し）",
        condition: ERenewVisaCondition.C,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "annualSalaryReportCopyFilePath",
        presentScreenLabel: "前年分の職員の給与所得の源泉徴収票等の法定調書合計表",
        required: true,
        uploadScreenLabel: "前年分の職員の給与所得の源泉徴収票等の法定調書合計表（受付印のあるものの写し）",
        condition: ERenewVisaCondition.D,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "careWorkplaceOverviewFilePath",
        presentScreenLabel: "介護分野における業務を行わせる事業所の概要書",
        required: true,
        uploadScreenLabel: "（分野様式第1-2）介護分野における業務を行わせる事業所の概要書",
        condition: ERenewVisaCondition.ALL,
        companySpecificIndustryIdsCondition: [12],
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "councilMembershipCertFilePath",
        presentScreenLabel: "協議会の構成員であることの証明書",
        required: true,
        uploadScreenLabel: "協議会の構成員であることの証明書",
        condition: ERenewVisaCondition.ALL,
        companySpecificIndustryIdsCondition: [12, 11, 10, 8, 6, 5, 4, 3, 2, 1],
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "specifiedSkillAutomobileMaintenanceCertFilePath",
        presentScreenLabel: "協議会の構成員であることの証明書",
        uploadScreenLabel: "①か②のいずれか",
        condition: ERenewVisaCondition.ALL,
        required: true,
        companySpecificIndustryIdsCondition: [7],
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
        children: [
          {
            key: "specifiedSkillAutomobileMaintenanceCertFilePath",
            uploadScreenLabel: "①自動車整備分野特定技能協議会入会届出書兼構成員資格証明書（受付印があるもの）",
          },
          {
            key: "specifiedSkillAutomobileMaintenanceIssuranceFilePath",
            uploadScreenLabel: "②自動車整備分野特定技能協議会構成員資格証明書発行申請書（受付印があるもの）",
          },
        ],
      },
      {
        key: "specificSkillOrganizationOverviewFilePath",
        presentScreenLabel: "建築物清掃業／建築物環境衛生総合管理業登録証明書",
        uploadScreenLabel: "次の①か②の書類を添付してください",
        condition: ERenewVisaCondition.ALL,
        required: false,
        tooltipContent:
          "過去3年以内の在留諸申請において提出済み、もしくは当該登録を受けていることが記載された「ビルクリーニング分野特定技能協議会構成員資格証明書」（当該登録の有効期限が切れていないものに限る。）を提出している場合は提出不要。",
        companySpecificIndustryIdsCondition: [11],
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
        children: [
          {
            key: "specificSkillOrganizationOverviewFilePath",
            uploadScreenLabel: "①建築物清掃業登録証明書",
          },
          {
            key: "buildingEnvHealthMgmtRegistrationCertFilePath",
            uploadScreenLabel: "②建築物環境衛生総合管理業登録証明書",
          },
        ],
      },
      {
        key: "hotelBusinessLicenseFilePath",
        presentScreenLabel: "旅館業許可証の写し",
        required: false,
        tooltipContent: "過去3年以内の在留諸申請において提出済み（当該許可の有効期限が切れていないものに限る。）の場合は提出不要。",
        uploadScreenLabel: "旅館業許可証(旅館・ホテル営業許可書)の写し",
        condition: ERenewVisaCondition.ALL,
        companySpecificIndustryIdsCondition: [5],
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
    ],
  },
  GROUP_THREE: {
    label: "以下のどちらかに該当する場合に提出省略な書類",
    subLabel:
      "・受け入れている任意の外国人に係る過去３年以内の在留諸申請において提出済み(現在もその内容に変更がなく、有効期限内の場合)の場合。\n・次の全ての条件に該当する場合には、受け入れている任意の外国人に係る過去の在留諸申請において提出済みの内容と変更がない。\n①申請日までの過去２年にわたって継続して（特定の外国人に限らない。）特定技能外国人の受入れを行っていること\n②申請日の前日から起算して１年以内に特定技能外国人の行方不明（受入れ機関の帰責性の有無を問わない。）を発生させていないこと\n③申請日の前日から起算して１年以内に地方出入国在留管理局から指導勧告書の交付を受けていないこと\n④申請日の前日から起算して３年以内に出入国管理及び難民認定法第１９条の２１第１項により改善命令を受けていないこと\n⑤申請日の前日から起算して１年以内に特定技能に係る定期又は随時の届出（出入国管理及び難民認定法第１９条の１８に定めるもの。）を怠ったことがないこと",
    value: [
      {
        key: "specificSkillsInstitutionOverviewDocumentFilePath",
        presentScreenLabel: "特定技能所属機関概要書",
        uploadScreenLabel: "（参考様式第1-11-1）特定技能所属機関概要書",
        required: true,
        tooltipContent:
          "記載内容に応じて、「受け入れた中長期在留者リスト（参考様式第１－１１－２号）」、「生活相談業務を行った中長期在留者リスト（参考様式第１－１１－３号）」、「支援責任者の履歴書(参考様式第１－２０号)」及び「支援担当者の履歴書(参考様式第１－２２号)」の添付が必要な場合がある。",
        condition: [],
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "registrationCertFilePath",
        presentScreenLabel: "登記事項証明書",
        uploadScreenLabel: "登記事項証明書",
        required: true,
        condition: [],
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE],
      },
      {
        key: "executiveResidentRecordCopyFilePath",
        presentScreenLabel: "業務執行に関与する役員の住民票の写し",
        uploadScreenLabel: "業務執行に関与する役員の住民票の写し",
        tooltipContent: "マイナンバーの記載がなく、本籍地の記載があるものに限る。",
        required: true,
        condition: [],
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE],
      },
      {
        key: "specificSkillsInstitutionOfficerAffidavitFilePath",
        presentScreenLabel: "特定技能所属機関の役員に関する誓約書",
        required: true,
        uploadScreenLabel: "（参考様式第1-23）特定技能所属機関の役員に関する誓約書",
        tooltipContent: "特定技能外国人の受入れに関する業務執行に関与しない役員がいる場合のみ。",
        condition: [],
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE],
      },
      {
        key: "individualBusinessOwnerResidentCertFilePath",
        presentScreenLabel: "個人事業主の住民票の写し",
        uploadScreenLabel: "個人事業主の住民票の写し",
        required: true,
        subUploadScreenLabel: "(注)マイナンバーの記載がなく、本籍地の記載があるものに限る。",
        condition: [],
        companyDivisionCondition: [COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
    ],
  },
  GROUP_FOUR: {
    label: "過去提出時から変更がある場合に提出する書類",
    subLabel: "過去に提出したときから変更がある場合は提出が必要です。変更がない場合は提出不要なため、スキップしてください。",
    value: [
      {
        key: "shipbuildingIndustryConfirmationFilePath",
        presentScreenLabel: "造船・舶用工業事業者の確認通知書",
        uploadScreenLabel: "造船・舶用工業事業者の確認通知書",
        required: false,
        companySpecificIndustryIdsCondition: [8],
        condition: ERenewVisaCondition.ALL,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "businessLicenseOrNotificationFormFilePath",
        presentScreenLabel: "保健所長の営業許可証又は届出書の写し",
        uploadScreenLabel: "保健所長の営業許可証又は届出書の写し",
        required: false,
        companySpecificIndustryIdsCondition: [1],
        condition: ERenewVisaCondition.ALL,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "fishingLicenseCopy1FilePath",
        presentScreenLabel: "漁業許可または免許",
        required: false,
        uploadScreenLabel: "所属機関が許可又は免許を受けて漁業又は養殖業を営んでいる場合、次の①から③のいずれかの書類を添付してください",
        companySpecificIndustryIdsCondition: [3],
        condition: ERenewVisaCondition.ALL,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
        children: [
          {
            key: "fishingLicenseCopy1FilePath",
            uploadScreenLabel: "①許可証の写し",
          },
          {
            key: "fishingLicenseCopy2FilePath",
            uploadScreenLabel: "②免許の指令書の写し",
          },
          {
            key: "fishingLicenseCopy3FilePath",
            uploadScreenLabel: "③その他許可または免許を受け漁業または養殖業を営んでいることが確認できる公的な書類の写し",
          },
        ],
      },
      {
        key: "fishingCooperativeRightsDocumentFilePath",
        presentScreenLabel: "漁業協同組合関連書類",
        required: false,
        uploadScreenLabel: "所属機関が漁業協同組合に所属して漁業又は養殖業を営んでいる場合、次の①または②のいずれか",
        companySpecificIndustryIdsCondition: [3],
        condition: ERenewVisaCondition.ALL,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
        children: [
          {
            key: "fishingCooperativeRightsDocumentFilePath",
            uploadScreenLabel: "①当該組合の漁業権の内容たる漁業又は養殖業を営むことを確認できる当該組合が発行した書類の写し",
          },
          {
            key: "fishingCooperativeMembershipDocumentFilePath",
            uploadScreenLabel: "②その他当該組合に所属して漁業又は養殖業を営んでいることが確認できる書類の写し",
          },
        ],
      },
      {
        key: "fishingBoatRegistryCopyFilePath",
        presentScreenLabel: "漁船関連書類",
        required: false,
        uploadScreenLabel: "漁船を用いて漁業又は養殖業を営んでいる場合、次の①または②のいずれか",
        companySpecificIndustryIdsCondition: [3],
        condition: ERenewVisaCondition.ALL,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
        children: [
          {
            key: "fishingBoatRegistryCopyFilePath",
            uploadScreenLabel: "①漁船原簿謄本の写し",
          },
          {
            key: "fishingBoatRegistrationCertCopyFilePath",
            uploadScreenLabel: "②漁船登録票の写し",
          },
        ],
      },
    ],
  },
  GROUP_FIVE: {
    label: "前回提出から2年経過もしくは更新がある場合に提出する書類",
    subLabel:
      "過去に提出したときから変更がある場合、または前回から2年経過している場合は提出してください。該当しない場合は提出不要なため、スキップしてください。",
    value: [
      {
        key: "laborInsuranceNoDelegationFilePath",
        presentScreenLabel: "労働保険概算・増加概算・確定保険料申告書または直近2年分の労働保険料等納入通知書の写し及び領収証書の写し",
        uploadScreenLabel: "次のAかBの書類を添付してください",
        required: false,
        condition: [],
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
        children: [
          {
            key: "laborInsuranceNoDelegationFilePath",
            uploadScreenLabel:
              "A) 労働保険事務組合に事務委託していない場合\n労働保険概算・増加概算・確定保険料申告書(事業主控)の写し及び申告書に対応する領収証書(口座振替結果通知ハガキ)の写し",
          },
          {
            key: "laborInsuranceDelegationFilePath",
            uploadScreenLabel:
              "B) 労働保険事務組合に事務委託している場合\n労働保険事務組合が発行した直近２年分の労働保険料等納入通知書の写し及び通知書に対応する領収証書(口座振替結果通知ハガキ)の写し",
          },
        ],
      },
      {
        key: "socialInsurancePaymentStatusCertFilePath",
        presentScreenLabel: "社会保険料納入状況回答票又は健康保険・厚生年金保険料領収証書の写し",
        uploadScreenLabel: "社会保険料納入状況回答票又は健康保険・厚生年金保険料領収証書の写し",
        condition: [],
        required: false,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE],
      },
      {
        key: "healthInsuranceWelfarePensionCertFilePath",
        presentScreenLabel: "健康保険・年金保険の領収または納付証明書等",
        uploadScreenLabel: "次のAかBの書類を添付してください",
        condition: [],
        required: false,
        companyDivisionCondition: [COMPANY_DIVISION.SOLE_PROPRIETOR],
        children: [
          {
            key: "healthInsuranceWelfarePensionCertFilePath",
            uploadScreenLabel: "A) 健康保険・厚生年金保険の適用事業所の場合\n社会保険料納入状況回答票又は健康保険・厚生年金保険料領収証書の写し",
          },
          {
            key: "nonHealthInsuranceWelfarePensionCertFilePath",
            uploadScreenLabel: "B)健康保険・厚生年金保険の適用事業所でない場合\n個人事業主の国民健康保険被保険者証の写し",
            tooltipContent: "申請の日の属する月の前々月までの２４か月分が必要",
          },
          {
            key: "individualHealthInsurancePaymentCertFilePath",
            uploadScreenLabel: "個人事業主の国民健康保険料(税)納付証明書",
            tooltipContent:
              "初めて受け入れる場合には直近１年分、受入れ中の場合には直近２年分が必要。保険者番号及び被保険者等記号・番号を申請人側でマスキング(黒塗り)すること。",
          },
          {
            key: "individualPensionReceiptCopyFilePath",
            uploadScreenLabel: "個人事業主の国民年金保険料領収証書の写し又は被保険者記録照会(納付Ⅱ)",
            tooltipContent: "申請の日の属する月の前々月までの２４か月分が必要。基礎年金番号を申請人側でマスキング(黒塗り)すること。",
          },
        ],
      },
      {
        key: "taxOfficePaymentCertNo3FilePath",
        presentScreenLabel: "税務署発行の納税証明書(その３)",
        uploadScreenLabel: "税務署発行の納税証明書(その３)",
        condition: [],
        required: false,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE],
      },
      {
        key: "corporateResidentTaxPaymentCertFilePath",
        presentScreenLabel: "法人住民税の市町村発行の納税証明書",
        uploadScreenLabel: "法人住民税の市町村発行の納税証明書",
        required: false,
        condition: [],
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE],
      },
      {
        key: "individualBusinessOwnerTaxOfficePaymentCertNo3FilePath",
        presentScreenLabel: "個人事業主の税務署発行の納税証明書(その３)",
        uploadScreenLabel: "個人事業主の税務署発行の納税証明書(その３)",
        tooltipContent: "税目は「①源泉所得税及び復興特別所得税」「②申告所得税及び復興特別所得税」「③消費税及び地方消費税」「④相続税」「⑤贈与税」。",
        required: false,
        condition: [],
        companyDivisionCondition: [COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
      {
        key: "individualResidentTaxCertFilePath",
        presentScreenLabel: "個人事業主の個人住民税の市町村発行の納税証明書",
        uploadScreenLabel: "個人事業主の個人住民税の市町村発行の納税証明書",
        required: false,
        tooltipContent: "直近２年分が必要。",
        condition: [],
        companyDivisionCondition: [COMPANY_DIVISION.SOLE_PROPRIETOR],
      },
    ],
  },
  GROUP_SIX: {
    label: "提出書類の一覧表",
    subLabel: "上記のアップロード内容に応じて一覧表のPDFが自動で生成されます。アップロードは不要です。",
    value: [
      {
        key: "residenceApplicationsForEmployeeTable1FilePath",
        presentScreenLabel: "特定技能外国人の在留諸申請に係る提出書類一覧表(第1表)",
        uploadScreenLabel: "特定技能外国人の在留諸申請に係る提出書類一覧表(第1表)",
        condition: ERenewVisaCondition.ALL,
        required: false,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
        onlyShowWhenHasFile: true,
      },
      {
        key: "residenceApplicationsForEmployeeTable2And1FilePath",
        presentScreenLabel: "特定技能外国人の在留諸申請に係る提出書類一覧表(第2表の1)",
        uploadScreenLabel: "特定技能外国人の在留諸申請に係る提出書類一覧表(第2表の1)",
        condition: [ERenewVisaCondition.A, ERenewVisaCondition.B, ERenewVisaCondition.C, ERenewVisaCondition.D, ERenewVisaCondition.E],
        required: false,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
        onlyShowWhenHasFile: true,
      },
      {
        key: "residenceApplicationsForEmployeeTable2And2FilePath",
        presentScreenLabel: "特定技能外国人の在留諸申請に係る提出書類一覧表(第2表の2)",
        required: false,
        uploadScreenLabel: "特定技能外国人の在留諸申請に係る提出書類一覧表(第2表の2)",
        condition: [],
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE],
        // onlyShowWhenHasFile: true,
      },
      {
        key: "residenceApplicationsForEmployeeTable2And3FilePath",
        presentScreenLabel: "特定技能外国人の在留諸申請に係る提出書類一覧表(第2表の3)",
        required: false,
        uploadScreenLabel: "特定技能外国人の在留諸申請に係る提出書類一覧表(第2表の3)",
        condition: [],
        companyDivisionCondition: [COMPANY_DIVISION.SOLE_PROPRIETOR],
        // onlyShowWhenHasFile: true,
      },
      {
        key: "residenceApplicationsForEmployeeTable3And1FilePath",
        required: false,
        presentScreenLabel: "特定技能外国人の在留諸申請に係る提出書類一覧表(第3表の1)",
        uploadScreenLabel: "特定技能外国人の在留諸申請に係る提出書類一覧表(第3表の1)",
        condition: ERenewVisaCondition.ALL,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
        onlyShowWhenHasFile: true,
      },
      {
        key: "residenceApplicationsForEmployeeTable3And2FilePath",
        required: false,
        presentScreenLabel: "特定技能外国人の在留諸申請に係る提出書類一覧表(第3表の2)",
        uploadScreenLabel: "特定技能外国人の在留諸申請に係る提出書類一覧表(第3表の2)",
        condition: ERenewVisaCondition.ALL,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
        onlyShowWhenHasFile: true,
      },
      {
        key: "residenceApplicationsForEmployeeTable3And3FilePath",
        required: false,
        presentScreenLabel: "特定技能外国人の在留諸申請に係る提出書類一覧表(第3表の3)",
        uploadScreenLabel: "特定技能外国人の在留諸申請に係る提出書類一覧表(第3表の3)",
        condition: ERenewVisaCondition.ALL,
        companyDivisionCondition: [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR],
        onlyShowWhenHasFile: true,
      },
    ],
  },
};

export const TOTAL_ARRAY_KEYS: (keyof TRenewVisaUploadFileForm)[] = [
  "applicantIndividualResidentTaxCertFilePath",
  "applicantResidentTaxPaymentCertFilePath",
  "applicantWithholdingSlipCopyFilePath",
  "applicantHealthInsuranceCardCopyFilePath",
  "applicantHealthInsurancePaymentCertFilePath",
  "applicantPensionReceiptCopy1FilePath",
  "applicantPensionRecordInquiry2FilePath",
  "previousApplicationDocumentsFilePath",
  "publicDutyPledgeFilePath",
  "publicDutyComplianceFilePath",
  "immigrationElectronicSystemPledgeFilePath",
  "documentOmissionPledgeFilePath",
  "foreignWorkerCompensationStatementFilePaths",
  "employmentContractCopyFilePath",
  "employmentConditionsCopyFilePaths",
  "wagePaymentFilePath",
  "quarterlyReportCopyFilePath",
  "innovationCompanyProofFilePath",
  "certifiedCompanyProofFilePath",
  "annualSalaryReportCopyFilePath",
  "careWorkplaceOverviewFilePath",
  "councilMembershipCertFilePath",
  "specifiedSkillAutomobileMaintenanceCertFilePath",
  "specifiedSkillAutomobileMaintenanceIssuranceFilePath",
  "hotelBusinessLicenseFilePath",
  "specificSkillOrganizationOverviewFilePath",
  "buildingEnvHealthMgmtRegistrationCertFilePath",
  "registrationCertFilePath",
  "specificSkillsInstitutionOverviewDocumentFilePath",
  "executiveResidentRecordCopyFilePath",
  "specificSkillsInstitutionOfficerAffidavitFilePath",
  "individualBusinessOwnerResidentCertFilePath",
  "socialInsurancePaymentStatusCertFilePath",
  "shipbuildingIndustryConfirmationFilePath",
  "businessLicenseOrNotificationFormFilePath",
  "fishingLicenseCopy1FilePath",
  "fishingLicenseCopy2FilePath",
  "fishingLicenseCopy3FilePath",
  "fishingCooperativeRightsDocumentFilePath",
  "fishingCooperativeMembershipDocumentFilePath",
  "fishingBoatRegistryCopyFilePath",
  "fishingBoatRegistrationCertCopyFilePath",
  "laborInsuranceNoDelegationFilePath",
  "laborInsuranceDelegationFilePath",
  "healthInsuranceWelfarePensionCertFilePath",
  "nonHealthInsuranceWelfarePensionCertFilePath",
  "individualHealthInsurancePaymentCertFilePath",
  "individualPensionReceiptCopyFilePath",
  "taxOfficePaymentCertNo3FilePath",
  "corporateResidentTaxPaymentCertFilePath",
  "individualBusinessOwnerTaxOfficePaymentCertNo3FilePath",
  "individualResidentTaxCertFilePath",
];
