import {
  TGetEmployeeRetirementDocumentListReqDto,
  TGetEmployeeRetirementDocumentListResDto,
} from "@/api/services/main/retirement-document/dtos/get-employee-retirement-document-list.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { clientApi } from "@/utils/api";
import { createUrlWithParams } from "@/utils/url";
import { usePagination } from "@/hooks/usePagination";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { TGetEmployeeRetirementDocumentResDto } from "@/api/services/main/retirement-document/dtos/get-employee-retirement-document.dto";
import {
  TUpdateEmployeeRetirementDocumentReqDto,
  TUpdateEmployeeRetirementDocumentResDto,
} from "@/api/services/main/retirement-document/dtos/update-employee-retirement-document.dto";
import { TGetRetirementFileListReqDto, TGetRetirementFileListResDto } from "@/api/services/main/retirement-document/dtos/get-retirement-file-list";
import { TCreateMultiRetirementFileFileReqDto } from "@/api/services/main/retirement-document/dtos/create-multi-retirement-files.dto";
import { TFinishUploadDocumentStepResDto } from "@/api/services/main/retirement-document/dtos/finish-upload-document-step.dto";
import { TApiRes } from "@/types/api";
import { TSetCompleteRetirementDocumentResDto } from "@/api/services/main/retirement-document/dtos/set-complete-retirement-document.dto";
import { TCancelRetirementDocumentResDto } from "@/api/services/main/retirement-document/dtos/cancel-retirement-document.dto";
import { TUpdateRetirementDateReqDto } from "@/api/services/main/retirement-document/dtos/update-retirement-date.dto";
import { DOCUMENT_STATUS } from "@/types/enum";
import { TUpdateRetirementStatusReqDto } from "@/api/services/main/retirement-document/dtos/update-retirement-status.dto";
import { TCheckToCompleteRetirementDocumentResDto } from "@/api/services/main/retirement-document/dtos/check-to-complete-retirement-document.dto";

export const fetchEmployeeRetirementDocumentListApi = async (
  req: TGetEmployeeRetirementDocumentListReqDto,
): Promise<TGetEmployeeRetirementDocumentListResDto> => {
  const url = createUrlWithParams("/retirement-documents", req);
  const rs = await clientApi.get<TGetEmployeeRetirementDocumentListResDto>(url);
  const sortedData = rs.data.data.sort((a, b) => {
    if (a.status === DOCUMENT_STATUS.IN_PROGRESS && b.status === DOCUMENT_STATUS.COMPLETE) {
      return -1;
    }

    if (a.status === DOCUMENT_STATUS.COMPLETE && b.status === DOCUMENT_STATUS.IN_PROGRESS) {
      return 1;
    }

    if (a.status === DOCUMENT_STATUS.COMPLETE && b.status === DOCUMENT_STATUS.COMPLETE && a?.retirementDate && b?.retirementDate) {
      return new Date(b?.retirementDate).getTime() - new Date(a?.retirementDate).getTime(); // Descending for COMPLETE
    }

    if (a.status === DOCUMENT_STATUS.IN_PROGRESS && b.status === DOCUMENT_STATUS.IN_PROGRESS && a?.retirementDate && b?.retirementDate) {
      return new Date(a.retirementDate).getTime() - new Date(b.retirementDate).getTime(); // Ascending for IN_PROGRESS
    }

    return 0;
  });
  return { ...rs.data, data: sortedData };
};

type TEmployeeRetirementDocumentListProps = {
  filter?: TGetEmployeeRetirementDocumentListReqDto;
};

export const useEmployeeRetirementDocumentList = ({ filter = {} }: TEmployeeRetirementDocumentListProps = {}) => {
  const pagReq = usePagination();

  const params = {
    ...filter,
    ...pagReq,
  };

  return useQuery({
    queryKey: [QUERY_KEYS.RETIREMENT_DOCUMENTS.FETCH_LIST, params],
    queryFn: () => fetchEmployeeRetirementDocumentListApi(params),
  });
};

export const createRetirementDocumentApi = (employeeId: number) => {
  return clientApi.post(`/retirement-documents/create/${employeeId}`, { employeeId });
};

export const fetchEmployeeRetirementDocumentInfoApi = async (documentGroupId: string | number): Promise<TGetEmployeeRetirementDocumentResDto> => {
  const rs = await clientApi.get<TGetEmployeeRetirementDocumentResDto>(`/retirement-documents/${documentGroupId}`);
  return rs.data;
};

export const useEmployeeRetirementDocument = (documentGroupIdParam?: number) => {
  const { documentGroupId } = useParams();

  const fetchId = documentGroupIdParam ?? documentGroupId;
  return useQuery({
    queryKey: [QUERY_KEYS.RETIREMENT_DOCUMENTS.FETCH_DOCUMENT, fetchId],
    queryFn: () => (fetchId ? fetchEmployeeRetirementDocumentInfoApi(fetchId) : undefined),
    enabled: Boolean(fetchId),
  });
};

export const updateRetirementDocumentApi = async (
  documentGroupId: string | number,
  payload: TUpdateEmployeeRetirementDocumentReqDto,
): Promise<TUpdateEmployeeRetirementDocumentResDto> => {
  const { data } = await clientApi.put(`/retirement-documents/${documentGroupId}/update-employee`, payload);
  return data;
};

export const finishSubmitDocumentStepApi = (documentStepId: string | number) => {
  return clientApi.post(`/retirement-documents/finish-submit-document-step`, { documentStepId: Number(documentStepId) });
};

export const finishUploadDocumentStepApi = async (documentStepId: number | string): Promise<TFinishUploadDocumentStepResDto> => {
  const rs = await clientApi.post<TFinishUploadDocumentStepResDto>("/retirement-documents/finish-upload-document-step", {
    documentStepId: Number(documentStepId),
  });
  return rs.data;
};

export const createMultiRetirementFilesApi = (payload: TCreateMultiRetirementFileFileReqDto) => {
  return clientApi.post(`/retirement-documents/files`, payload);
};

export const fetchRetirementFileListApi = async (retirementDocumentStepId: string | number, req: TGetRetirementFileListReqDto) => {
  const url = createUrlWithParams(`/retirement-documents/files/${retirementDocumentStepId}`, req);
  const rs = await clientApi.get<TGetRetirementFileListResDto>(url);
  return rs.data;
};

export const useRetirementFileList = (documentStepId?: number) => {
  const { id } = useParams();
  const fetchId = id ?? documentStepId;
  return useQuery({
    queryKey: [QUERY_KEYS.RETIREMENT_DOCUMENTS.FETCH_FILE_LIST, fetchId],
    queryFn: () => (fetchId ? fetchRetirementFileListApi(fetchId, {}) : undefined),
    enabled: Boolean(fetchId),
  });
};

export const deleteRetirementFileApi = async (fileId: string | number) => {
  const rs = await clientApi.delete<TApiRes<boolean>>(`/retirement-documents/files/${fileId}`);
  return rs.data;
};

export const setCompleteRetirementDocumentApi = (documentGroupId: string | number): Promise<TSetCompleteRetirementDocumentResDto> => {
  return clientApi.post(`/retirement-documents/${documentGroupId}/set-complete`);
};

export const cancelRetirementDocumentApi = (documentGroupId: string | number): Promise<TCancelRetirementDocumentResDto> => {
  return clientApi.post(`/retirement-documents/${documentGroupId}/cancel`);
};

export const updateRetirementDateApi = async (documentGroupId: string | number, payload: TUpdateRetirementDateReqDto) => {
  const { data } = await clientApi.put(`/retirement-documents/${documentGroupId}/employee-retirement-date`, payload);
  return data;
};

export const updateRetirementStatusApi = async (employeeId: string | number, payload: TUpdateRetirementStatusReqDto) => {
  const { data } = await clientApi.put(`/retirement-documents/${employeeId}/employee-retirement-status`, payload);
  return data;
};

export const checkToCompleteRetirementDocumentApi = (documentGroupId: string | number): Promise<TCheckToCompleteRetirementDocumentResDto> => {
  return clientApi.post(`/retirement-documents/${documentGroupId}/check-to-complete`);
};
