import { createRenewVisaEmployeeApplicationDocumentApi, updateRenewVisaEmployeeApplicationDocumentApi } from "@/api/services/main/renew-visa";
import { Form } from "@/components/elements/Form";
import { FormDescription } from "@/components/elements/FormDescription";
import { MenuNavigate } from "@/components/elements/MenuNavigate";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { EmployeeApplicationBtnActions } from "@/features/renew-visa/components/applicationStep/formApplication/employeeApplication/EmployeeApplicationBtnActions";
import { EmployeeCareerRelatedActitivyForm } from "@/features/renew-visa/components/applicationStep/formApplication/employeeApplication/EmployeeCareerRelatedActitivyForm";
import { EmployeeContractForm } from "@/features/renew-visa/components/applicationStep/formApplication/employeeApplication/EmployeeContractForm";
import { EmployeeDispatchingOrganizationFormTable } from "@/features/renew-visa/components/applicationStep/formApplication/employeeApplication/EmployeeDispatchingOrganizationFormTable";
import { EmployeeInfoOneForm } from "@/features/renew-visa/components/applicationStep/formApplication/employeeApplication/EmployeeInfoOneForm";
import { EmployeeInfoTwoForm } from "@/features/renew-visa/components/applicationStep/formApplication/employeeApplication/EmployeeInfoTwoForm";
import { EmployeeWorkingHistoriesFormTable } from "@/features/renew-visa/components/applicationStep/formApplication/employeeApplication/EmployeeWorkingHistoriesFormTable";
import { PreventEmployeeApplicationModal } from "@/features/renew-visa/components/applicationStep/formApplication/employeeApplication/PreventEmployeeApplicationModal";
import { ProgressStep } from "@/features/renew-visa/components/applicationStep/formApplication/ProgressStep";
import { employeeApplicationDraftSchema, employeeApplicationSchema } from "@/features/renew-visa/schema/employeeApplicationSchema";
import { TEmployeeApplicationForm } from "@/features/renew-visa/types/employeeApplicationForm";
import { convertEmployeeApplicationFormDataToReqDto } from "@/features/renew-visa/utils/convertEmployeeApplicationFormDataToReqDto";
import { AppRoutes } from "@/routes/config";
import { TUpdaterDto } from "@/types/common";
import { setErrorForm, showError } from "@/utils/error";
import { isEmpty } from "@/utils/object";
import { getFormValues } from "@/utils/pieces";
import { toast } from "@/utils/toast";
import { getDynamicRoute } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

type TEmployeeApplicationFormProps = {
  previousValues: TEmployeeApplicationForm | undefined;
  updater?: TUpdaterDto | undefined;
  readonly?: boolean;
  isDraft?: boolean;
  isEditable?: boolean;
  setIsEditable?: (bool: boolean) => void;
};

export const EmployeeApplicationForm: FC<TEmployeeApplicationFormProps> = ({
  previousValues,
  updater,
  readonly,
  isDraft: isPageDraft,
  isEditable,
  setIsEditable,
}) => {
  const { renewVisaDocumentId, documentStepId } = useParams();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const methods = useForm<TEmployeeApplicationForm>({
    resolver: yupResolver(employeeApplicationSchema),
    defaultValues: previousValues,
    mode: "all",
    reValidateMode: "onBlur",
  });

  const saveDataForm = async (data: TEmployeeApplicationForm, isDraft: boolean) => {
    if (!renewVisaDocumentId || !documentStepId) return;
    try {
      if (updater) {
        if (isEmpty(methods.formState.dirtyFields) && isPageDraft === isDraft) {
          if (isDraft) {
            navigate(getDynamicRoute(AppRoutes.renewVisaPresent, { renewVisaDocumentId }));
          } else {
            navigate(getDynamicRoute(AppRoutes.renewVisaPresentCompanyApplication, { renewVisaDocumentId, documentStepId }));
          }
          return;
        }
        const rs = await updateRenewVisaEmployeeApplicationDocumentApi(renewVisaDocumentId, documentStepId, {
          ...convertEmployeeApplicationFormDataToReqDto(data, isDraft),
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      } else {
        const rs = await createRenewVisaEmployeeApplicationDocumentApi(renewVisaDocumentId, documentStepId, {
          ...convertEmployeeApplicationFormDataToReqDto(data, isDraft),
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }

      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_DETAIL] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_EMPLOYEE_APPLICATION] });
      if (isDraft) {
        methods.unregister();
        navigate(getDynamicRoute(AppRoutes.renewVisaPresent, { renewVisaDocumentId }));
      } else {
        navigate(getDynamicRoute(AppRoutes.renewVisaPresentCompanyApplication, { renewVisaDocumentId, documentStepId }));
      }
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  const onSubmit = async (data: TEmployeeApplicationForm) => {
    if (readonly) {
      navigate(getDynamicRoute(AppRoutes.renewVisaPresentCompanyApplication, { renewVisaDocumentId, documentStepId }));
      return;
    }
    await saveDataForm(data, false);
  };

  const saveDraft = async () => {
    const data = methods.getValues();
    try {
      const values = await getFormValues(data, employeeApplicationDraftSchema);
      await saveDataForm(values as TEmployeeApplicationForm, true);
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Form onSubmit={onSubmit} methods={methods} schema={employeeApplicationSchema}>
      <MenuNavigate
        contents={[
          { title: "外国人情報（身分事項１）", content: <EmployeeInfoOneForm readonly={readonly || !isEditable} /> },
          { title: "外国人情報（身分事項２）", content: <EmployeeInfoTwoForm readonly={readonly || !isEditable} /> },
          { title: "活動に関する事項（STEP2-3)", content: <EmployeeCareerRelatedActitivyForm readonly={readonly || !isEditable} /> },
          { title: "特定技能雇用契約", content: <EmployeeContractForm readonly={readonly || !isEditable} /> },
          { title: "職歴（外国におけるものを含む）", content: <EmployeeWorkingHistoriesFormTable readonly={readonly || !isEditable} /> },
          { title: "派遣先", content: <EmployeeDispatchingOrganizationFormTable readonly={readonly || !isEditable} /> },
        ]}
        contentPrefix={
          <>
            <ProgressStep />
            <FormDescription />
          </>
        }
        contentAffix={
          <EmployeeApplicationBtnActions
            saveDraft={saveDraft}
            readonly={readonly}
            isEditable={!!isEditable}
            setIsEditable={(v) => setIsEditable?.(v)}
          />
        }
      />
      <PreventEmployeeApplicationModal />
    </Form>
  );
};
