import { API_UNKNOWN_ERROR_MESSAGE } from "@/constants/common";
import { ERROR_STATUS_CODES } from "@/constants/error";
import { AppRoutes } from "@/routes/config";
import { TApiError } from "@/types/api";
import { toast } from "@/utils/toast";
import { AxiosError } from "axios";
import Cookies from "js-cookie";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import { ValidationError } from "yup";

/**
 * Checks if the error is an API error.
 *
 * @param {unknown} error - The error to check.
 * @returns {error is TApiError} - True if the error is an API error, false otherwise.
 */
export const isApiError = (error: unknown): error is TApiError => {
  if (error && typeof error === "object" && "message" in error) return true;
  return false;
};

/**
 * Checks if the error is a validation error.
 *
 * @param {unknown} error - The error to check.
 * @returns {error is ValidationError} - True if the error is a validation error, false otherwise.
 */
export const isValidatorError = (error: unknown): error is ValidationError => {
  if (error && typeof error === "object" && "path" in error && "message" in error) return true;
  return false;
};

/**
 * Sets form error messages based on the error type.
 *
 * @template T - The type of form field values.
 * @param {UseFormReturn<T, object, undefined>} methods - The form methods from react-hook-form.
 * @param {unknown} error - The error to handle.
 */
export const setErrorForm = <T extends FieldValues>(methods: UseFormReturn<T, object, undefined>, error: unknown) => {
  const { setError } = methods;
  if (isValidatorError(error)) {
    setError(error.path as Path<T>, { message: error.message });
    return;
  }
  if (isApiError(error)) {
    if (error.error && Array.isArray(error.error)) {
      error.error.forEach(({ property, message }) => {
        setError(property as Path<T>, { message });
      });
      return;
    }
  }
};

/**
 * Displays an error message using the toast utility.
 *
 * @param {unknown} error - The error to handle.
 */
export const showError = (error: unknown) => {
  if (isApiError(error) && error.message) {
    toast.error(error.message);
  } else {
    toast.error(API_UNKNOWN_ERROR_MESSAGE);
  }
};

/**
 * Handles API errors based on error code and redirects if necessary.
 *
 * @param {AxiosError} error - The error to handle.
 */
export const handleApiErrorWithCode = (error: AxiosError) => {
  if (error.code === "ERR_NETWORK") {
    setTimeout(() => window.location.replace(AppRoutes.serverErrorPage), 1000);
    return;
  }

  if (error.config?.method === "get") {
    if (isApiError(error.response?.data)) {
      const { code, message } = error.response.data;
      toast.error(message);
      switch (code) {
        case ERROR_STATUS_CODES.INFORMATION_NOT_EXISTS:
          window.location.replace(AppRoutes.information);
          return;
        case ERROR_STATUS_CODES.COMPANY_NOT_EXISTS:
          window.location.replace(AppRoutes.company);
          return;
        case ERROR_STATUS_CODES.COMPANY_BRANCH_NOT_EXISTS:
          window.location.replace(AppRoutes.companyBranch);
          return;
        case ERROR_STATUS_CODES.EMPLOYEE_NOT_EXISTS:
          window.location.replace(AppRoutes.employeeList);
          return;
        case ERROR_STATUS_CODES.DOCUMENT_NOT_EXISTS:
          window.location.replace(AppRoutes.regularHistory);
          return;
        case ERROR_STATUS_CODES.ACCEPTANCE_ACTIVITY_DOCUMENT_NOT_EXISTS:
          window.location.replace(AppRoutes.regularHistory);
          return;
        case ERROR_STATUS_CODES.ACCEPTANCE_ACTIVITY_FILE_NOT_EXISTS:
          window.location.replace(AppRoutes.regularHistory);
          return;
        case ERROR_STATUS_CODES.LIFE_SUPPORT_DOCUMENT_NOT_EXISTS:
          window.location.replace(AppRoutes.regularHistory);
          return;
        case ERROR_STATUS_CODES.MAINTENANCE_UNUSABLE:
          window.location.replace(AppRoutes.maintenance);
          return;
        case ERROR_STATUS_CODES.MAINTENANCE_READABLE:
          window.location.replace(AppRoutes.home);
          return;
      }
    }
  }

  if (error.response?.status === 404 && error.config?.method === "get") {
    if (!window.location.pathname.startsWith(AppRoutes.pageNotFound)) window.location.replace(AppRoutes.pageNotFound);
  }

  // Uncomment to handle 500 status errors for GET requests
  if (error.response?.status === 500 && error.config?.method === "get") {
    if (!window.location.pathname.startsWith(AppRoutes.serverErrorPage)) {
      setTimeout(() => window.location.replace(AppRoutes.serverErrorPage), 1000);
    }
  }
};

export const getCustomSentryExceptionError = (error: AxiosError) => {
  const titleSentry = "Irohana Error";
  const serverErrorResponse = error.response?.data as { message: string; code: string; error: unknown };
  let name = `[Irohana]`;
  if (serverErrorResponse) {
    const customErrorResponseMessage = serverErrorResponse?.error
      ? typeof serverErrorResponse?.error === "string"
        ? serverErrorResponse?.error
        : JSON.stringify(serverErrorResponse?.error)
      : undefined;
    name = name + `: ${serverErrorResponse.message}`;
    const message = `${serverErrorResponse.code}\n${customErrorResponseMessage ?? ""}`;
    const user = { email: Cookies.get("email"), companyCode: Cookies.get("companyCode") };
    const sendError = new Error(`${name} \n ${message} \n ${JSON.stringify(user)}`);
    sendError.name = titleSentry;
    return sendError;
  } else {
    const sendError = new Error(`${name} \n ${error.message}`);
    sendError.name = titleSentry;
    return sendError;
  }
};
