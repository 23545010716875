import { TFireEvent } from "@/types/common";
import { dayjs } from "@/utils/dayjs";
import { Dayjs } from "dayjs";
import { v4 as uuidv4 } from "uuid";
import Moji from "moji";

/**
 * Generates a new UUID (Universally Unique Identifier).
 *
 * @returns {string} - The generated UUID.
 */
export const makeUuid = (): string => {
  return uuidv4();
};

/**
 * Gets the start date of a given quarter in a specific year.
 *
 * @param {object} resource - The resource containing the year and quarter.
 * @param {number} resource.year - The year of the quarter.
 * @param {number} resource.quarter - The quarter number (1-4).
 * @returns {Dayjs} - The start date of the quarter.
 */
export const getStartDateOfQuarter = (resource: { year: number; quarter: number }): Dayjs => {
  const { quarter, year } = resource;
  const quarterStartMonth = (quarter - 1) * 3;

  return dayjs(new Date(year, quarterStartMonth, 1));
};

/**
 * Gets the end date of a given quarter in a specific year.
 *
 * @param {object} resource - The resource containing the year and quarter.
 * @param {number} resource.year - The year of the quarter.
 * @param {number} resource.quarter - The quarter number (1-4).
 * @returns {Dayjs} - The end date of the quarter.
 */
export const getEndDateOfQuarter = (resource: { year: number; quarter: number }): Dayjs => {
  const { quarter, year } = resource;
  const quarterEndMonth = quarter * 3;

  return dayjs(new Date(year, quarterEndMonth, 1)).subtract(1, "day");
};

/**
 * Converts a hex color code to an RGBA color string.
 *
 * @param {string} hexColor - The hex color code.
 * @param {number} alpha - The alpha (transparency) value (0 to 1).
 * @returns {string} - The RGBA color string.
 */
export const hexToRgba = (hexColor: string, alpha: number): string => {
  hexColor = hexColor.replace("#", "");
  // Convert hexadecimal to RGB
  const red = parseInt(hexColor.substring(0, 2), 16);
  const green = parseInt(hexColor.substring(2, 4), 16);
  const blue = parseInt(hexColor.substring(4, 6), 16);

  // Validate alpha value
  alpha = Math.min(1, Math.max(0, alpha));

  // Format and return RGBA string
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

/**
 * Removes duplicate elements from an array.
 *
 * @template T
 * @param {T[]} arr - The array to process.
 * @returns {T[]} - A new array with unique elements.
 */
export const uniqueArray = <T>(arr: T[]): T[] => {
  return Array.from(new Set(arr));
};

/**
 * Pauses execution for a specified number of milliseconds.
 *
 * @param {number} ms - The number of milliseconds to sleep.
 * @returns {Promise<void>} - A promise that resolves after the specified time.
 */
export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

/**
 * Validates and returns the cleaned form values based on a given schema.
 *
 * @template T
 * @param {T} values - The form values to validate.
 * @param {ObjectSchema<object>} schema - The validation schema.
 * @returns {Promise<Partial<T>>} - A promise that resolves with the validated form values.
 * @throws {Error} - Throws an error if validation fails.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFormValues = async <T extends object>(values: T, schema: any): Promise<Partial<T>> => {
  try {
    const data = await schema.cast(values);
    return data;
  } catch (err) {
    throw err;
  }
};

/**
 * Extracts and returns text content from an HTML string.
 *
 * @param {string} html - The HTML string to extract text from.
 * @returns {string} - The extracted text content.
 */
export const extractTextFromHTML = (html: string): string => {
  const span = document.createElement("span");
  span.innerHTML = html;
  span.remove();
  return span.textContent || span.innerText;
};

export const isFireEvent = <T>(event: Event): event is TFireEvent<T> => {
  if ("detail" in event) return true;
  return false;
};

export const isDateInQuarter = (inputDate: string, year: number, quarter: number): boolean => {
  const date = dayjs(inputDate);

  // Validate quarter input
  if (quarter < 1 || quarter > 4) {
    throw new Error("Invalid quarter. Must be 1, 2, 3, or 4.");
  }

  // Calculate start and end of the quarter
  let startOfQuarter: Dayjs;
  let endOfQuarter: Dayjs;

  switch (quarter) {
    case 1:
      startOfQuarter = dayjs(`${year}-01-01`);
      endOfQuarter = dayjs(`${year}-03-31`);
      break;
    case 2:
      startOfQuarter = dayjs(`${year}-04-01`);
      endOfQuarter = dayjs(`${year}-06-30`);
      break;
    case 3:
      startOfQuarter = dayjs(`${year}-07-01`);
      endOfQuarter = dayjs(`${year}-09-30`);
      break;
    case 4:
      startOfQuarter = dayjs(`${year}-10-01`);
      endOfQuarter = dayjs(`${year}-12-31`);
      break;
    default:
      throw new Error("Invalid quarter. Must be 1, 2, 3, or 4.");
  }

  // Check if the date is within the quarter
  return date.isSameOrAfter(startOfQuarter) && date.isSameOrBefore(endOfQuarter);
};

export const convertHalfWidthToFullWidth = (text: string): string => {
  if (!text) return text;

  const fullWidth = Moji(text)
    .convert("HE", "ZE") // alphanumeric (a-z, 0-9) to full-width
    .convert("HK", "ZK") // half-width Katakana to full-width Katakana
    .toString()
    .replace(/ /g, "　");

  return fullWidth;
};

export const convertFullWidthToHalfWidth = (text: string): string => {
  if (!text) return text;

  const halfWidth = Moji(text)
    .convert("ZE", "HE") // full-width alphanumeric (A-Z, 0-9) to half-width
    .convert("ZK", "HK") // full-width Katakana to half-width Katakana
    .toString()
    .replace(/　/g, " ");

  return halfWidth;
};
