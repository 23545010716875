import { TGetEnumOptionsResDto } from "@/api/services/global/enum/dtos/get-enum-options.dto";
import { CACHED_TIME } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { valueOf } from "@/types/common";
import { globalApi } from "@/utils/api";
import { useQuery } from "@tanstack/react-query";

export type TEnumName = valueOf<
  [
    "EInformationType",
    "EEmploymentStatus",
    "ERetirementStatus",
    "EDocumentStatus",
    "EQuarter",
    "ESupportImplementationStatus",
    "EGender",
    "EEmployeeTrainingStatus",
    "EEmployeeCareerCompletionProof",
    "EEmployeeCareerSkillCertificationOne",
    "EEmployeeCareerLocation",
    "EEmployeeCareerJapaneseCertification",
    "ECompanySupporterRole",
    "EImmigrationJurisdiction",
    "EConvertError",
    "EModifyContractTerminateReasonForCompany",
    "EModifyContractTerminateReasonForEmployee",
    "EModifyContractModificationReason",
    "EModifyContractChangeDispatchLocationReasons",
    "EModifyContractTerminateReason",
    "ESupportOutsourcingContractModificationType",
    "EEmploymentContractModificationType",
    "ESupportRecipient",
    "ESupportSpecifiedSkill",
    "ESupportRegistered",
    "ETerminateContractMajorCategoryReason",
    "ETerminateContractSubcategoryReason",
    "ESupportContractSubcategoryReason",
    "ERenewVisaCondition",
    "EMaritalStatus",
    "EResidenceCardReceiptMethod",
    "EYesNoAnswer",
    "EYesNoAndNotApplicableAnswer",
    "EPaymentMethod",
    "ERenewVisaConditionsApplyForStaffingTarget",
    "ERenewVisaConditionsApplyForSupportPlanDelegation",
    "EAnyTimeContentOfSupport",
    "ERetirementReasonType",
    "ECurrentSituation",
    "ESpecificCircumstance",
    "ESpecificCircumstanceContent",
    "EAnyConsultation",
    "EAnyConsultationContent",
    "EPlanToChangeJob",
    "EPlanToChangeJobContent",
    "ESupportType",
    "ESupportContent",
    "EReasonReturnHome",
    "EReasonReturnHomeContent",
    "EPlanDateReturn",
    "EPlanDateReturnContent",
    "EPlanToMove",
    "EPlanToMoveContent",
    "EProvidedLegalProtection",
    "EPlanToContinueActivity",
    "EMeasure",
    "EPayrollDeadline",
    "EPaymentDate",
    "ECompanyDivision",
    "EConvertStatus",
    "EEmployeeRetirementDocumentStepStatus",
    "ERenewVisaDocumentStatus",
  ]
>;

export const fetchEnumApi = async (enumName: TEnumName): Promise<TGetEnumOptionsResDto> => {
  const rs = await globalApi.get<TGetEnumOptionsResDto>(`/enums/${enumName}/options`);
  return rs.data;
};

export const useEnum = (enumName?: TEnumName) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.FETCH_ENUM, enumName],
    queryFn: () => (enumName ? fetchEnumApi(enumName) : undefined),
    enabled: Boolean(enumName),
    staleTime: CACHED_TIME,
    gcTime: CACHED_TIME,
  });

  return { data: data?.data ?? [], ...rest };
};
