import { useEnum } from "@/api/services/global/enum";
import { useCompanyInfo } from "@/api/services/main/company";
import { Button } from "@/components/elements/Button";
import { InnerBox } from "@/components/elements/InnerBox";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { EMPTY_STR } from "@/constants/string";
import { InfoRow } from "@/features/company/components/InfoRow";
import { SupportersInfo } from "@/features/company/components/SupportersInfo";
import { AppRoutes } from "@/routes/config";
import { text } from "@/theme/colors";
import { convertNumberToZipCode, convertNumberToCommas } from "@/utils/input";
import { findLabelByValue } from "@/utils/object";
import { getFullAddress } from "@/utils/string";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const CompanyPage = () => {
  const { data: immigrationJurisdictionOptions } = useEnum("EImmigrationJurisdiction");
  const { data: payrollDeadlineOptions } = useEnum("EPayrollDeadline");
  const { data: paymentDateOptions } = useEnum("EPaymentDate");
  const { data: companyDivisionOptions } = useEnum("ECompanyDivision");
  const { data: companyResult, isLoading } = useCompanyInfo();
  const companyData = companyResult?.data;
  const navigate = useNavigate();
  if (isLoading) return <LoadingPage />;

  return (
    <Box>
      <Stack gap={2}>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography variant="head20Semi" fontWeight={100} color={text.primary}>
            基本情報
          </Typography>
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              navigate(AppRoutes.companyEdit);
            }}
          >
            編集
          </Button>
        </Stack>
        <InnerBox>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <InfoRow title="会社・法人名" contentText={companyData?.companyName} />
            </Grid>
            <Grid item xs={4}>
              <InfoRow title="会社・法人名（ふりがな）" contentText={companyData?.companyFuriganaName || EMPTY_STR.TEXT} />
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <InfoRow title="代表者名" contentText={companyData?.representativeName || EMPTY_STR.TEXT} />
            </Grid>
            <Grid item xs={4}>
              <InfoRow title="代表者名（ふりがな）" contentText={companyData?.representativeFuriganaName || EMPTY_STR.TEXT} />
            </Grid>
            <Grid item xs={4}>
              <InfoRow title="代表者の役職名" contentText={companyData?.representativeJobTitle || EMPTY_STR.TEXT} />
            </Grid>
            {/* <Grid item xs={4}>
              <InfoRow title="事業内容" contentText={companyData?.companyDescription} />
            </Grid> */}
            <Grid item xs={4}>
              <InfoRow title="区分" contentText={findLabelByValue(companyDivisionOptions, companyData?.division) ?? EMPTY_STR.TEXT} />
            </Grid>
            <Grid item xs={4}>
              <InfoRow
                title="資本金（円）"
                contentText={
                  companyData && companyData.capital?.toString() ? `${convertNumberToCommas(companyData.capital?.toString())}円` : EMPTY_STR.TEXT
                }
              />
            </Grid>
            <Grid item xs={4}>
              <InfoRow title="法人番号（13桁）" contentText={companyData?.companyIdentificationNumber || EMPTY_STR.TEXT} />
            </Grid>
            <Grid item xs={4}>
              <InfoRow title="雇用保険適用事業所番号（11桁）" contentText={companyData?.businessInsuranceNumber || EMPTY_STR.TEXT} />
            </Grid>
            <Grid item xs={4}>
              <InfoRow title="労働保険番号" contentText={companyData?.employmentInsuranceNumber || EMPTY_STR.TEXT} />
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={12}>
              <InfoRow title="住所">
                <Grid container padding={1} spacing={3}>
                  <Grid item xs={4}>
                    <InfoRow
                      gap={1}
                      titleBold={false}
                      title="郵便番号"
                      contentText={companyData?.zipCode ? convertNumberToZipCode(companyData?.zipCode) : EMPTY_STR.TEXT}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InfoRow
                      gap={1}
                      titleBold={false}
                      title="本社住所（都道府県）"
                      contentText={getFullAddress({ province: companyData?.province, municipality: companyData?.municipality })}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InfoRow
                      paddingLeft={1}
                      gap={1}
                      titleBold={false}
                      title="本社住所（都道府県・市区町村以外）"
                      contentText={companyData?.address || EMPTY_STR.TEXT}
                    />
                  </Grid>
                </Grid>
              </InfoRow>
            </Grid>
            <Grid item xs={4}>
              <InfoRow title="電話番号（ハイフン区切りの数字で入力）" contentText={companyData?.telNumber ?? EMPTY_STR.TEXT} />
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={4}>
              <InfoRow
                title="常勤職員数"
                contentText={
                  companyData?.employeeCount?.toString() ? `${convertNumberToCommas(companyData.employeeCount.toString())}人` : EMPTY_STR.TEXT
                }
              />
            </Grid>
            <Grid item xs={4}>
              <InfoRow
                title="年間売上金額（直近年度）"
                contentText={
                  companyData && companyData.annualRevenue?.toString() ? `${convertNumberToCommas(companyData.annualRevenue?.toString())}円` : ""
                }
              />
            </Grid>
            <Grid item xs={4}>
              <InfoRow title="年度" contentText={companyData?.yearsInBusiness?.toString() ? `${companyData.yearsInBusiness}年度` : EMPTY_STR.TEXT} />
            </Grid>
            <Grid item xs={12}>
              <SupportersInfo />
            </Grid>
            <Grid item xs={4}>
              <InfoRow title="特定産業分野 1" contentText={companyData?.specificIndustry.companySpecificIndustry || EMPTY_STR.TEXT} />
            </Grid>
            <Grid item xs={4}>
              <InfoRow
                title="業務区分 1"
                contentText={
                  companyData && companyData?.specificBusinessClassifications?.length > 0
                    ? companyData?.specificBusinessClassifications.map((item) => item.companySpecificBusinessClassificationName).join(", ")
                    : EMPTY_STR.TEXT
                }
              />
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <InfoRow title="特定産業分野 2" contentText={companyData?.specificIndustryTwo?.companySpecificIndustry || EMPTY_STR.TEXT} />
            </Grid>
            <Grid item xs={4}>
              <InfoRow
                title="業務区分 2"
                contentText={
                  companyData && companyData.specificBusinessClassificationsTwo && companyData?.specificBusinessClassificationsTwo?.length > 0
                    ? companyData?.specificBusinessClassificationsTwo.map((item) => item.companySpecificBusinessClassificationName).join(", ")
                    : EMPTY_STR.TEXT
                }
              />
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <InfoRow title="特定産業分野 3" contentText={companyData?.specificIndustryThree?.companySpecificIndustry || EMPTY_STR.TEXT} />
            </Grid>
            <Grid item xs={4}>
              <InfoRow
                title="業務区分 3"
                contentText={
                  companyData && companyData.specificBusinessClassificationsThree && companyData?.specificBusinessClassificationsThree?.length > 0
                    ? companyData?.specificBusinessClassificationsThree.map((item) => item.companySpecificBusinessClassificationName).join(", ")
                    : EMPTY_STR.TEXT
                }
              />
            </Grid>
            <Grid item xs={4}></Grid>

            <Grid item xs={4}>
              <InfoRow title="主たる業種" contentText={companyData?.mainIndustry?.mainIndustry || EMPTY_STR.TEXT} />
            </Grid>
            <Grid item xs={4}>
              <InfoRow title="その他を選択した場合の業種名" contentText={companyData?.mainIndustryOther || EMPTY_STR.TEXT} />
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <InfoRow
                title="入管管轄"
                contentText={findLabelByValue(immigrationJurisdictionOptions, companyData?.immigrationJurisdiction) ?? EMPTY_STR.TEXT}
              />
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <InfoRow title="給与締日" contentText={findLabelByValue(payrollDeadlineOptions, companyData?.payrollDeadline) ?? EMPTY_STR.TEXT} />
            </Grid>
            <Grid item xs={4}>
              <InfoRow title="給与支払日" contentText={findLabelByValue(paymentDateOptions, companyData?.paymentDate) ?? EMPTY_STR.TEXT} />
            </Grid>
            <Grid item xs={12}>
              <InfoRow title="備考" contentText={companyData?.companyNote || EMPTY_STR.TEXT} />
            </Grid>
          </Grid>
        </InnerBox>
      </Stack>
    </Box>
  );
};
