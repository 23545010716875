import { uploadEmployeeAvatarApi } from "@/api/services/global/employee";
import { Avatar } from "@/components/elements/Avatar";
import { DropZone } from "@/components/elements/DropZone";
import { MIME_TYPES } from "@/constants/file";
import { divider } from "@/theme/colors";
import { rounded } from "@/theme/variables";
import { FCC } from "@/types/common";
import { TExtendFile } from "@/types/file";
import { showError } from "@/utils/error";
import { convertKbToByte } from "@/utils/file";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { Box, Stack, Typography, styled } from "@mui/material";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

export type TUploadAvatarDropZoneProps = {
  avatarFile: TExtendFile | undefined;
  setAvatarFile: React.Dispatch<React.SetStateAction<TExtendFile | undefined>>;
};

export const UploadAvatarDropZone: FCC<TUploadAvatarDropZoneProps> = ({ setAvatarFile, avatarFile }) => {
  const { id: employeeId } = useParams();
  const [searchParams] = useSearchParams();

  const onChangeFile = async (value: TExtendFile | TExtendFile[] | null) => {
    const file = [value].flat().at(0);
    if (toNumber(file?.size) > convertKbToByte(50)) {
      toast.error("ファイルサイズは50KB以内でお願いします。");
      return;
    }
    if (!file) {
      setAvatarFile(undefined);
      return;
    }
    const token = searchParams.get("token");
    const companyId = toNumber(searchParams.get("companyId"));
    if (!employeeId || !token || !companyId) return;
    try {
      const rs = await uploadEmployeeAvatarApi(employeeId, { file, companyId, employeeId: toNumber(employeeId), token });
      setAvatarFile(Object.assign(file, { filePath: rs.data.filePath }));
    } catch (error) {
      showError({ message: "このURLは無効であるか、以前に使用されています。" });
    }
  };

  return (
    <>
      {avatarFile ? (
        <Avatar imageSrc={URL.createObjectURL(avatarFile)} width={300} height={400} onRemove={() => setAvatarFile(undefined)} />
      ) : (
        <Container>
          <DropZone
            options={{ multiple: false }}
            rules={{ acceptMimeTypes: MIME_TYPES.JPEG, maxSize: convertKbToByte(50) }}
            enableFileList={false}
            onChange={onChangeFile}
          >
            <Stack width={352} alignItems="center">
              <Avatar width={300} height={400} onRemove={() => setAvatarFile(undefined)} />
              <Typography sx={{ textAlign: "center", mt: "16px", whiteSpace: "pre-line" }} variant="body14">
                {`画像ファイルを\nドラッグ&ドロップ`}
              </Typography>
            </Stack>
          </DropZone>
        </Container>
      )}
    </>
  );
};

const Container = styled(Box)`
  padding: 24px;
  border: 1px solid ${divider.middle};
  border-radius: ${rounded.sm};
`;
