import { MAX_INTEGER } from "@/constants/common";
import { INVALID_NUMBER_MAX } from "@/constants/invalids";
import { RESIDENCE_STATUS, YES_NO_AND_NOT_APPLICABLE_ANSWER, YES_NO_ANSWER } from "@/types/enum";
import { floorToTwoDecimals } from "@/utils/number";
import { replaceStr } from "@/utils/string";
import { validator } from "@/utils/validator";
import { dayjs } from "@/utils/dayjs";
import { EResidenceCardReceiptMethod } from "@/features/renew-visa/enums/employeeApplicationForm";

export const employeeFamilyMemberSchema = validator.object().shape({
  employeeRelationshipId: validator.string().trim().isSelection().emptyToNull().label("続柄").required(),
  fullName: validator.string().trim().emptyToNull().max(26).autoTransformToFullWidth().label("氏名").required(),
  birthday: validator.mixed().isDayjs().isSelection().label("生年月日").required(),
  nationalityId: validator.string().isSelection().trim().emptyToNull().label("国籍").required(),
  livingWithEmployee: validator.string().isSelection().trim().emptyToNull().label("同居の有無").required(),
  workingLocation: validator.string().trim().emptyToNull().max(60).autoTransformToFullWidth().label("勤務先・通学先名称").required(),
  residenceCode: validator
    .string()
    .trim()
    .emptyToNull()
    .length(12)
    .uppercaseAlphanumericCharactersOnly()
    .autoTransformToHalfWidth()
    .label("在留カード番号／特別永住者証明書番号")
    .required(),
});

export const employeeApplicationDocumentWorkingHistorySchema = validator.object().shape({
  jobName: validator.string().trim().emptyToNull().max(60).label("勤務先名称").notRequired(),
  workingStartYear: validator
    .mixed()
    .isDayjs()
    .test(function (value, context) {
      if (!value) return true;

      if (value && value.isAfter(dayjs().year(1899))) {
        return true;
      }
      return context.createError({ path: context.path, message: ({ label }) => `「${label}」は1900年より後にする必要があります。` });
    })
    .isPastOrToday(`「{{path}}」は${dayjs().year()}年より前にする必要があります。`)
    .label("入社年月（年）")
    .notRequired(),
  workingStartMonth: validator.mixed().isDayjs().label("入社年月（月）").notRequired(),
  workingEndYear: validator
    .mixed()
    .isDayjs()
    .test(function (value, context) {
      if (!value) return true;

      if (value && value.isAfter(dayjs().year(1899))) {
        return true;
      }
      return context.createError({ path: context.path, message: ({ label }) => `「${label}」は1900年より後にする必要があります。` });
    })
    .isPastOrToday(`「{{path}}」は${dayjs().year()}年より前にする必要があります。`)
    .label("退社年月（年）")
    .notRequired(),
  workingEndMonth: validator.mixed().isDayjs().label("退社年月（月）").notRequired(),
});

export const employeeApplicationDocumentDispatchingOrganizationSchema = validator.object().shape({
  organizationName: validator.string().trim().emptyToNull().max(60).autoTransformToFullWidth().label("氏名又は名称").notRequired(),
  organizationIdentificationNumber: validator
    .string()
    .trim()
    .emptyToNull()
    .autoTransformToHalfWidth()
    .numbersOnly()
    .length(13)
    .label("法人番号")
    .notRequired()
    .when("organizationName", {
      is: (value: unknown) => !!value,
      then: (schema) => schema.required(),
    }),
  employmentInsuranceApplicableEstablishmentNumber: validator
    .string()
    .trim()
    .emptyToNull()
    .autoTransformToHalfWidth()
    .numbersOnly()
    .length(11)
    .label("雇用保険適用事業所番号")
    .notRequired(),
  organizationProvince: validator
    .string()
    .trim()
    .isSelection()
    .emptyToNull()
    .label("派遣先所在地（都道府県）")
    .notRequired()
    .when("organizationName", {
      is: (value: unknown) => !!value,
      then: (schema) => schema.required(),
    }),
  organizationMunicipality: validator
    .string()
    .trim()
    .isSelection()
    .emptyToNull()
    .label("派遣先所在地（市区町村）")
    .notRequired()
    .when("organizationName", {
      is: (value: unknown) => !!value,
      then: (schema) => schema.required(),
    }),
  organizationAddress: validator
    .string()
    .trim()
    .emptyToNull()
    .max(80)
    .autoTransformToFullWidth()
    .label("派遣先所在地（都道府県以外）")
    .notRequired()
    .when("organizationName", {
      is: (value: unknown) => !!value,
      then: (schema) => schema.required(),
    }),
  organizationTelNumber: validator
    .string()
    .trim()
    .emptyToNull()
    .numbersOnly()
    .autoTransformToHalfWidth()
    .max(12, replaceStr(INVALID_NUMBER_MAX, { path: "電話番号", max: 12 }))
    .label("電話番号")
    .notRequired()
    .when("organizationName", {
      is: (value: unknown) => !!value,
      then: (schema) => schema.required(),
    }),
  representativeName: validator
    .string()
    .trim()
    .emptyToNull()
    .max(26)
    .autoTransformToFullWidth()
    .label("代表者の氏名")
    .notRequired()
    .when("organizationName", {
      is: (value: unknown) => !!value,
      then: (schema) => schema.required(),
    }),
  startDate: validator
    .mixed()
    .isDayjs()
    .isSelection()
    .label("派遣期間（始期）")
    .notRequired()
    .when("organizationName", {
      is: (value: unknown) => !!value,
      then: (schema) => schema.required(),
    }),
  endDate: validator
    .mixed()
    .isDayjs()
    .isAfterDateOfField("startDate", { startLabel: "派遣期間（始期）", endLabel: "派遣期間（終期）" })
    .isSelection()
    .label("派遣期間（終期）")
    .notRequired()
    .when("organizationName", {
      is: (value: unknown) => !!value,
      then: (schema) => schema.required(),
    }),
});

export const employeeApplicationSchema = validator.object().shape({
  employeeId: validator.string().trim().emptyToNull().required(),
  employeeInfoFullName: validator.string().trim().emptyToNull().label("氏名").required(),
  employeeInfoMaritalStatus: validator.string().isSelection().trim().emptyToNull().label("配偶者の有無").required(),
  employeeInfoLivingWithDependentPerson: validator.string().isSelection().trim().emptyToNull().label("在日親族及び同居者").required(),
  employeeFamilyMembers: validator.array().of(employeeFamilyMemberSchema).required(),
  employeeInfoResidenceCardReceiveMethod: validator.string().isSelection().trim().emptyToNull().label("在留カードの受領方法").required(),
  employeeInfoResidenceCardReceiveGovernmentCounterId: validator
    .string()
    .isSelection()
    .trim()
    .emptyToNull()
    .label("窓口受け取りの際の受領官署")
    .notRequired()
    .when("employeeInfoResidenceCardReceiveMethod", {
      is: EResidenceCardReceiptMethod.COUNTER,
      then: (schema) => schema.required(),
    }),
  employeeInfoRecentResidenceTax: validator
    .number()
    .emptyToNull()
    .autoTransformToHalfWidth()
    .commas()
    .integer()
    .min(0)
    .max(99999)
    .limit(MAX_INTEGER)
    .label("直近年度の住民税の納税額（単位：千円）")
    .notRequired(),
  employeeInfoBirthday: validator.mixed().isDayjs().isSelection().label("生年月日").required(),
  employeeInfoGender: validator.string().isSelection().trim().emptyToNull().label("性別").notRequired(),
  employeeInfoNationalityId: validator.string().isSelection().trim().emptyToNull().label("国籍").notRequired(),
  employeeInfoHasReceivedPunishmentForCrime: validator
    .string()
    .isSelection()
    .trim()
    .emptyToNull()
    .label("犯罪を理由とする処分を受けたことの有無（日本国外におけるものを含む）※交通違反等による処分を含む")
    .notRequired(),
  employeeInfoPunishmentDetailForCrime: validator
    .string()
    .trim()
    .emptyToNull()
    .max(40)
    .label("犯罪を理由とする処分を受けたことの具体的内容")
    .notRequired(),
  employeeInfoCareer: validator.string().trim().emptyToNull().max(40).label("職業").notRequired(),
  employeeInfoPassportPermanentAddress: validator
    .string()
    .trim()
    .emptyToNull()
    .max(80)
    .autoTransformToFullWidth()
    .fullWidthOnly()
    .label("本国における居住地")
    .notRequired(),
  employeeInfoProvince: validator.string().isSelection().trim().emptyToNull().label("申請人住所（都道府県）").notRequired(),
  employeeInfoMunicipality: validator.string().isSelection().trim().emptyToNull().label("申請人住所（市区町村）").notRequired(),
  employeeInfoAddress: validator.string().trim().emptyToNull().label("申請人住所（都道府県以外）").notRequired(),
  employeeInfoTelNumber: validator.string().trim().emptyToNull().label("電話番号").notRequired(),
  employeeInfoMobileNumber: validator.string().trim().emptyToNull().label("携帯電話番号").notRequired(),
  employeeInfoEmail: validator.string().trim().emptyToNull().label("メールアドレス（支援担当者のアドレスも可）").notRequired(),
  employeeInfoPassportCode: validator.string().trim().emptyToNull().label("旅券番号").notRequired(),
  employeeInfoPassportExpiryDate: validator.mixed().isDayjs().isSelection().label("旅券有効期限").notRequired(),
  employeeInfoTwoResidenceStatusId: validator.string().trim().isSelection().emptyToNull().label("現に有する在留資格").notRequired(),
  employeeInfoTwoResidencePeriodId: validator.string().trim().isSelection().emptyToNull().label("現に有する在留期間").notRequired(),
  employeeInfoTwoResidenceExpiryDate: validator.mixed().isDayjs().isSelection().label("在留期間満了日").notRequired(),
  employeeInfoTwoResidenceCode: validator.string().trim().emptyToNull().label("在留カード番号").notRequired(),
  employeeInfoTwoResidenceDesiredPeriodId: validator.string().trim().isSelection().emptyToNull().label("希望する在留期間").required(),
  employeeInfoTwoResidenceChangeReason: validator.string().trim().emptyToNull().max(21).label("更新の理由").required(),
  employeeInfoTwoRasImmiFullName: validator
    .string()
    .trim()
    .emptyToNull()
    .autoTransformToFullWidth()
    .max(26)
    .label("取次者等(オンラインシステム利用者)氏名")
    .required(),
  relatedActivityCompletionProof: validator.string().trim().isSelection().emptyToNull().label("良好に修了したことの証明").notRequired(),
  relatedActivityMajorId: validator.string().isSelection().trim().emptyToNull().label("職種").notRequired(),
  relatedActivityJobTypeId: validator.string().isSelection().trim().emptyToNull().label("作業").notRequired(),
  relatedActivitySkillCertificationOne: validator.string().isSelection().trim().emptyToNull().label("技能水準の評価区分").notRequired(),
  relatedActivitySkillExamOneId: validator.string().isSelection().trim().emptyToNull().label("合格した試験名（技能）１").notRequired(),
  relatedActivitySkillExamLocationOne: validator.string().trim().emptyToNull().label("受験地１").notRequired(),
  relatedActivitySkillExamOverseaOneNationalityId: validator.string().trim().emptyToNull().label("技能受験地名（国外）1").notRequired(),
  relatedActivitySkillOtherEvaluation: validator.string().trim().emptyToNull().max(60).label("その他の評価方法による証明").notRequired(),
  relatedActivityJapaneseCertificationTwo: validator.string().isSelection().trim().emptyToNull().label("日本語能力の評価区分２").notRequired(),
  relatedActivityJapaneseExamTwoId: validator.string().isSelection().trim().emptyToNull().label("合格した試験名（日本語）2").notRequired(),
  relatedActivityJapaneseExamLocationTwo: validator.string().isSelection().trim().emptyToNull().label("受験地2").notRequired(),
  relatedActivityJapaneseExamOverseaTwoNationalityId: validator.string().trim().emptyToNull().label("受験地（国外）2").notRequired(),
  relatedActivityJapaneseCertificationThree: validator.string().isSelection().trim().emptyToNull().label("日本語能力の評価区分３").notRequired(),
  relatedActivityJapaneseExamThreeId: validator.string().isSelection().trim().emptyToNull().label("合格した試験名（日本語）3").notRequired(),
  relatedActivityJapaneseExamLocationThree: validator.string().isSelection().trim().emptyToNull().label("受験地3").notRequired(),
  relatedActivityJapaneseExamOverseaThreeNationalityId: validator.string().trim().isSelection().emptyToNull().label("受験地（国外）3").notRequired(),
  relatedActivitySpecificSkillTransitionPeriod: validator
    .string()
    .isSelection()
    .trim()
    .emptyToNull()
    .label("申請時における特定技能1号での通算在留期間（過去の在留歴含む）")
    .notRequired(),
  relatedActivityHasCollectionForSpecificSkillOne: validator
    .string()
    .isSelection()
    .trim()
    .emptyToNull()
    .label("特定技能雇用契約に係る保証金の徴収その他財産管理又は支払い契約の有無")
    .notRequired(),
  relatedActivityCollectionOrManagementAgencyName: validator
    .string()
    .trim()
    .emptyToNull()
    .max(60)
    .autoTransformToFullWidth()
    .label("有りの場合、徴収又は管理機関名")
    .notRequired()
    .when("relatedActivityHasCollectionForSpecificSkillOne", {
      is: YES_NO_ANSWER.YES,
      then: (schema) => schema.required(),
    }),
  relatedActivityCollectionAmount: validator
    .string()
    .trim()
    .emptyToNull()
    .max(40)
    .label("徴収金額又は管理財産")
    .notRequired()
    .when("relatedActivityHasCollectionForSpecificSkillOne", {
      is: YES_NO_ANSWER.YES,
      then: (schema) => schema.required(),
    }),
  relatedActivityHasUnderstandingAndAgreementOnForeignAgencyFees: validator
    .string()
    .isSelection()
    .trim()
    .emptyToNull()
    .label(
      "特定技能雇用契約に係る申込みの取次又は外国における活動準備に関する外国の機関への費用の支払いについて、その額及び内訳を十分に理解して合意していることの有無",
    )
    .notRequired(),
  relatedActivityForeignAgencyName: validator
    .string()
    .trim()
    .emptyToNull()
    .autoTransformToFullWidth()
    .label("有りの場合、外国の機関名")
    .max(60)
    .notRequired()
    .when("relatedActivityHasUnderstandingAndAgreementOnForeignAgencyFees", {
      is: YES_NO_AND_NOT_APPLICABLE_ANSWER.YES,
      then: (schema) => schema.required(),
    }),
  relatedActivityForeignAgencyFee: validator
    .string()
    .trim()
    .emptyToNull()
    .label("支払額（日本円に換算）")
    .max(40)
    .notRequired()
    .when("relatedActivityHasUnderstandingAndAgreementOnForeignAgencyFees", {
      is: YES_NO_AND_NOT_APPLICABLE_ANSWER.YES,
      then: (schema) => schema.required(),
    }),
  relatedActivityHasComplianceWithProceduresInHomeCountry: validator
    .string()
    .isSelection()
    .trim()
    .emptyToNull()
    .label("国籍又は住居を有する国又は地域において定められる、本邦で行う活動に関連して遵守すべき手続きを経ていることの有無")
    .notRequired(),
  relatedActivityWhetherRegularPayments: validator
    .string()
    .isSelection()
    .trim()
    .emptyToNull()
    .label("本邦において定期的に負担する費用について、対価の内容を十分に理解して合意していることの有無")
    .notRequired(),
  relatedActivityHasEffortToTransferSkillsToHomeCountry: validator
    .string()
    .isSelection()
    .trim()
    .emptyToNull()
    .label("技能実習によって本邦において習得、習熟又は熟達した技能等の本国への移転に努めることの有無")
    .notRequired()
    .when("employeeInfoTwoResidenceStatusId", {
      is: RESIDENCE_STATUS.SPECIFIED_SKILL_NO1.toString(),
      then: (schema) =>
        schema.test("checkRelatedActivityHasEffortToTransferSkillsToHomeCountry", "", function (value) {
          const { path, createError } = this;
          if (value !== YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE) {
            return createError({ path, message: () => "技能特定1号の場合、「非該当」を選ぶ必要があります。" });
          } else {
            return true;
          }
        }),
    }),
  relatedActivityHasComplianceWithIndustrySpecificCriteria: validator
    .string()
    .isSelection()
    .trim()
    .emptyToNull()
    .label("申請人につき特定産業分野に特有の事情に鑑みて告示で定められる基準に適合していることの有無")
    .notRequired(),
  contractSalaryPaymentMethod: validator.string().isSelection().trim().emptyToNull().label("報酬の支払い方法").required(),
  contractHourlySalary: validator
    .number()
    .commas()
    .emptyToNull()
    .autoTransformToHalfWidth()
    .integer()
    .min(0)
    .max(99999999)
    .label("基本給の時間換算額（円）")
    .required(),
  contractMonthlySalaryForJapanesePerson: validator
    .number()
    .commas()
    .emptyToNull()
    .autoTransformToHalfWidth()
    .integer()
    .min(1)
    .max(99999999)
    .label("同等の業務に従事する日本人の月額報酬（円）")
    .notRequired(),
  contractStartDate: validator.mixed().isDayjs().isSelection().label("始期").notRequired(),
  contractEndDate: validator.mixed().isDayjs().isSelection().label("終期").notRequired(),
  contractSpecificIndustryId: validator.string().isSelection().trim().emptyToNull().label("特定産業分野1").notRequired(),
  contractSpecificBusinessClassificationId: validator.string().isSelection().trim().emptyToNull().label("業務区分1").notRequired(),
  contractSpecificIndustryTwoId: validator.string().isSelection().trim().emptyToNull().label("特定産業分野2").notRequired(),
  contractSpecificBusinessClassificationTwoId: validator.string().isSelection().trim().emptyToNull().label("業務区分2").notRequired(),
  contractSpecificIndustryThreeId: validator.string().isSelection().trim().emptyToNull().label("特定産業分野3").notRequired(),
  contractSpecificBusinessClassificationThreeId: validator.string().isSelection().trim().emptyToNull().label("業務区分3").notRequired(),
  contractMainOccupationId: validator.string().isSelection().trim().emptyToNull().label("主たる職種").notRequired(),
  contractWeeklyWorkingHours: validator
    .number()
    .commas()
    .emptyToNull()
    .autoTransformToHalfWidth()
    .min(0)
    .max(100)
    .transform((value) => floorToTwoDecimals(value))
    .label("所定労働時間（週平均）")
    .notRequired(),
  contractMonthlyWorkingHours: validator
    .number()
    .commas()
    .emptyToNull()
    .autoTransformToHalfWidth()
    .min(0)
    .max(1000)
    .transform((value) => floorToTwoDecimals(value))
    .label("所定労働時間（月）")
    .notRequired(),
  compensationEqualOrAboveJapaneseStandard: validator
    .string()
    .isSelection()
    .trim()
    .emptyToNull()
    .label("報酬の額が日本人が従事する場合の報酬の額と同等以上であることの有無")
    .notRequired(),
  whetherEquivalentWorkingHoursToRegularEmployee: validator
    .string()
    .isSelection()
    .trim()
    .emptyToNull()
    .label("所定労働時間が通常の労働者の所定労働時間と同等であることの有無")
    .notRequired()
    .test("checkWhetherEquivalentWorkingHoursToRegularEmployee", "", function (value) {
      const { path, createError } = this;
      if (value === YES_NO_ANSWER.NO) {
        return createError({ path, message: () => "本項目は「有り」を選ぶ必要があります。" });
      } else {
        return true;
      }
    }),
  contractMonthlySalary: validator.number().commas().emptyToNull().autoTransformToHalfWidth().integer().min(0).label("月額報酬（円）").notRequired(),
  contractIsDiscriminatedForBeingForeigner: validator
    .string()
    .isSelection()
    .trim()
    .emptyToNull()
    .label("外国人であることを理由として日本人と異なった待遇としている事項の有無")
    .notRequired(),
  contractIsDiscriminatedForBeingForeignerDetail: validator
    .string()
    .trim()
    .emptyToNull()
    .max(60)
    .label("上記事項が「有り」の場合の内容")
    .notRequired()
    .when("contractIsDiscriminatedForBeingForeigner", {
      is: YES_NO_ANSWER.YES,
      then: (schema) => schema.required(),
    }),
  contractHasPaidLeaveForReturningHomeTemporarily: validator
    .string()
    .isSelection()
    .trim()
    .emptyToNull()
    .label("外国人が一時帰国を希望した場合には、必要な有給休暇を取得させるものとしていることの有無")
    .notRequired(),
  contractHasComplianceWithEmploymentStandardsInSpecificIndustry: validator
    .string()
    .isSelection()
    .trim()
    .emptyToNull()
    .label("雇用関係につき特定産業分野に特有の事情に鑑みて告示で定められる基準に適合していることの有無")
    .notRequired(),
  contractHasProvisionForTravelExpensesWhenLeave: validator
    .string()
    .isSelection()
    .trim()
    .emptyToNull()
    .label(
      "外国人が特定技能雇用契約終了後の帰国に要する旅費を負担することができないときは、当該旅費を負担するとともに、出国が円滑になされるような必要な措置を講ずることとしていることの有無",
    )
    .notRequired(),
  complianceWithStandardsForProperResidenceInSpecificIndustry: validator
    .string()
    .isSelection()
    .trim()
    .emptyToNull()
    .label("外国人の健康の状況その他の生活を把握するために必要な措置を講ずることとしていることの有無")
    .notRequired(),
  contractHasSupportForeignerHealthAndLivingConditions: validator
    .string()
    .isSelection()
    .trim()
    .emptyToNull()
    .label("外国人の適正な在留に資するために必要な事項につき特定産業分野に特有の事情に鑑みて告示で定められる基準に適合していることの有無")
    .notRequired(),
  workingHistories: validator.array().of(employeeApplicationDocumentWorkingHistorySchema).required(),
  dispatchingOrganizations: validator.array().of(employeeApplicationDocumentDispatchingOrganizationSchema).required(),
});

//draft schema

export const employeeFamilyMemberDraftSchema = validator.object().shape({
  employeeRelationshipId: validator.string().trim().isSelection().emptyToNull().nullable(),
  fullName: validator.string().trim().emptyToNull().max(26).autoTransformToFullWidth().nullable(),
  birthday: validator.mixed().isDayjs().isSelection().nullable(),
  nationalityId: validator.string().isSelection().trim().emptyToNull().nullable(),
  livingWithEmployee: validator.string().isSelection().trim().emptyToNull().nullable(),
  workingLocation: validator.string().trim().emptyToNull().max(60).autoTransformToFullWidth().nullable(),
  residenceCode: validator
    .string()
    .isSelection()
    .trim()
    .emptyToNull()
    .length(12)
    .uppercaseAlphanumericCharactersOnly()
    .autoTransformToHalfWidth()
    .nullable(),
});

export const employeeApplicationDocumentWorkingHistoryDraftSchema = validator.object().shape({
  jobName: validator.string().trim().emptyToNull().max(60).lettersOnly().nullable(),
  workingStartYear: validator
    .mixed()
    .isDayjs()
    .test(function (value, context) {
      if (value?.isAfter(dayjs().year(1899))) {
        return true;
      }
      return context.createError({ path: context.path, message: ({ label }) => `「${label}」は1900年より後にする必要があります。` });
    })
    .isPastOrToday(`「{{path}}」は${dayjs().year()}年より前にする必要があります。`)
    .label("入社年月（年）")
    .notRequired(),
  workingStartMonth: validator.mixed().isDayjs().label("入社年月（月）").notRequired(),
  workingEndYear: validator
    .mixed()
    .isDayjs()
    .test(function (value, context) {
      if (value?.isAfter(dayjs().year(1899))) {
        return true;
      }
      return context.createError({ path: context.path, message: ({ label }) => `「${label}」は1900年より後にする必要があります。` });
    })
    .isPastOrToday(`「{{path}}」は${dayjs().year()}年より前にする必要があります。`)
    .label("退社年月（年）")
    .notRequired(),
  workingEndMonth: validator.mixed().isDayjs().label("退社年月（月）").notRequired(),
});

export const employeeApplicationDocumentDispatchingOrganizationDraftSchema = validator.object().shape({
  organizationName: validator.string().trim().emptyToNull().max(60).autoTransformToFullWidth().nullable(),
  organizationIdentificationNumber: validator.string().trim().emptyToNull().max(13).autoTransformToHalfWidth().nullable(),
  employmentInsuranceApplicableEstablishmentNumber: validator.string().trim().emptyToNull().autoTransformToHalfWidth().nullable(),
  organizationProvince: validator.string().trim().isSelection().emptyToNull().nullable(),
  organizationMunicipality: validator.string().trim().isSelection().emptyToNull().nullable(),
  organizationAddress: validator.string().trim().isSelection().emptyToNull().max(80).autoTransformToFullWidth().nullable(),
  organizationTelNumber: validator
    .string()
    .trim()
    .emptyToNull()
    .numbersOnly()
    .autoTransformToHalfWidth()
    .max(12, replaceStr(INVALID_NUMBER_MAX, { path: "電話番号", max: 12 }))
    .nullable(),
  representativeName: validator.string().trim().emptyToNull().max(26).autoTransformToFullWidth().nullable(),
  startDate: validator.mixed().isDayjs().isSelection().nullable(),
  endDate: validator.mixed().isDayjs().isSelection().nullable(),
});

export const employeeApplicationDraftSchema = validator.object().shape({
  employeeId: validator.string().trim().emptyToNull().required(),
  employeeInfoFullName: validator.string().trim().emptyToNull().nullable(),
  employeeInfoMaritalStatus: validator.string().isSelection().trim().emptyToNull().nullable(),
  employeeInfoLivingWithDependentPerson: validator.string().isSelection().trim().emptyToNull().nullable(),
  employeeFamilyMembers: validator.array().of(employeeFamilyMemberDraftSchema).required(),
  employeeInfoResidenceCardReceiveMethod: validator.string().isSelection().trim().emptyToNull().nullable(),
  employeeInfoResidenceCardReceiveGovernmentCounterId: validator.string().isSelection().trim().emptyToNull().nullable(),
  employeeInfoRecentResidenceTax: validator
    .number()
    .emptyToNull()
    .autoTransformToHalfWidth()
    .commas()
    .positive()
    .integer()
    .max(99999)
    .limit(MAX_INTEGER)
    .nullable(),
  employeeInfoBirthday: validator.mixed().isDayjs().isSelection().nullable(),
  employeeInfoGender: validator.string().isSelection().trim().emptyToNull().nullable(),
  employeeInfoNationalityId: validator.string().isSelection().trim().emptyToNull().nullable(),
  employeeInfoHasReceivedPunishmentForCrime: validator.string().isSelection().trim().emptyToNull().nullable(),
  employeeInfoPunishmentDetailForCrime: validator.string().trim().emptyToNull().max(40).nullable(),
  employeeInfoCareer: validator.string().trim().emptyToNull().max(40).nullable(),
  employeeInfoPassportPermanentAddress: validator.string().trim().emptyToNull().max(80).autoTransformToFullWidth().fullWidthOnly().nullable(),
  employeeInfoProvince: validator.string().isSelection().trim().emptyToNull().nullable(),
  employeeInfoMunicipality: validator.string().isSelection().trim().emptyToNull().nullable(),
  employeeInfoAddress: validator.string().trim().emptyToNull().nullable(),
  employeeInfoTelNumber: validator.string().trim().emptyToNull().nullable(),
  employeeInfoMobileNumber: validator.string().trim().emptyToNull().nullable(),
  employeeInfoEmail: validator.string().trim().emptyToNull().nullable(),
  employeeInfoPassportCode: validator.string().trim().emptyToNull().nullable(),
  employeeInfoPassportExpiryDate: validator.mixed().isDayjs().isSelection().nullable(),
  employeeInfoTwoResidenceStatusId: validator.string().trim().isSelection().emptyToNull().nullable(),
  employeeInfoTwoResidencePeriodId: validator.string().trim().isSelection().emptyToNull().nullable(),
  employeeInfoTwoResidenceExpiryDate: validator.mixed().isDayjs().isSelection().nullable(),
  employeeInfoTwoResidenceCode: validator.string().trim().emptyToNull().nullable(),
  employeeInfoTwoResidenceDesiredPeriodId: validator.string().trim().isSelection().emptyToNull().nullable(),
  employeeInfoTwoResidenceChangeReason: validator.string().trim().emptyToNull().max(21).nullable(),
  employeeInfoTwoRasImmiFullName: validator.string().trim().emptyToNull().max(21).nullable(),
  relatedActivityCompletionProof: validator.string().trim().isSelection().emptyToNull().nullable(),
  relatedActivityMajorId: validator.string().isSelection().trim().emptyToNull().nullable(),
  relatedActivityJobTypeId: validator.string().isSelection().trim().emptyToNull().nullable(),
  relatedActivitySkillCertificationOne: validator.string().isSelection().trim().emptyToNull().nullable(),
  relatedActivitySkillExamOneId: validator.string().isSelection().trim().emptyToNull().nullable(),
  relatedActivitySkillExamLocationOne: validator.string().trim().emptyToNull().nullable(),
  relatedActivitySkillExamOverseaOneNationalityId: validator.string().trim().emptyToNull().nullable(),
  relatedActivitySkillOtherEvaluation: validator.string().trim().emptyToNull().max(60).nullable(),
  relatedActivityJapaneseCertificationTwo: validator.string().isSelection().trim().emptyToNull().nullable(),
  relatedActivityJapaneseExamTwoId: validator.string().isSelection().trim().emptyToNull().nullable(),
  relatedActivityJapaneseExamLocationTwo: validator.string().isSelection().trim().emptyToNull().nullable(),
  relatedActivityJapaneseExamOverseaTwoNationalityId: validator.string().trim().emptyToNull().nullable(),
  relatedActivityJapaneseCertificationThree: validator.string().isSelection().trim().emptyToNull().nullable(),
  relatedActivityJapaneseExamThreeId: validator.string().isSelection().trim().emptyToNull().nullable(),
  relatedActivityJapaneseExamLocationThree: validator.string().isSelection().trim().emptyToNull().nullable(),
  relatedActivityJapaneseExamOverseaThreeNationalityId: validator.string().trim().isSelection().emptyToNull().nullable(),
  relatedActivityHasCollectionForSpecificSkillOne: validator.string().isSelection().trim().emptyToNull().nullable(),
  relatedActivityCollectionOrManagementAgencyName: validator.string().trim().emptyToNull().max(60).nullable(),
  relatedActivityCollectionAmount: validator.string().trim().emptyToNull().max(40).nullable(),
  relatedActivityHasUnderstandingAndAgreementOnForeignAgencyFees: validator.string().isSelection().trim().emptyToNull().nullable(),
  relatedActivityForeignAgencyName: validator.string().trim().emptyToNull().nullable(),
  relatedActivityForeignAgencyFee: validator.string().trim().emptyToNull().nullable(),
  relatedActivityHasComplianceWithProceduresInHomeCountry: validator.string().isSelection().trim().emptyToNull().nullable(),
  relatedActivityWhetherRegularPayments: validator.string().isSelection().trim().emptyToNull().nullable(),
  relatedActivityHasEffortToTransferSkillsToHomeCountry: validator.string().isSelection().trim().emptyToNull().nullable(),
  relatedActivityHasComplianceWithIndustrySpecificCriteria: validator.string().isSelection().trim().emptyToNull().nullable(),
  contractSalaryPaymentMethod: validator.string().isSelection().trim().emptyToNull().nullable(),
  contractHourlySalary: validator.number().commas().emptyToNull().autoTransformToHalfWidth().integer().min(0).max(99999999).nullable(),
  contractMonthlySalaryForJapanesePerson: validator
    .number()
    .commas()
    .emptyToNull()
    .autoTransformToHalfWidth()
    .integer()
    .min(0)
    .max(99999999)
    .nullable(),
  contractStartDate: validator.mixed().isDayjs().isSelection().nullable(),
  contractEndDate: validator.mixed().isDayjs().isSelection().nullable(),
  contractSpecificIndustryId: validator.string().isSelection().trim().emptyToNull().nullable(),
  contractSpecificBusinessClassificationId: validator.string().isSelection().trim().emptyToNull().nullable(),
  contractMainOccupationId: validator.string().isSelection().trim().emptyToNull().nullable(),
  contractWeeklyWorkingHours: validator.number().commas().emptyToNull().autoTransformToHalfWidth().integer().min(0).nullable(),
  contractMonthlyWorkingHours: validator.number().commas().emptyToNull().autoTransformToHalfWidth().integer().min(0).nullable(),
  whetherEquivalentWorkingHoursToRegularEmployee: validator.string().isSelection().trim().emptyToNull().nullable(),
  contractMonthlySalary: validator.number().commas().emptyToNull().autoTransformToHalfWidth().integer().min(0).nullable(),
  compensationEqualOrAboveJapaneseStandard: validator.string().isSelection().trim().emptyToNull().nullable(),
  contractIsDiscriminatedForBeingForeigner: validator.string().isSelection().trim().emptyToNull().nullable(),
  contractIsDiscriminatedForBeingForeignerDetail: validator.string().trim().emptyToNull().nullable(),
  contractHasPaidLeaveForReturningHomeTemporarily: validator.string().isSelection().trim().emptyToNull().nullable(),
  contractHasComplianceWithEmploymentStandardsInSpecificIndustry: validator.string().isSelection().trim().emptyToNull().nullable(),
  contractHasProvisionForTravelExpensesWhenLeave: validator.string().isSelection().trim().emptyToNull().nullable(),
  complianceWithStandardsForProperResidenceInSpecificIndustry: validator.string().isSelection().trim().emptyToNull().nullable(),
  contractHasSupportForeignerHealthAndLivingConditions: validator.string().isSelection().trim().emptyToNull().nullable(),
  workingHistories: validator.array().of(employeeApplicationDocumentWorkingHistoryDraftSchema).required(),
  dispatchingOrganizations: validator.array().of(employeeApplicationDocumentDispatchingOrganizationDraftSchema).required(),
});
