import { useEnum } from "@/api/services/global/enum";
import { useInformationDetail } from "@/api/services/main/information";
import { FileItem } from "@/components/elements/FileItem";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { Notification } from "@/components/elements/Notification";
import { DATE_FORMAT } from "@/constants/datetime";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { EMPTY_STR } from "@/constants/string";
import { dayjs } from "@/utils/dayjs";
import { findLabelByValue } from "@/utils/object";
import { Box, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import styled from "styled-components";

export const InformationDetailPage = () => {
  const { data: informationTypes } = useEnum("EInformationType");
  const { data: informationResult, isLoading } = useInformationDetail();
  const informationData = informationResult?.data;
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.INFORMATION.CHECK_UNREAD] });
  }, [queryClient]);

  const renderContent = useMemo(() => {
    if (!informationData) return null;
    return (
      <Stack gap={1}>
        <Typography variant="sub16Semi">{informationData.title}</Typography>
        {informationData.content && <StyledNotificationDetail dangerouslySetInnerHTML={{ __html: informationData.content }} />}
        {informationData.filePath && <FileItem filePath={informationData.filePath} downloadable />}
      </Stack>
    );
  }, [informationData]);

  if (!informationData || isLoading) return <LoadingPage />;

  return (
    <Notification
      tag={findLabelByValue(informationTypes, informationData.type) || EMPTY_STR.TEXT}
      title={dayjs(informationData.createdAt).format(DATE_FORMAT)}
    >
      {renderContent}
    </Notification>
  );
};

const StyledNotificationDetail = styled(Box)`
  p {
    min-height: 24px;
  }
`;
