import { useEnum } from "@/api/services/global/enum";
import { useGetLocalGovernmentCounterList, useGetRegionList } from "@/api/services/global/master";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { RadioGroup } from "@/components/elements/RadioGroup";
import { Select } from "@/components/elements/Select";
import { EmployeeFamilyMemberFormTable } from "@/features/renew-visa/components/applicationStep/formApplication/employeeApplication/EmployeeFamilyMemberFormTable";
import { RefreshEmployeeInfoBtn } from "@/features/renew-visa/components/applicationStep/formApplication/employeeApplication/RefreshEmployeeInfoBtn";
import { EResidenceCardReceiptMethod } from "@/features/renew-visa/enums/employeeApplicationForm";
import { TEmployeeApplicationForm } from "@/features/renew-visa/types/employeeApplicationForm";
import { text } from "@/theme/colors";
import { YES_NO_ANSWER } from "@/types/enum";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionDetails, AccordionSummary, Divider, Grid, Accordion as MuiAccordion, styled } from "@mui/material";
import { FC, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

type TEmployeeInfoOneFormProps = {
  readonly: boolean;
};

export const EmployeeInfoOneForm: FC<TEmployeeInfoOneFormProps> = ({ readonly }) => {
  const methods = useFormContext<TEmployeeApplicationForm>();
  const { control, setValue } = methods;

  const employeeInfoHasReceivedPunishmentForCrimeWatchField = useWatch({ control, name: "employeeInfoHasReceivedPunishmentForCrime" });
  const employeeFamilyMembersWatchField = useWatch({ control, name: "employeeFamilyMembers" });
  const employeeInfoResidenceCardReceiveMethodWatchField = useWatch({ control, name: "employeeInfoResidenceCardReceiveMethod" });
  const employeeInfoProvinceWatchField = useWatch({ control, name: "employeeInfoProvince" });

  const { localGovernmentCounterByProvinceOptions, isLoading: isLoadingLocalGovernmentCounter } = useGetLocalGovernmentCounterList();

  const { isLoading: isLoadingRegion, nationalityOptions } = useGetRegionList();

  const { data: yesNoAnswerOptions } = useEnum("EYesNoAnswer");

  useEffect(() => {
    if (employeeFamilyMembersWatchField && employeeFamilyMembersWatchField.length > 0) {
      setValue("employeeInfoLivingWithDependentPerson", YES_NO_ANSWER.YES);
    } else {
      setValue("employeeInfoLivingWithDependentPerson", YES_NO_ANSWER.NO);
    }
  }, [employeeFamilyMembersWatchField]);

  return (
    <InnerBox title="外国人情報（身分事項1）" bordered p={3} required rightContent={readonly ? undefined : <RefreshEmployeeInfoBtn />}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormField control={control} name="employeeInfoFullName">
            <Input readonly />
          </FormField>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <FormField control={control} name="employeeInfoMaritalStatus">
            <Select enumName="EMaritalStatus" readonly={readonly} />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="employeeInfoLivingWithDependentPerson">
            <Select enumName="EYesNoAnswer" readonly />
          </FormField>
        </Grid>
        <Grid item xs={12}>
          <EmployeeFamilyMemberFormTable readonly={readonly} />
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="employeeInfoResidenceCardReceiveMethod">
            <Select
              enumName="EResidenceCardReceiptMethod"
              readonly={readonly}
              onChange={() => {
                setValue("employeeInfoResidenceCardReceiveGovernmentCounterId", null);
              }}
            />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="employeeInfoResidenceCardReceiveGovernmentCounterId">
            <Select
              loading={isLoadingLocalGovernmentCounter}
              options={employeeInfoProvinceWatchField ? localGovernmentCounterByProvinceOptions(employeeInfoProvinceWatchField) : []}
              readonly={readonly}
              disabled={employeeInfoResidenceCardReceiveMethodWatchField !== EResidenceCardReceiptMethod.COUNTER}
            />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="employeeInfoRecentResidenceTax">
            <Input endAdornment="円" clearable={false} readonly={readonly} />
          </FormField>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12}>
          <MuiAccordionWrapper sx={{ boxShadow: "none", width: "100%" }}>
            <AccordionSummaryWrapper
              sx={{ p: 0, justifyContent: "center", alignItems: "center", width: "160px", marginLeft: "auto" }}
              expandIcon={<ExpandMoreIcon sx={{ color: text.primary, width: "40px", height: "40px" }} />}
            >
              省略項目を表示
            </AccordionSummaryWrapper>
            <AccordionDetails>
              <Divider sx={{ mb: 5 }} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoBirthday">
                    <InputDatePicker readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoGender">
                    <Select enumName="EGender" readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoNationalityId">
                    <Select loading={isLoadingRegion} readonly options={nationalityOptions} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="employeeInfoHasReceivedPunishmentForCrime">
                    <RadioGroup
                      options={yesNoAnswerOptions}
                      disabled={readonly}
                      onChange={(value) => {
                        if (value !== YES_NO_ANSWER.YES) {
                          methods.setValue("employeeInfoPunishmentDetailForCrime", "");
                        }
                      }}
                    />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="employeeInfoPunishmentDetailForCrime">
                    <Input readonly={readonly || employeeInfoHasReceivedPunishmentForCrimeWatchField === YES_NO_ANSWER.NO} />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoCareer">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoPassportPermanentAddress">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoProvince">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoMunicipality">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="employeeInfoAddress">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoTelNumber">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoMobileNumber">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoEmail">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoPassportCode">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoPassportExpiryDate">
                    <InputDatePicker readonly />
                  </FormField>
                </Grid>
              </Grid>
            </AccordionDetails>
          </MuiAccordionWrapper>
        </Grid>
      </Grid>
    </InnerBox>
  );
};

const MuiAccordionWrapper = styled(MuiAccordion)`
  .Mui-expanded {
    min-height: 0;
  }
  &::before {
    display: none;
  }
  & .MuiAccordionDetails-root {
    padding: 0;
  }
`;

const AccordionSummaryWrapper = styled(AccordionSummary)`
  & .MuiAccordionSummary-content {
    margin: 0;
    justify-content: end;
  }
  & .Mui-expanded {
    margin: 0 !important;
  }
`;
