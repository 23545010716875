import { IconCalendar, IconClose } from "@/assets/icons";
import { useFormField } from "@/components/elements/FormField/FormField";
import { IconBox } from "@/components/elements/IconBox";
import { DATE_FORMAT, YEAR_MONTH_STR_FORMAT, MONTH_FORMAT } from "@/constants/datetime";
import { background, blue, divider, lightBlue, state, text } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { rounded } from "@/theme/variables";
import { dayjs } from "@/utils/dayjs";
import { Box, css, styled } from "@mui/material";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { Dayjs, isDayjs } from "dayjs";
import { forwardRef, FC, useEffect, useMemo, useState } from "react";

export type TInputDatePickerSize = "md" | "lg";

export type TInputDatePickerProps = {
  readonly?: boolean;
  previousValue?: Dayjs;
  nonControl?: boolean;
  previous?: boolean;
  placeholder?: string;
  error?: boolean;
  format?: string;
  enableCloseIcon?: boolean;
} & Omit<DatePickerProps<Dayjs>, "size" | "readOnly">;

export const InputDatePicker = forwardRef<FC, TInputDatePickerProps>(
  (
    {
      readonly = false,
      previousValue,
      placeholder = "選択してください",
      nonControl = false,
      error,
      format = DATE_FORMAT,
      maxDate = dayjs().year(2199),
      enableCloseIcon = true,
      ...rest
    },
    _ref,
  ) => {
    const { field, fieldState } = useFormField(nonControl);
    const [open, setOpen] = useState(false);
    const value = field?.value ?? rest.value;

    useEffect(() => {
      if (isDayjs(value)) {
        field?.onBlur();
      }
    }, [value]);

    const clearValue = () => {
      field?.onChange(null);
      rest.onChange?.(null, {
        validationError: null,
      });
      field?.onBlur();
    };

    const isPrevious = useMemo(() => {
      if (fieldState?.isDirty === false) return true;
      const curValue = value;
      if (isDayjs(curValue) && isDayjs(previousValue)) {
        return curValue.format(DATE_FORMAT) == previousValue?.format(DATE_FORMAT);
      }
      return false;
    }, [fieldState?.isDirty, previousValue, field?.value, rest.value]);

    return (
      <InputDatePickerWrap previous={isPrevious}>
        {!readonly && !rest.disabled && <InputPointer onClick={() => setOpen(true)} />}
        <PickerIcon onClick={() => setOpen(true)} sx={{ pointerEvents: readonly ? "none" : "" }}>
          <IconCalendar />
        </PickerIcon>
        {isDayjs(value) && !rest.disabled && !readonly && enableCloseIcon && (
          <ClearIcon onClick={clearValue}>
            <IconClose fontSize="20px" color={text.primary} />
          </ClearIcon>
        )}
        <DatePicker
          slotProps={{
            field: { clearable: true, onClear: () => {} },
            textField: {
              placeholder: placeholder,
              error: Boolean(fieldState?.error) || error,
            },
            calendarHeader: {
              format: YEAR_MONTH_STR_FORMAT,
              sx: {
                display: format === MONTH_FORMAT ? "none" : "flex",
              },
            },
          }}
          format={format}
          open={open}
          onClose={() => setOpen(false)}
          disableOpenPicker
          closeOnSelect
          readOnly={readonly}
          maxDate={maxDate}
          {...rest}
          {...field}
          value={value ?? null}
        />
      </InputDatePickerWrap>
    );
  },
);

const options = { shouldForwardProp: (propName: string) => !["previous"].includes(propName) };

const InputPointer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
`;

const InputDatePickerWrap = styled(Box, options)<{ previous: boolean }>`
  position: relative;
  .MuiTextField-root {
    width: 100%;
  }
  .MuiInputBase-root {
    background: ${background.white};
    padding: 6px 16px;
    padding-left: 40px;
    width: 100%;
    border-radius: ${rounded.xs};
    input {
      padding: 0;
      color: ${text.primary};
      caret-color: ${blue[70]};
      ${css(typography.body14)}
      &::placeholder {
        opacity: 1;
        color: ${text.tertiary}!important;
      }
    }
    fieldset {
      border-color: ${divider.middle};
      border-radius: ${rounded.xs};
      border-width: 1px !important;
    }

    &:hover,
    &.Mui-focused {
      fieldset {
        border-color: ${blue[70]};
      }
    }
    &.Mui-error {
      background: ${background.error};
      fieldset {
        border-color: ${state.error_1}!important;
      }
    }
    &.Mui-disabled {
      background: ${background.disable};
      fieldset {
        border-color: ${divider.middle}!important;
      }
    }
    &.Mui-readOnly {
      background: ${background.disable};
      pointer-events: none;
      input {
        color: ${text.primary}!important;
        &::placeholder {
          opacity: 0;
        }
      }
      fieldset {
        border-color: ${divider.middle}!important;
      }
    }
    ${({ previous }) => previous && PreviousInputDatePicker};
  }
`;

const PreviousInputDatePicker = css`
  input {
    color: ${lightBlue[60]};
  }
`;

const PickerIcon = styled(IconBox)`
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  z-index: 1;
`;

const ClearIcon = styled(IconBox)`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  z-index: 2;
`;
