import { useEmployeeInfo } from "@/api/services/main/employee";
import {
  cancelRetirementDocumentApi,
  checkToCompleteRetirementDocumentApi,
  setCompleteRetirementDocumentApi,
  useEmployeeRetirementDocument,
} from "@/api/services/main/retirement-document";
import { ConfirmSaveModal } from "@/components/commons/ConfirmSaveModal";
import { Button } from "@/components/elements/Button";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { TopPage } from "@/components/elements/TopPage";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { DocumentsContent } from "@/features/retirement/components/retirement-document/documents/DocumentsContent";
import { useCheckRetirementDocument } from "@/features/retirement/hooks/useCheckRetirmentDocument";
import { useDialog } from "@/hooks/useDialog";
import { AppRoutes } from "@/routes/config";
import { background, button, state } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { rounded, spacing } from "@/theme/variables";
import { CHECK_COMPLETE_RETIREMENT_RESULT, DOCUMENT_STATUS } from "@/types/enum";
import { showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { Box, css, Stack, styled, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

type TRetirementDocumentPageProps = {};

export const RetirementDocumentPage: FC<TRetirementDocumentPageProps> = () => {
  const { data: documentResult, isLoading } = useEmployeeRetirementDocument();
  const { documentGroupId } = useParams();
  const documentData = documentResult?.data.retirementDocuments;
  const queryClient = useQueryClient();
  const { finished, completed } = useCheckRetirementDocument();
  const navigate = useNavigate();
  const confirmSubmit = useDialog();
  // const confirmDeleteRenewVisa = useDialog();
  const isCompleted = documentData?.every((document) => document.status === DOCUMENT_STATUS.COMPLETE);
  const isInProgressDocument = documentData?.length === 0 ? false : documentData?.some((doc) => doc.status === DOCUMENT_STATUS.IN_PROGRESS);
  const { data: employeeResult } = useEmployeeInfo(
    documentData && documentData.length > 0 && documentData[0].employeeId ? documentData[0].employeeId : undefined,
  );
  const cancelDocument = async () => {
    try {
      if (documentGroupId) {
        const rs = await cancelRetirementDocumentApi(documentGroupId);
        navigate(AppRoutes.retirement);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RETIREMENT_DOCUMENTS.FETCH_LIST] });
      }
    } catch (error) {
      showError(error);
    }
  };

  const submitDocument = async () => {
    try {
      const agree = await confirmSubmit.show();
      if (!agree || !documentGroupId) return;

      // const checkRenewVisaResult = await checkToCompleteRetirementDocumentApi(documentGroupId);
      // if (checkRenewVisaResult.data === CHECK_COMPLETE_RETIREMENT_RESULT.CAN_UPDATE_AND_DELETE_RENEW_VISA) {
      //   const agreeRenewVisa = await confirmDeleteRenewVisa.show();
      //   if (!agreeRenewVisa) return;
      // }

      const rs = await setCompleteRetirementDocumentApi(documentGroupId);
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RETIREMENT_DOCUMENTS.FETCH_LIST] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RETIREMENT_DOCUMENTS.FETCH_DOCUMENT, documentGroupId] });
      navigate(AppRoutes.retirement);
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
    } catch (error) {
      showError(error);
    }
  };

  const retirementDate = useMemo(() => employeeResult?.data.retirementDate, [employeeResult]);

  if (isLoading || !documentData) return <LoadingPage />;
  return (
    <Box>
      <TopPage title="" backUrl={AppRoutes.retirement} />
      <Stack mt={-3} mb={3} flexDirection="row" justifyContent="space-between">
        <Typography variant="head24Bold">退職手続き一覧</Typography>
        {isCompleted ? null : (
          <Stack flexDirection="row" alignItems="flex-start" gap={2}>
            <Button variant="secondary" size="md" disabled={!isInProgressDocument} onClick={cancelDocument}>
              提出せずに破棄する
            </Button>
            <Stack justifyContent="center" alignItems="center" gap={1}>
              <CompletedButton disabled={!finished || !retirementDate || new Date(retirementDate) > new Date()} onClick={submitDocument}>
                随時届出を完了
              </CompletedButton>
              {!completed && (
                <Typography variant="body14Bold" color={state.error_1}>
                  全てのステップを完了してください。
                </Typography>
              )}
              {completed && retirementDate && new Date(retirementDate) > new Date() && (
                <Typography variant="body14Bold" color={state.error_1}>
                  退職手続きの届出は退職日以降にのみ完了できます。
                </Typography>
              )}
            </Stack>
          </Stack>
        )}
      </Stack>
      <DocumentsContent />
      <ConfirmSaveModal open={confirmSubmit.open} onOk={confirmSubmit.confirm} onClose={confirmSubmit.cancel}>
        <Typography>退職に切り替えた外国人は、irohanaサポートの対象外となります。よろしいですか？</Typography>
      </ConfirmSaveModal>
      {/* <ConfirmSaveModal open={confirmDeleteRenewVisa.open} onOk={confirmDeleteRenewVisa.confirm} onClose={confirmDeleteRenewVisa.cancel}>
        <Typography>随時届出を完了すると、未完了の期間更新ドキュメントが削除されます。本当に更新してもよろしいですか？</Typography>
      </ConfirmSaveModal> */}
    </Box>
  );
};

const CompletedButton = styled(Button)`
  &.MuiButtonBase-root {
    background-color: ${button.secondary}!important;
    border-radius: ${rounded.sm};
    padding: ${`10px ${spacing.md}`};
    color: ${background.white}!important;
    border: none !important;
    ${css(typography.sub16Semi)}
    cursor: pointer;
    &:disabled {
      background-color: ${button.disable}!important;
    }
  }
`;
