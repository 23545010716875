import { useCompanyInfo } from "@/api/services/main/company";
import {
  useGetRenewVisaCompanyApplicationDocumentInfo,
  useGetRenewVisaDocumentInfo,
  useLatestCompleteGetRenewVisaDocumentInfo,
} from "@/api/services/main/renew-visa";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { TopPage } from "@/components/elements/TopPage";
import { COMPANY_CONTAINER } from "@/constants/ui";
import { CompanyApplicationForm } from "@/features/renew-visa/components/applicationStep/formApplication/companyApplication/CompanyApplicationForm";
import { convertResToCompanyApplicationFormData } from "@/features/renew-visa/utils/convertResToCompanyApplicationFormData";
import { findRenewVisaDocumentStep } from "@/features/renew-visa/utils/findDocumentStep";
import { AppRoutes } from "@/routes/config";
import { FCC } from "@/types/common";
import { DOCUMENT_STATUS, DOCUMENT_STEP_KEYS, RENEW_VISA_DOCUMENT_STATUS } from "@/types/enum";
import { getFullName } from "@/utils/string";
import { getDynamicRoute } from "@/utils/url";
import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

type TRenewVisaPresentCompanyApplicationPageProps = {};

export const RenewVisaPresentCompanyApplicationPage: FCC<TRenewVisaPresentCompanyApplicationPageProps> = ({}) => {
  const { renewVisaDocumentId, documentStepId } = useParams();

  const { data: renewVisaDocument, isLoading: isLoadingRenewVisaDocument } = useGetRenewVisaDocumentInfo();

  const { data: companyApplicationData, isLoading: isLoadingCompanyApplication } = useGetRenewVisaCompanyApplicationDocumentInfo();

  const { data: companyData, isLoading: isLoadingCompanyData } = useCompanyInfo();

  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (!companyApplicationData) {
      setIsEditable(true);
    } else {
      if (companyApplicationData.status === DOCUMENT_STATUS.IN_PROGRESS) {
        setIsEditable(true);
      } else if (companyApplicationData.status === DOCUMENT_STATUS.COMPLETE) {
        setIsEditable(false);
      }
    }
  }, [companyApplicationData]);

  const { data: latestCompleteGetRenewVisaDocumentRes, isLoading: isLoadingLatestCompleteGetRenewVisaDocument } =
    useLatestCompleteGetRenewVisaDocumentInfo();
  const latestApplicationStepData = latestCompleteGetRenewVisaDocumentRes
    ? findRenewVisaDocumentStep(latestCompleteGetRenewVisaDocumentRes.data, DOCUMENT_STEP_KEYS.RENEW_VISA_CREATE_DOCUMENT)
    : undefined;

  const { data: companyApplicationLatestCompleteData } = useGetRenewVisaCompanyApplicationDocumentInfo({
    renewVisaDocumentId: latestApplicationStepData?.renewVisaDocumentId,
    applicationDocumentStepId: latestApplicationStepData?.id,
  });

  const isLoading = useMemo(
    () => isLoadingRenewVisaDocument || isLoadingCompanyApplication || isLoadingCompanyData || isLoadingLatestCompleteGetRenewVisaDocument,
    [isLoadingRenewVisaDocument, isLoadingCompanyApplication, isLoadingCompanyData, isLoadingLatestCompleteGetRenewVisaDocument],
  );

  const isFinishRenewVisaDocument = useMemo(() => renewVisaDocument?.status === RENEW_VISA_DOCUMENT_STATUS.COMPLETE, [renewVisaDocument]);

  if (isLoading) return <LoadingPage />;

  return (
    <Box maxWidth={COMPANY_CONTAINER}>
      <TopPage
        title={`${getFullName({ firstName: renewVisaDocument?.employee.firstName ?? "", middleName: renewVisaDocument?.employee.middleName, lastName: renewVisaDocument?.employee.lastName ?? "" })}さんの申請データの作成`}
        backUrl={getDynamicRoute(AppRoutes.renewVisaPresentEmployeeApplication, { renewVisaDocumentId, documentStepId })}
        isDraft={companyApplicationData?.isDraft}
      />
      <CompanyApplicationForm
        readonly={isFinishRenewVisaDocument}
        updater={companyApplicationData?.updater}
        isDraft={Boolean(companyApplicationData?.isDraft)}
        isEditable={isEditable}
        setIsEditable={setIsEditable}
        previousValues={convertResToCompanyApplicationFormData(
          renewVisaDocument?.employee,
          companyData?.data,
          companyApplicationData,
          companyApplicationLatestCompleteData,
        )}
      />
    </Box>
  );
};
