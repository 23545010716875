import { TEmployeeRenewVisaDocumentStep } from "@/api/entities/employee-renew-visa-document-step.entity";
import { useEmployeeInfo } from "@/api/services/main/employee";
import {
  fetchRenewVisaDocumentListToExportCsvApi,
  useGetRenewVisaBasicInformationDocumentInfo,
  useGetRenewVisaUploadAvatarFileDocumentInfo,
  useGetRenewVisaUploadFileDocumentInfo,
} from "@/api/services/main/renew-visa";
import { Button } from "@/components/elements/Button";
import { StepCard } from "@/components/elements/StepCard";
import { FormApplicationStep } from "@/features/renew-visa/components/applicationStep/formApplication/FormApplicationStep";
import { UploadApplicationStep } from "@/features/renew-visa/components/applicationStep/uploadApplication/UploadApplicationStep";
import { UploadAvatarStep } from "@/features/renew-visa/components/applicationStep/UploadAvatar/UploadAvatarStep";
import { useConvertRenewVisaToCsvData } from "@/features/renew-visa/hook/useConvertRenewVisaToCsvData";
import { useMergeRenewVisaFile } from "@/features/renew-visa/hook/useMergeRenewVisaFile";
import { DOCUMENT_STATUS } from "@/types/enum";
import { downloadFile, downloadFileFromPath, downloadJSONByCSV } from "@/utils/export";
import { getFullName } from "@/utils/string";
import { Typography } from "@mui/material";
import { FC, useState } from "react";

export type TRenewVisaApplicationStepProps = {
  stepData: TEmployeeRenewVisaDocumentStep;
  readonly: boolean;
  employeeId: number;
  basicInfoStepData: TEmployeeRenewVisaDocumentStep;
};

export const RenewVisaApplicationStep: FC<TRenewVisaApplicationStepProps> = ({ stepData, readonly, basicInfoStepData, employeeId }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { convertRenewVisaToCsvData } = useConvertRenewVisaToCsvData();
  const { data: uploadFileData } = useGetRenewVisaUploadFileDocumentInfo({
    documentStepId: stepData.id,
    enabled: !!stepData.id,
  });
  const { data: uploadAvatarFileData } = useGetRenewVisaUploadAvatarFileDocumentInfo({
    documentStepId: stepData.id,
  });
  const { data: employeeData } = useEmployeeInfo(employeeId);
  const { data: basicInfoData } = useGetRenewVisaBasicInformationDocumentInfo({
    documentStepId: basicInfoStepData.id,
    enabled: !!basicInfoStepData.id,
  });
  const { generateMergedPdf } = useMergeRenewVisaFile(stepData.renewVisaDocumentId);

  const handleExportCsv = async () => {
    try {
      if (!employeeData?.data) return;
      const rs = await fetchRenewVisaDocumentListToExportCsvApi({ ids: [stepData.renewVisaDocumentId] });
      const renewVisaList = rs.data
        ? rs.data.map((item) => ({ employeeApplication: item.employeeApplication, companyApplication: item.companyApplication }))
        : [];
      if (renewVisaList.length === 0) return;
      const csvData = convertRenewVisaToCsvData(renewVisaList);
      const { firstName, lastName, middleName } = employeeData?.data;
      const fullName = getFullName({ firstName, lastName, middleName });
      downloadJSONByCSV(csvData, `${fullName}.csv`, "utf-16le", {
        prependHeader: true,
        delimiter: { field: "\t", eol: "\r\n" },
        expandArrayObjects: true,
        escapeHeaderNestedDots: false,
      });
    } catch (error) {}
  };

  const handleDownloadMergedPdf = async () => {
    try {
      const mergedPdf = await generateMergedPdf();
      if (mergedPdf && employeeData?.data) {
        const { firstName, lastName, middleName } = employeeData?.data;
        const fullName = getFullName({ firstName, lastName, middleName });
        await downloadFile(`${fullName}の添付書類.pdf`, mergedPdf);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadAvatar = async () => {
    try {
      if (uploadAvatarFileData?.avatarFilePath && employeeData?.data) {
        const { firstName, lastName, middleName } = employeeData?.data;
        const fullName = getFullName({ firstName, lastName, middleName });
        await downloadFileFromPath(uploadAvatarFileData?.avatarFilePath, `${fullName}の顔写真`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      await handleExportCsv();
      await handleDownloadMergedPdf();
      await handleDownloadAvatar();
    } catch (error) {
      console.error(error);
    }
    setIsDownloading(false);
  };

  return (
    <StepCard
      title={`ステップ${stepData.masterDocumentStep.stepOrder}`}
      subTitle={stepData.masterDocumentStep.stepName}
      buttons={
        <Button
          variant="primary"
          size="sm"
          disabled={
            readonly || !uploadFileData || !uploadAvatarFileData || !employeeData || !basicInfoData || stepData.status !== DOCUMENT_STATUS.COMPLETE
          }
          onClick={handleDownload}
          isLoading={isDownloading}
        >
          提出形式で保存
        </Button>
      }
    >
      <Typography component="p" variant="body14" mb="16px">
        添付書類は取得に時間がかかる場合があります。余裕を持って手続きを進めましょう。
      </Typography>
      <FormApplicationStep stepData={stepData} />
      <UploadApplicationStep stepData={stepData} basicInfoStepData={basicInfoStepData} />
      <UploadAvatarStep readonly={readonly} employeeId={employeeId} stepData={stepData} />
    </StepCard>
  );
};
