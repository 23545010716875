import { TEmployeeRenewVisaDocumentStep } from "@/api/entities/employee-renew-visa-document-step.entity";
import { useEnum } from "@/api/services/global/enum";
import { createRenewVisaBasicInformationDocumentApi, updateRenewVisaBasicInformationDocumentApi } from "@/api/services/main/renew-visa";
import { TGetTRenewVisaBasicInformationDocumentOutputDto } from "@/api/services/main/renew-visa/dtos/get-renew-visa-basic-information-document.dto";
import { Button } from "@/components/elements/Button";
import { CheckboxGroup } from "@/components/elements/CheckboxGroup";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { StepCard } from "@/components/elements/StepCard";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { renewVisaBasicInfoSchema } from "@/features/renew-visa/schema/renewVisaBasicInfoSchema";
import { TRenewVisaBasicInfoForm } from "@/features/renew-visa/types/renewVisaBasicInfoForm";
import { text } from "@/theme/colors";
import { showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

export type TBasicInfoStepCardProps = {
  stepData: TEmployeeRenewVisaDocumentStep;
  basicInfoData?: TGetTRenewVisaBasicInformationDocumentOutputDto;
  readonly: boolean;
};

export const BasicInfoStepCard: FC<TBasicInfoStepCardProps> = ({ stepData, basicInfoData, readonly }) => {
  const { stepOrder, stepName } = stepData.masterDocumentStep;
  const { id: documentStepId } = stepData;

  const { renewVisaDocumentId } = useParams();
  const queryClient = useQueryClient();

  const { data: renewVisaConditionOptions } = useEnum("ERenewVisaCondition");

  const methods = useForm<TRenewVisaBasicInfoForm>({
    defaultValues: {
      conditions: basicInfoData ? basicInfoData.conditions : [],
    },
  });

  const { control } = methods;

  const onSubmitRenewVisaBasicInfo = async (data: TRenewVisaBasicInfoForm) => {
    if (!renewVisaDocumentId || !documentStepId) return;
    try {
      if (basicInfoData) {
        const rs = await updateRenewVisaBasicInformationDocumentApi(renewVisaDocumentId, documentStepId, { conditions: data.conditions });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      } else {
        const rs = await createRenewVisaBasicInformationDocumentApi(renewVisaDocumentId, documentStepId, { conditions: data.conditions });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }

      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_DETAIL] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_BASIC_INFORMATION] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_UPLOAD_FILE] });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Form methods={methods} onSubmit={onSubmitRenewVisaBasicInfo} schema={renewVisaBasicInfoSchema}>
      <StepCard
        title={`ステップ${stepOrder}`}
        subTitle={stepName}
        buttons={
          <Button variant="primary" size="sm" type="submit" disabled={readonly}>
            条件を更新
          </Button>
        }
      >
        <Typography component="p" variant="body14Bold" mb="16px">
          基本情報を確認すると今回必要な添付書類がstep2 届出作成に表示されます。以下に
          <Typography color={text.accent} variant="body14Bold">
            該当する場合のみ
          </Typography>
          チェックを入れてください。
        </Typography>
        <Stack gap="8px">
          <FormField control={control} name="conditions">
            <CheckboxGroup options={renewVisaConditionOptions} disabled={readonly} />
          </FormField>
        </Stack>
      </StepCard>
    </Form>
  );
};
