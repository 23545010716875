import { IconClose } from "@/assets/icons";
import { Button } from "@/components/elements/Button";
import { IconBox } from "@/components/elements/IconBox";
import { background } from "@/theme/colors";
import { FCC } from "@/types/common";
import { Box, Modal as MuiModal, Stack, styled, Typography } from "@mui/material";
import { ReactNode } from "react";

export type TModalProps = {
  isOpen: boolean;
  title?: ReactNode;
  labelClose?: string;
  labelOk?: string;
  enableFooter?: boolean;
  enableCloseIcon?: boolean;
  hideBackdrop?: boolean;
  width?: number;
  keepMounted?: boolean;
  preventCloseBackdrop?: boolean;
  disabledOk?: boolean;
  onOk?: () => void;
  onClose?: () => void;
};

export const Modal: FCC<TModalProps> = ({
  children,
  isOpen,
  title,
  labelClose,
  labelOk,
  enableFooter = true,
  enableCloseIcon = true,
  hideBackdrop = false,
  keepMounted = false,
  width,
  disabledOk = false,
  preventCloseBackdrop = false,
  onOk,
  onClose,
}) => {
  return (
    <MuiModal
      open={isOpen}
      hideBackdrop={hideBackdrop}
      onClose={!preventCloseBackdrop ? onClose : undefined}
      sx={{ position: hideBackdrop ? "relative" : "fixed" }}
      keepMounted={keepMounted}
    >
      <ModalContainer width={width}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {!title && <span></span>}
          {typeof title === "string" ? <Typography variant="head20Bold">{title}</Typography> : title}
          {enableCloseIcon && (
            <IconBox onClick={onClose} sx={{ p: 0 }}>
              <IconClose fontSize={24} />
            </IconBox>
          )}
        </Stack>
        {children}
        {enableFooter && (
          <Stack flexDirection="row" justifyContent="center" gap="24px">
            <Button variant="outline" onClick={onClose}>
              {labelClose || "いいえ"}
            </Button>
            <Button onClick={onOk} disabled={disabledOk}>
              {labelOk || "はい"}
            </Button>
          </Stack>
        )}
      </ModalContainer>
    </MuiModal>
  );
};

const ModalContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 417px;
  background-color: ${background.white};
  box-shadow: 0px 8px 24px 0px #00000026;
  border-radius: 8px;
  padding: 24px;
  max-width: 760px;
  max-height: 90vh;
`;
