import { TCheckUpdateEmployeeReqDto, TCheckUpdateEmployeeResDto } from "@/api/services/main/employee/dtos/check-update-employee.dto";
import { TCreateOrUpdateEmployeeResDto, TCreateOrUpdateEmployeeReqDto } from "@/api/services/main/employee/dtos/create-or-update-employee.dto";
import { TDeleteEmployeeResDto } from "@/api/services/main/employee/dtos/delete-employee.dto";
import { TGetEmployeeDetailListReqDto, TGetEmployeeDetailListResDto } from "@/api/services/main/employee/dtos/get-employee-detail-list.dto";
import { TGetEmployeeListReqDto, TGetEmployeeListResDto } from "@/api/services/main/employee/dtos/get-employee-list.dto";
import { TGetEmployeeResDto } from "@/api/services/main/employee/dtos/get-employee.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { usePagination } from "@/hooks/usePagination";
import { clientApi } from "@/utils/api";
import { getFullAddress } from "@/utils/string";
import { createUrlWithParams } from "@/utils/url";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const createEmployeeApi = async (payload: TCreateOrUpdateEmployeeReqDto): Promise<TCreateOrUpdateEmployeeResDto> => {
  const rs = await clientApi.post<TCreateOrUpdateEmployeeResDto>("/employees", payload);
  return rs.data;
};

export const uploadEmployeeCsvFileApi = async (formData: FormData): Promise<boolean> => {
  const rs = await clientApi.post("/employees/import-csv", formData);
  return rs.data;
};

export const updateEmployeeInfoApi = async (employeeId: string, payload: TCreateOrUpdateEmployeeReqDto): Promise<TCreateOrUpdateEmployeeResDto> => {
  const rs = await clientApi.put<TCreateOrUpdateEmployeeResDto>(`/employees/${employeeId}`, payload);
  return rs.data;
};

export const deleteEmployeeInfoApi = async (employeeId: string): Promise<TDeleteEmployeeResDto> => {
  const rs = await clientApi.delete<TDeleteEmployeeResDto>(`/employees/${employeeId}`);
  return rs.data;
};

export const fetchEmployeeInfoApi = async (id: string | number): Promise<TGetEmployeeResDto> => {
  const rs = await clientApi.get<TGetEmployeeResDto>(`/employees/${id}`);
  return rs.data;
};

export const useEmployeeInfo = (employeeId?: number) => {
  const { id } = useParams();

  const fetchId = employeeId ?? id;
  return useQuery({
    queryKey: [QUERY_KEYS.EMPLOYEE.FETCH_DETAIL, fetchId],
    queryFn: () => (fetchId ? fetchEmployeeInfoApi(fetchId) : undefined),
    enabled: Boolean(fetchId),
  });
};

export const fetchEmployeeListApi = async (request: TGetEmployeeListReqDto): Promise<TGetEmployeeListResDto> => {
  const url = createUrlWithParams("/employees", request);
  const { data } = await clientApi.get<TGetEmployeeListResDto>(url);

  return data;
};

type TUseEmployeeListProps = {
  isFetchedAll?: boolean;
  enabled?: boolean;
  filter?: TGetEmployeeListReqDto;
};

export const useEmployeeList = ({ isFetchedAll, enabled = true, filter }: TUseEmployeeListProps = {}) => {
  const pagReq = usePagination();

  const params = isFetchedAll ? {} : filter ?? pagReq;
  return useQuery({
    queryKey: [QUERY_KEYS.EMPLOYEE.FETCH_LIST, params, filter],
    queryFn: async () => {
      const rs = await fetchEmployeeListApi(params);
      const { data: employees } = rs;
      employees.map((employee) => {
        employee.fullAddress = getFullAddress({ province: employee.province, municipality: employee.municipality, address: employee.address });
        return employee;
      });

      return rs;
    },
    enabled: enabled,
  });
};

export const fetchEmployeeDetailListApi = async (request: TGetEmployeeDetailListReqDto): Promise<TGetEmployeeDetailListResDto> => {
  const url = createUrlWithParams("/employees/details", request);
  const { data } = await clientApi.get<TGetEmployeeDetailListResDto>(url);

  return data;
};

export const checkUpdateEmployeeInfoApi = async (employeeId: string, payload: TCheckUpdateEmployeeReqDto): Promise<TCheckUpdateEmployeeResDto> => {
  const rs = await clientApi.post<TCheckUpdateEmployeeResDto>(`/employees/${employeeId}/check-update-employee`, payload);
  return rs.data;
};
