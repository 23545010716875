import { TCreateOrUpdateRenewVisaCompanyApplicationDocumentReqDto } from "@/api/services/main/renew-visa/dtos/create-or-update-renew-visa-company-application-document.dto";
import { TCompanyApplicationForm } from "@/features/renew-visa/types/companyApplicationForm";
import { toNumber } from "@/utils/number";

export const convertCompanyApplicationFormDataToReqDto = (
  data: TCompanyApplicationForm,
  isDraft: boolean,
): TCreateOrUpdateRenewVisaCompanyApplicationDocumentReqDto => {
  return {
    address: data.address ? data.address : null,
    affiliationWithOrganizedCrimeDetail: data.affiliationWithOrganizedCrimeDetail ? data.affiliationWithOrganizedCrimeDetail : null,
    agriculturalWorkInDesignatedStrategicZoneDetail: data.agriculturalWorkInDesignatedStrategicZoneDetail
      ? data.agriculturalWorkInDesignatedStrategicZoneDetail
      : null,
    companyName: data.companyName ? data.companyName : null,
    annualRevenue: data.annualRevenue * 10000,
    branchAddress: data.branchAddress,
    branchName: data.branchName ? data.branchName : null,
    branchProvince: data.branchProvince,
    branchMunicipality: data.branchMunicipality,
    branchTelNumber: data.branchTelNumber ? data.branchTelNumber : null,
    businessInsuranceNumber: data.businessInsuranceNumber ? data.businessInsuranceNumber : null,
    capital: toNumber(data.capital),
    municipality: data.municipality ? data.municipality : null,
    companyHasComplianceWithHealthAndWelfarePensionInsurance: data.companyHasComplianceWithHealthAndWelfarePensionInsurance
      ? data.companyHasComplianceWithHealthAndWelfarePensionInsurance
      : null,
    companyIdentificationNumber: data.companyIdentificationNumber ? data.companyIdentificationNumber : null,
    complianceWithWorkersCompensationAndEmploymentInsurance: data.complianceWithWorkersCompensationAndEmploymentInsurance
      ? data.complianceWithWorkersCompensationAndEmploymentInsurance
      : null,
    conditionsApplyForStaffingTargetsDetail: data.conditionsApplyForStaffingTargetsDetail ? data.conditionsApplyForStaffingTargetsDetail : null,
    conditionsApplyForSupportPlanDelegationDetail: data.conditionsApplyForSupportPlanDelegationDetail
      ? data.conditionsApplyForSupportPlanDelegationDetail
      : null,
    conditionsApplyForSupportPlanDelegationOtherDetail: data.conditionsApplyForSupportPlanDelegationOtherDetail
      ? data.conditionsApplyForSupportPlanDelegationOtherDetail
      : null,
    criminalPenaltiesForLawViolationsByOrgOrPersonnelDetail: data.criminalPenaltiesForLawViolationsByOrgOrPersonnelDetail
      ? data.criminalPenaltiesForLawViolationsByOrgOrPersonnelDetail
      : null,
    employeeCount: data.employeeCount,
    employmentInsuranceNumber: data.employmentInsuranceNumber ? data.employmentInsuranceNumber : "",
    formerExecutiveOfOrgWithCanceledTechnicalTrainingCertificationDetail: data.formerExecutiveOfOrgWithCanceledTechnicalTrainingCertificationDetail
      ? data.formerExecutiveOfOrgWithCanceledTechnicalTrainingCertificationDetail
      : null,
    hostCompanyComplianceWithSectionsDetail: data.hostCompanyComplianceWithSectionsDetail ? data.hostCompanyComplianceWithSectionsDetail : null,
    awarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreementsDetail:
      data.awarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreementsDetail
        ? data.awarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreementsDetail
        : null,
    involuntaryDismissalOfWorkersInSimilarRolesDetail: data.involuntaryDismissalOfWorkersInSimilarRolesDetail
      ? data.involuntaryDismissalOfWorkersInSimilarRolesDetail
      : null,
    legalRepresentativeIsEligibilityDetail: data.legalRepresentativeIsEligibilityDetail ? data.legalRepresentativeIsEligibilityDetail : null,
    mainIndustryId: data.mainIndustryId ? toNumber(data.mainIndustryId) : null,
    mainIndustryOther: data.mainIndustryOther ? data.mainIndustryOther : null,
    majorityCapitalInvestmentByIndustryRelatedEntityDetail: data.majorityCapitalInvestmentByIndustryRelatedEntityDetail
      ? data.majorityCapitalInvestmentByIndustryRelatedEntityDetail
      : null,
    mentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnelDetail: data.mentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnelDetail
      ? data.mentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnelDetail
      : null,
    missingForeignWorkersDueToOrgIsFaultDetail: data.missingForeignWorkersDueToOrgIsFaultDetail
      ? data.missingForeignWorkersDueToOrgIsFaultDetail
      : null,
    neglectOfSupportPlanOccurredInPastFiveYearsDetail: data.neglectOfSupportPlanOccurredInPastFiveYearsDetail
      ? data.neglectOfSupportPlanOccurredInPastFiveYearsDetail
      : null,
    organizedCrimeControlOverBusinessDetail: data.organizedCrimeControlOverBusinessDetail ? data.organizedCrimeControlOverBusinessDetail : null,
    penaltyClausesForBreachOfEmploymentContractDetail: data.penaltyClausesForBreachOfEmploymentContractDetail
      ? data.penaltyClausesForBreachOfEmploymentContractDetail
      : null,
    province: data.province ? data.province : null,
    relevantIndustryWorkAtDispatchSiteDetail: data.relevantIndustryWorkAtDispatchSiteDetail ? data.relevantIndustryWorkAtDispatchSiteDetail : null,
    representativeName: data.representativeName ? data.representativeName : null,
    substantialInvolvementByIndustryRelatedEntityDetail: data.substantialInvolvementByIndustryRelatedEntityDetail
      ? data.substantialInvolvementByIndustryRelatedEntityDetail
      : null,
    supportPersonnel: data.supportPersonnel ? data.supportPersonnel : null,
    supportPersonnelRole: data.supportPersonnelRole ? data.supportPersonnelRole : null,
    supportSupervisor: data.supportSupervisor ? data.supportSupervisor : null,
    supportSupervisorRole: data.supportSupervisorRole ? data.supportSupervisorRole : null,
    telNumber: data.telNumber ? data.telNumber : null,
    unfairActsRelatedToImmigrationLaborLawsDetail: data.unfairActsRelatedToImmigrationLaborLawsDetail
      ? data.unfairActsRelatedToImmigrationLaborLawsDetail
      : null,
    unrehabilitatedBankruptcyOfOrgOrPersonnelDetail: data.unrehabilitatedBankruptcyOfOrgOrPersonnelDetail
      ? data.unrehabilitatedBankruptcyOfOrgOrPersonnelDetail
      : null,
    whetherCancellationOfTrainingCertification: data.whetherCancellationOfTrainingCertification
      ? data.whetherCancellationOfTrainingCertification
      : null,
    cancellationOfTrainingCertificationDetail: data.cancellationOfTrainingCertificationDetail ? data.cancellationOfTrainingCertificationDetail : null,
    violationsOfLaborSocialInsuranceAndTaxLawsDetail: data.violationsOfLaborSocialInsuranceAndTaxLawsDetail
      ? data.violationsOfLaborSocialInsuranceAndTaxLawsDetail
      : null,
    whetherAdequateSystemForContinuedEmploymentContractFulfillment: data.whetherAdequateSystemForContinuedEmploymentContractFulfillment
      ? data.whetherAdequateSystemForContinuedEmploymentContractFulfillment
      : null,
    whetherAffiliationWithOrganizedCrime: data.whetherAffiliationWithOrganizedCrime ? data.whetherAffiliationWithOrganizedCrime : null,
    whetherTransportationToPortOrAirportIsProvided: data.whetherTransportationToPortOrAirportIsProvided
      ? data.whetherTransportationToPortOrAirportIsProvided
      : null,
    whetherAppointmentOfSupportPersonnelFromStaff: data.whetherAppointmentOfSupportPersonnelFromStaff
      ? data.whetherAppointmentOfSupportPersonnelFromStaff
      : null,
    whetherAppointmentOfSupportSupervisorFromStaff: data.whetherAppointmentOfSupportSupervisorFromStaff
      ? data.whetherAppointmentOfSupportSupervisorFromStaff
      : null,
    whetherAccompanimentForProceduresIsProvidedIfNeeded: data.whetherAccompanimentForProceduresIsProvidedIfNeeded
      ? data.whetherAccompanimentForProceduresIsProvidedIfNeeded
      : null,
    whetherAwarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreements:
      data.whetherAwarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreements
        ? data.whetherAwarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreements
        : null,
    whetherComplianceWithIndustrySpecificStandardsForContractFulfillment: data.whetherComplianceWithIndustrySpecificStandardsForContractFulfillment
      ? data.whetherComplianceWithIndustrySpecificStandardsForContractFulfillment
      : null,
    whetherComplianceWithIndustryStandardsIsEnsured: data.whetherComplianceWithIndustryStandardsIsEnsured
      ? data.whetherComplianceWithIndustryStandardsIsEnsured
      : null,
    whetherSupportPlanMeetsIndustryStandards: data.whetherSupportPlanMeetsIndustryStandards ? data.whetherSupportPlanMeetsIndustryStandards : null,
    whetherConditionsApplyForStaffingTargets: data.whetherConditionsApplyForStaffingTargets ? data.whetherConditionsApplyForStaffingTargets : null,
    whetherConditionsApplyForSupportPlanDelegation: data.whetherConditionsApplyForSupportPlanDelegation
      ? data.whetherConditionsApplyForSupportPlanDelegation
      : null,
    whetherCriminalPenaltiesForLawViolationsByOrgOrPersonnel: data.whetherCriminalPenaltiesForLawViolationsByOrgOrPersonnel
      ? data.whetherCriminalPenaltiesForLawViolationsByOrgOrPersonnel
      : null,
    whetherFormerExecutiveOfOrgWithCanceledTechnicalTrainingCertification: data.whetherFormerExecutiveOfOrgWithCanceledTechnicalTrainingCertification
      ? data.whetherFormerExecutiveOfOrgWithCanceledTechnicalTrainingCertification
      : null,
    whetherHostCompanyComplianceWithSections: data.whetherHostCompanyComplianceWithSections ? data.whetherHostCompanyComplianceWithSections : null,
    whetherSpecificIndustryRequirementsAreIncludedInSupportPlan: data.whetherSpecificIndustryRequirementsAreIncludedInSupportPlan
      ? data.whetherSpecificIndustryRequirementsAreIncludedInSupportPlan
      : null,
    whetherInvoluntaryDismissalOfWorkersInSimilarRoles: data.whetherInvoluntaryDismissalOfWorkersInSimilarRoles
      ? data.whetherInvoluntaryDismissalOfWorkersInSimilarRoles
      : null,
    whetherLegalRepresentativeIsEligibility: data.whetherLegalRepresentativeIsEligibility ? data.whetherLegalRepresentativeIsEligibility : null,
    whetherMentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnel: data.whetherMentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnel
      ? data.whetherMentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnel
      : null,
    whetherMissingForeignWorkersDueToOrgIsFault: data.whetherMissingForeignWorkersDueToOrgIsFault
      ? data.whetherMissingForeignWorkersDueToOrgIsFault
      : null,
    whetherNeglectOfSupportPlanOccurredInPastFiveYears: data.whetherNeglectOfSupportPlanOccurredInPastFiveYears
      ? data.whetherNeglectOfSupportPlanOccurredInPastFiveYears
      : null,
    whetherSupportPersonnelAreNeutralInImplementation: data.whetherSupportPersonnelAreNeutralInImplementation
      ? data.whetherSupportPersonnelAreNeutralInImplementation
      : null,
    whetherNoCostBurdenOnWorkerForSpecifiedSkilledWorkerSupport: data.whetherNoCostBurdenOnWorkerForSpecifiedSkilledWorkerSupport
      ? data.whetherNoCostBurdenOnWorkerForSpecifiedSkilledWorkerSupport
      : null,
    whetherOrganizedCrimeControlOverBusiness: data.whetherOrganizedCrimeControlOverBusiness ? data.whetherOrganizedCrimeControlOverBusiness : null,
    whetherPaymentOfWagesViaBankOrVerifiableMethodWithReporting: data.whetherPaymentOfWagesViaBankOrVerifiableMethodWithReporting
      ? data.whetherPaymentOfWagesViaBankOrVerifiableMethodWithReporting
      : null,
    whetherPenaltyClausesForBreachOfEmploymentContract: data.whetherPenaltyClausesForBreachOfEmploymentContract
      ? data.whetherPenaltyClausesForBreachOfEmploymentContract
      : null,
    whetherInformationOnLivingInJapanIsProvidedInUnderstandableLanguage: data.whetherInformationOnLivingInJapanIsProvidedInUnderstandableLanguage
      ? data.whetherInformationOnLivingInJapanIsProvidedInUnderstandableLanguage
      : null,
    whetherOpportunitiesForJapaneseLanguageLearningAreProvided: data.whetherOpportunitiesForJapaneseLanguageLearningAreProvided
      ? data.whetherOpportunitiesForJapaneseLanguageLearningAreProvided
      : null,
    whetherRegularMeetingsWithSupervisorsAreConducted: data.whetherRegularMeetingsWithSupervisorsAreConducted
      ? data.whetherRegularMeetingsWithSupervisorsAreConducted
      : null,
    whetherRegularMeetingSystemWithSupervisorsIsInPlace: data.whetherRegularMeetingSystemWithSupervisorsIsInPlace
      ? data.whetherRegularMeetingSystemWithSupervisorsIsInPlace
      : null,
    whetherRetentionOfActivityDocuments: data.whetherRetentionOfActivityDocuments ? data.whetherRetentionOfActivityDocuments : null,
    whetherRetentionOfSupportDocumentation: data.whetherRetentionOfSupportDocumentation ? data.whetherRetentionOfSupportDocumentation : null,
    whetherSupportForOpeningBankAccountsAndContractsIsProvided: data.whetherSupportForOpeningBankAccountsAndContractsIsProvided
      ? data.whetherSupportForOpeningBankAccountsAndContractsIsProvided
      : null,
    whetherJobChangeSupportIsProvidedForContractTermination: data.whetherJobChangeSupportIsProvidedForContractTermination
      ? data.whetherJobChangeSupportIsProvidedForContractTermination
      : null,
    whetherSupportForPromotingExchangeBetweenForeignersAndJapaneseIsProvided:
      data.whetherSupportForPromotingExchangeBetweenForeignersAndJapaneseIsProvided
        ? data.whetherSupportForPromotingExchangeBetweenForeignersAndJapaneseIsProvided
        : null,
    whetherSupportForSecuringAppropriateHousingIsProvided: data.whetherSupportForSecuringAppropriateHousingIsProvided
      ? data.whetherSupportForSecuringAppropriateHousingIsProvided
      : null,
    whetherSupportSystemInUnderstandingLanguageIsAvailable: data.whetherSupportSystemInUnderstandingLanguageIsAvailable
      ? data.whetherSupportSystemInUnderstandingLanguageIsAvailable
      : null,
    whetherSupportPlanIsCreatedInUnderstandableLanguages: data.whetherSupportPlanIsCreatedInUnderstandableLanguages
      ? data.whetherSupportPlanIsCreatedInUnderstandableLanguages
      : null,
    whetherSupportIsAppropriateForForeignersProperStay: data.whetherSupportIsAppropriateForForeignersProperStay
      ? data.whetherSupportIsAppropriateForForeignersProperStay
      : null,
    whetherThereHaveBeenViolationsOfLaborSocialInsuranceAndTaxLaws: data.whetherThereHaveBeenViolationsOfLaborSocialInsuranceAndTaxLaws
      ? data.whetherThereHaveBeenViolationsOfLaborSocialInsuranceAndTaxLaws
      : null,
    whetherTimelyResponseToConsultationsOrComplaintsIsEnsured: data.whetherTimelyResponseToConsultationsOrComplaintsIsEnsured
      ? data.whetherTimelyResponseToConsultationsOrComplaintsIsEnsured
      : null,
    whetherUnfairActsRelatedToImmigrationLaborLaws: data.whetherUnfairActsRelatedToImmigrationLaborLaws
      ? data.whetherUnfairActsRelatedToImmigrationLaborLaws
      : null,
    whetherUnrehabilitatedBankruptcyOfOrgOrPersonnel: data.whetherUnrehabilitatedBankruptcyOfOrgOrPersonnel
      ? data.whetherUnrehabilitatedBankruptcyOfOrgOrPersonnel
      : null,
    whetherWorkerIsCompensationInsuranceEnrollment: data.whetherWorkerIsCompensationInsuranceEnrollment
      ? data.whetherWorkerIsCompensationInsuranceEnrollment
      : null,
    workerIsCompensationInsuranceEnrollmentDetail: data.workerIsCompensationInsuranceEnrollmentDetail
      ? data.workerIsCompensationInsuranceEnrollmentDetail
      : null,
    isDraft,
  };
};
