import { checkUpdateEmployeeInfoApi, updateEmployeeInfoApi } from "@/api/services/main/employee";
import { ConfirmSaveModal } from "@/components/commons/ConfirmSaveModal";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { TopPage } from "@/components/elements/TopPage";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { DeleteEmployeeModal } from "@/features/employee/components/DeleteEmployeeModal";
import { EmployeeForm } from "@/features/employee/components/employee-forms";
import { useGetEmployeeSchema } from "@/features/employee/hook/useGetEmployeeSchema";
import { TEmployeeForm } from "@/features/employee/types/employeeForm";
import { convertEmployeeFormDataToReqDto } from "@/features/employee/utils/convertFormDataToReqDto";
import { useDialog } from "@/hooks/useDialog";
import { useVisible } from "@/hooks/useVisible";
import { AppRoutes } from "@/routes/config";
import { background } from "@/theme/colors";
import { CHECK_UPDATE_EMPLOYEE_RESULT, EMPLOYMENT_STATUS } from "@/types/enum";
import { setErrorForm, showError } from "@/utils/error";
import { pick } from "@/utils/object";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, styled, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

type TEmployeeEditPageProps = {
  previousValues: TEmployeeForm | undefined;
};

export const EmployeeEditPage: FC<TEmployeeEditPageProps> = ({ previousValues }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [confirmMessage, setConfirmMessage] = useState<string>("");
  const confirmSave = useDialog();
  const { id } = useParams();

  const deleteModal = useVisible();

  const { employeeSchema } = useGetEmployeeSchema();

  const methods = useForm<TEmployeeForm>({
    defaultValues: previousValues ? previousValues : undefined,
    resolver: yupResolver(employeeSchema),
    mode: "all",
    reValidateMode: "onChange",
  });

  const onSubmit = async (data: TEmployeeForm) => {
    if (!id) return;
    try {
      let confirm = true;
      const payload = convertEmployeeFormDataToReqDto({ ...data, hasRetiredBefore: undefined });
      const checkResult = await checkUpdateEmployeeInfoApi(id, {
        ...pick(payload, "employmentStatus", "retirementStatus", "residenceCode", "residenceExpiryDate", "residenceStatusId"),
      });
      if (checkResult.data === CHECK_UPDATE_EMPLOYEE_RESULT.CAN_NOT_UPDATE) {
        showError(new Error("期間更新申請中のため編集不可。"));
        return;
      }
      if (checkResult.data === CHECK_UPDATE_EMPLOYEE_RESULT.CAN_UPDATE_AND_UPDATE_RENEW_VISA) {
        setConfirmMessage("更新を実行すると、更新した内容が在留期間更新に反映されます。本当に更新してもよろしいですか？");
        confirm = await confirmSave.show();
      }
      if (checkResult.data === CHECK_UPDATE_EMPLOYEE_RESULT.CAN_UPDATE_AND_DELETE_RENEW_VISA) {
        setConfirmMessage("更新を実行すると、在留期間更新ドキュメントが削除されます。本当に更新してもよろしいですか？");
        confirm = await confirmSave.show();
      }
      if (!confirm) return;
      const rs = await updateEmployeeInfoApi(id, payload);
      navigate(AppRoutes.employeeList);
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EMPLOYEE.FETCH_LIST] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EMPLOYEE.FETCH_DETAIL, rs.data.id] });
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <>
      <Form methods={methods} onSubmit={onSubmit} schema={employeeSchema}>
        <TopPageWrapper>
          <TopPage
            title="特定技能外国人"
            backUrl={AppRoutes.employeeList}
            rightContent={
              <>
                <Button type="submit" variant="primary" size="lg">
                  保存して閉じる
                </Button>
                {previousValues?.employmentStatus === EMPLOYMENT_STATUS.OFFERED && (
                  <Button variant="primary" size="lg" onClick={() => deleteModal.open()}>
                    削除
                  </Button>
                )}
              </>
            }
          />
        </TopPageWrapper>
        <EmployeeForm />
      </Form>

      <DeleteEmployeeModal isOpen={deleteModal.visible} onClose={() => deleteModal.close()} />
      <ConfirmSaveModal open={confirmSave.open} onOk={confirmSave.confirm} onClose={confirmSave.cancel}>
        <Typography>{confirmMessage}</Typography>
      </ConfirmSaveModal>
    </>
  );
};

const TopPageWrapper = styled(Box)`
  position: sticky;
  top: 80px;
  z-index: 100;
  background-color: ${background.primary};
  height: 156px;
  padding-top: 24px;
  transform: translateY(-24px);
`;
