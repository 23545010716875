import {
  INVALID_BEFORE_AGE,
  INVALID_BOOLEAN,
  INVALID_DATE,
  INVALID_DATE_MUST_AFTER,
  INVALID_DATE_MUST_BEFORE,
  INVALID_DATE_MUST_PAST,
  INVALID_DATE_MUST_PAST_OR_TODAY,
  INVALID_EMAIL,
  INVALID_LIMIT,
  INVALID_NOT_EMPTY,
  INVALID_NUMBER,
  INVALID_NUMBER_INTEGER,
  INVALID_NUMBER_LESS_THAN,
  INVALID_NUMBER_MAX,
  INVALID_NUMBER_MIN,
  INVALID_NUMBER_MORE_THAN,
  INVALID_NUMBER_NEGATIVE,
  INVALID_NUMBER_POSITIVE,
  INVALID_PASSWORD_STRONG,
  INVALID_SELECT_NOT_EMPTY,
  INVALID_STRING,
  INVALID_STRING_FULL_WIDTH,
  INVALID_STRING_HALF_WIDTH,
  INVALID_STRING_KANJI,
  INVALID_STRING_KATAKANA,
  INVALID_STRING_LATIN,
  INVALID_STRING_LENGTH,
  INVALID_STRING_MATCHES,
  INVALID_STRING_MAX,
  INVALID_STRING_MIN,
  INVALID_STRING_UPPERCASE_AND_NUMBER,
  INVALID_TEL_NUMBER,
  INVALID_URL,
  INVALID_VALUE,
  INVALID_ZIP_CODE,
} from "@/constants/invalids";
import { PATTERN } from "@/constants/pattern";
import { TExtendFile } from "@/types/file";
import { zipCodeApi } from "@/utils/api";
import { dayjs, today } from "@/utils/dayjs";
import { convertCommasToNumber } from "@/utils/input";
import { replaceStr } from "@/utils/string";
import { Dayjs, isDayjs } from "dayjs";
import * as yup from "yup";

declare module "yup" {
  interface MixedSchema {
    isDayjs(message?: string): MixedSchema<Dayjs>;
    isAfterDateOfField(refKey: string, message: { startLabel: string; endLabel: string } | string): MixedSchema<Dayjs>;
    isBeforeDateOfField(refKey: string, message: { startLabel: string; endLabel: string } | string): MixedSchema<Dayjs>;
    isBeforeAge(age: number, message?: string): MixedSchema<Dayjs>;
    isPastOrToday(message?: string): MixedSchema<Dayjs>;
    isPast(message?: string): MixedSchema<Dayjs>;
    isExtendFile(message?: string): MixedSchema<TExtendFile>;
    isCsvFile(message?: string): MixedSchema<File>;
    isSelection(): this;
  }
  interface StringSchema {
    strongPassword(message?: string): this;
    limit(length: number, message?: string): this;
    lettersOnly(message?: string): this;
    katakanaOnly(message?: string): this;
    latinsOnly(message?: string): this;
    latinAndNumber(message?: string): this;
    halfWidthOnly(message?: string): this;
    fullWidthOnly(message?: string): this;
    notKanji(message?: string): this;
    notHalfWidthCharacter(message?: string): this;
    uppercaseAlphanumericCharactersOnly(message?: string): this;
    numbersOnly(message?: string): this;
    emptyToNull(): this;
    isSelection(): this;
    email(): this;
    zipCode(message?: string): this;
    isTelNumber(message?: string): this;
    autoTransformToHalfWidth(): this;
    autoTransformToFullWidth(): this;
  }
  interface NumberSchema {
    limit(length: number, message?: string): this;
    emptyToNull(): this;
    commas(): this;
    isSelection(): this;
    autoTransformToHalfWidth(): this;
  }
}

yup.setLocale({
  mixed: {
    notType: (ctx) => {
      const { type, path } = ctx;
      switch (type) {
        case "number":
          return replaceStr(INVALID_NUMBER, { path });
        case "string":
          return replaceStr(INVALID_STRING, { path });
        case "boolean":
          return replaceStr(INVALID_BOOLEAN, { path });
        default:
          break;
      }
      return replaceStr(INVALID_VALUE, { path });
    },
    required: ({ path, spec }) => {
      const isSelection = spec?.meta?.type === "selection";
      if (isSelection) return replaceStr(INVALID_SELECT_NOT_EMPTY, { path });
      return replaceStr(INVALID_NOT_EMPTY, { path });
    },
  },
  string: {
    email: ({ path }) => replaceStr(INVALID_EMAIL, { path }),
    length: ({ path, length }) => replaceStr(INVALID_STRING_LENGTH, { path, length }),
    max: ({ path, max }) => replaceStr(INVALID_STRING_MAX, { path, max }),
    min: ({ path, min }) => replaceStr(INVALID_STRING_MIN, { path, min }),
    url: ({ path }) => replaceStr(INVALID_URL, { path }),
    matches: ({ path }) => replaceStr(INVALID_STRING_MATCHES, { path }),
  },
  number: {
    integer: ({ path }) => replaceStr(INVALID_NUMBER_INTEGER, { path }),
    positive: ({ path }) => replaceStr(INVALID_NUMBER_POSITIVE, { path }),
    negative: ({ path }) => replaceStr(INVALID_NUMBER_NEGATIVE, { path }),
    min: ({ path, min }) => replaceStr(INVALID_NUMBER_MIN, { path, min }),
    max: ({ path, max }) => replaceStr(INVALID_NUMBER_MAX, { path, max }),
    lessThan: ({ path, less }) => replaceStr(INVALID_NUMBER_LESS_THAN, { path, less }),
    moreThan: ({ path, more }) => replaceStr(INVALID_NUMBER_MORE_THAN, { path, more }),
  },
});

yup.addMethod(yup.mixed, "isDayjs", function (message) {
  return this.test("isDayjs", message || INVALID_DATE, function (value, ctx) {
    if (!value) return true;
    if (isDayjs(value)) return true;
    return ctx.createError({ path: ctx.path, message: ({ label }) => replaceStr(INVALID_DATE, { path: label }) });
  });
});

yup.addMethod(yup.mixed, "isBeforeAge", function (age: number, message) {
  return this.test("isBeforeAge", message || INVALID_DATE, function (value, ctx) {
    if (!value) return true;
    if (isDayjs(value) && dayjs(value).isBefore(dayjs().subtract(age, "year"))) return true;
    return ctx.createError({ path: ctx.path, message: ({ label }) => replaceStr(INVALID_BEFORE_AGE, { path: label, age }) });
  });
});

yup.addMethod(yup.mixed, "isAfterDateOfField", function (refKey, message) {
  let errorMsg = INVALID_VALUE;
  if (typeof message === "string") {
    errorMsg = message;
  }
  if (typeof message === "object" && "startLabel" in message && "endLabel" in message) {
    const start = message.startLabel;
    const end = message.endLabel;
    errorMsg = replaceStr(INVALID_DATE_MUST_AFTER, { start, end });
  }
  return this.test("isAfterDateOfField", errorMsg, function (value, context) {
    const refValue = context.parent[refKey];
    if (!isDayjs(value) || !isDayjs(refValue)) return true;
    return value.isAfter(refValue);
  });
});

yup.addMethod(yup.mixed, "isBeforeDateOfField", function (refKey, message) {
  let errorMsg = INVALID_VALUE;
  if (typeof message === "string") {
    errorMsg = message;
  }
  if (typeof message === "object" && "startLabel" in message && "endLabel" in message) {
    const start = message.startLabel;
    const end = message.endLabel;
    errorMsg = replaceStr(INVALID_DATE_MUST_BEFORE, { start, end });
  }
  return this.test("isBeforeDateOfField", errorMsg, function (value, context) {
    const refValue = context.parent[refKey];
    if (!isDayjs(value) || !isDayjs(refValue)) return true;
    return value.isBefore(refValue);
  });
});

yup.addMethod(yup.mixed, "isPastOrToday", function (message) {
  return this.test("isPastOrToday", message || INVALID_DATE_MUST_PAST_OR_TODAY, function (value, ctx) {
    if (!value) return true;
    if (!isDayjs(value)) return true;
    if (value.isAfter(today().add(1, "day"))) {
      return ctx.createError({ path: ctx.path, message: ({ label }) => replaceStr(message || INVALID_DATE_MUST_PAST_OR_TODAY, { path: label }) });
    }
    return true;
  });
});

yup.addMethod(yup.mixed, "isPast", function () {
  return this.test("isPast", INVALID_DATE_MUST_PAST, function (value, ctx) {
    if (!value) return true;
    if (!isDayjs(value)) return true;
    if (value.isBefore(today())) {
      return true;
    }
    return ctx.createError({ path: ctx.path, message: ({ label }) => replaceStr(INVALID_DATE_MUST_PAST, { path: label }) });
  });
});

yup.addMethod(yup.mixed, "isExtendFile", function (message) {
  return this.test("isExtendFile", message || INVALID_VALUE, function (value, ctx) {
    if (!value) return true;
    if (typeof value === "object" && "status" in value && "id" in value) return true;
    return ctx.createError({ path: ctx.path, message: ({ label }) => replaceStr(INVALID_VALUE, { path: label }) });
  });
});

yup.addMethod(yup.mixed, "isCsvFile", function (message) {
  return this.test("isCsvFile", message || INVALID_VALUE, function (value, ctx) {
    if (!value) return true;
    if (value instanceof File) {
      const allowedTypes = ["text/csv"];

      if (!allowedTypes.includes(value.type)) {
        return ctx.createError({ path: ctx.path, message: INVALID_VALUE });
      }

      return true;
    }
    return ctx.createError({ path: ctx.path, message: INVALID_VALUE });
  });
});

yup.addMethod(yup.mixed, "isSelection", function () {
  return this.meta({ type: "selection" });
});

// Add custom method to Yup for strong password validation
yup.addMethod(yup.string, "strongPassword", function (message) {
  const rules = {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSpecial: 1,
  };
  return this.test("strongPassword", message || INVALID_PASSWORD_STRONG, function (value) {
    const { path, createError } = this;

    if (!value) {
      return createError({ path, message: ({ label }) => replaceStr(INVALID_NOT_EMPTY, { path: label }) });
    }

    const { minLength, minLowercase, minUppercase, minNumbers, minSpecial } = rules;

    const lowercaseCount = (value.match(/[a-z]/g) || []).length;
    const uppercaseCount = (value.match(/[A-Z]/g) || []).length;
    const numberCount = (value.match(/\d/g) || []).length;
    const specialCount = (value.match(/[`~!@#$%^&*()\[\]{}|:;'"<,>.?/\\_\-+=]/g) || []).length;
    const isValid = /^[a-zA-Z\d`~!@#$%^&*()\[\]{}|:;'"<,>.?/\\_\-+=]+$/.test(value);

    if (!isValid) {
      return createError({ path, message: ({ label }) => replaceStr(INVALID_STRING_LATIN, { path: label }) });
    }

    if (
      value.length < minLength ||
      lowercaseCount < minLowercase ||
      uppercaseCount < minUppercase ||
      numberCount < minNumbers ||
      specialCount < minSpecial
    ) {
      return createError({ path, message: INVALID_PASSWORD_STRONG });
    }
    return true;
  });
});

yup.addMethod(yup.string, "limit", function (length, message) {
  return this.test("stringLimit", message || INVALID_LIMIT, function (value, ctx) {
    if (!value) return true;
    if (value.length >= length)
      return ctx.createError({ path: ctx.path, message: ({ label }) => replaceStr(INVALID_LIMIT, { path: label, max: length }) });
    return true;
  });
});

yup.addMethod(yup.number, "limit", function (length, message) {
  return this.test("numberLimit", message || INVALID_LIMIT, function (value, ctx) {
    if (!value) return true;
    if (value >= length) return ctx.createError({ path: ctx.path, message: ({ label }) => replaceStr(INVALID_LIMIT, { path: label, max: length }) });
    return true;
  });
});

yup.addMethod(yup.string, "lettersOnly", function (message) {
  return this.test("lettersOnly", message || INVALID_STRING_MATCHES, function (value) {
    const { path, createError } = this;

    if (!value) return true; // Allow empty strings
    if (PATTERN.LETTER.test(value)) return true;
    return createError({ path, message: ({ label }) => replaceStr(INVALID_STRING_MATCHES, { path: label }) });
  });
});

yup.addMethod(yup.string, "numbersOnly", function (message) {
  return this.test("numbersOnly", message || INVALID_STRING_MATCHES, function (value) {
    const { path, createError } = this;
    if (!value) return true; // Allow empty strings

    if (PATTERN.NUMBER_ONLY.test(value)) return true;
    return createError({ path, message: ({ label }) => replaceStr(INVALID_NUMBER, { path: label }) });
  });
});

yup.addMethod(yup.string, "latinsOnly", function (message) {
  return this.test("latinsOnly", message || INVALID_STRING_MATCHES, function (value) {
    const { path, createError } = this;
    if (!value) return true; // Allow empty strings

    if (PATTERN.LATIN_ONLY.test(value)) return true;
    return createError({ path, message: ({ label }) => replaceStr(INVALID_STRING_LATIN, { path: label }) });
  });
});

yup.addMethod(yup.string, "latinAndNumber", function (message) {
  return this.test("latinAndNumber", message || INVALID_STRING_MATCHES, function (value) {
    const { path, createError } = this;
    if (!value) return true; // Allow empty strings

    if (PATTERN.LATIN_AND_NUMBERS.test(value)) return true;
    return createError({ path, message: ({ label }) => replaceStr(INVALID_STRING_LATIN, { path: label }) });
  });
});

yup.addMethod(yup.string, "halfWidthOnly", function (message) {
  return this.test("halfWidthOnly", message || INVALID_STRING_MATCHES, function (value) {
    const { path, createError } = this;
    if (!value) return true; // Allow empty strings

    if (PATTERN.HALF_WIDTH_ONLY.test(value)) return true;
    return createError({ path, message: ({ label }) => replaceStr(INVALID_STRING_HALF_WIDTH, { path: label }) });
  });
});

yup.addMethod(yup.string, "fullWidthOnly", function (message) {
  return this.test("fullWidthOnly", message || INVALID_STRING_FULL_WIDTH, function (value) {
    const { path, createError } = this;
    if (!value) return true; // Allow empty strings

    if (PATTERN.FULL_WIDTH_ONLY.test(value)) return true;
    return createError({ path, message: ({ label }) => replaceStr(INVALID_STRING_FULL_WIDTH, { path: label }) });
  });
});

yup.addMethod(yup.string, "uppercaseAlphanumericCharactersOnly", function (message) {
  return this.test("uppercaseAlphanumericCharactersOnly", message || INVALID_STRING_UPPERCASE_AND_NUMBER, function (value) {
    const { path, createError } = this;
    if (!value) return true; // Allow empty strings

    if (PATTERN.UPPERCASE_ALPHANUMERIC_CHARACTERS_ONLY.test(value)) return true;
    return createError({
      path,
      message: ({ label }) => message || replaceStr(INVALID_STRING_UPPERCASE_AND_NUMBER, { path: label }),
    });
  });
});

yup.addMethod(yup.string, "katakanaOnly", function (message) {
  return this.test("katakanaOnly", message || INVALID_STRING_MATCHES, function (value) {
    const { path, createError } = this;
    if (!value) return true; // Allow empty strings

    if (PATTERN.KATAKANA_AND_SPACE.test(value)) return true;
    return createError({ path, message: ({ label }) => replaceStr(INVALID_STRING_KATAKANA, { path: label }) });
  });
});

yup.addMethod(yup.string, "notKanji", function (message) {
  return this.meta({ isFurigana: true }).test("notKanji", message || INVALID_STRING_MATCHES, function (value) {
    const { path, createError } = this;
    if (!value) return true; // Allow empty strings

    if (!PATTERN.KANJI.test(value)) return true;
    return createError({ path, message: ({ label }) => replaceStr(INVALID_STRING_KANJI, { path: label }) });
  });
});

yup.addMethod(yup.string, "notHalfWidthCharacter", function (message) {
  return this.test("notHalfWidthCharacter", message || INVALID_STRING_MATCHES, function (value) {
    const { path, createError } = this;
    if (!value) return true; // Allow empty strings

    if (!PATTERN.HALF_WIDTH_CHARACTER.test(value)) return true;
    return createError({ path, message: ({ label }) => replaceStr(INVALID_STRING_HALF_WIDTH, { path: label }) });
  });
});

yup.addMethod(yup.string, "emptyToNull", function () {
  return this.transform(function (value, originalValue) {
    if (!value) return null;
    if (typeof originalValue === "string" && originalValue.trim() === "") return null;
    return value;
  });
});

yup.addMethod(yup.number, "emptyToNull", function () {
  return this.transform(function (value, originalValue) {
    if (typeof originalValue === "string" && originalValue.trim() === "") return null;
    if (value === "" || value === null || value === undefined) {
      return null;
    }
    return value;
  });
});

yup.addMethod(yup.number, "commas", function () {
  return this.transform(function (value, originalValue) {
    if (!originalValue?.toString()) return originalValue;
    if (typeof originalValue === "string") {
      originalValue = convertCommasToNumber(originalValue);
    }
    return !isNaN(Number(originalValue)) ? Number(originalValue) : originalValue;
  });
});

yup.addMethod(yup.number, "isSelection", function () {
  return this.meta({ type: "selection" });
});

yup.addMethod(yup.string, "isSelection", function () {
  return this.meta({ type: "selection" });
});

yup.addMethod(yup.string, "email", function (message) {
  return this.test("email", message || INVALID_EMAIL, function (value) {
    const { path, createError } = this;
    if (!value) return true;

    if (PATTERN.EMAIL.test(value)) return true;
    return createError({ path, message: ({ label }) => replaceStr(INVALID_EMAIL, { path: label }) });
  });
});

yup.addMethod(yup.string, "zipCode", function (message) {
  return this.transform(function (value, originalValue) {
    if (typeof originalValue === "string") {
      return originalValue.replace(/-/g, "");
    }
    return originalValue;
  })
    .meta({ isZipCode: true })
    .test("zipCode", message || INVALID_ZIP_CODE, function (value) {
      const { createError } = this;
      if (!value) return true;

      const originalValue = this.parent[this.path];
      const zipCodePattern = PATTERN.ZIP_CODE;

      if (zipCodePattern.test(originalValue)) return true;

      return createError({ path: this.path, message: message || INVALID_ZIP_CODE });
    })
    .test(async function (value, ctx) {
      const { createError } = ctx;
      if (value?.length && value?.length === 7) {
        try {
          await zipCodeApi.head(`/${value}.json`);
          return true;
        } catch {
          return createError({ path: this.path, message: "入力された郵便番号が無効です。正しい郵便番号を入力してください。" });
        }
      } else return true;
    });
});

yup.addMethod(yup.string, "isTelNumber", function (message) {
  return this.test("isTelNumber", message || INVALID_TEL_NUMBER, function (value) {
    const { createError } = this;

    if (!value) return true;

    // Check if the number starts with 0
    if (!value.startsWith("0")) {
      return createError({ path: this.path, message: "0から始まる数字を入力してください。" });
    }

    // Check if the number includes hyphens
    if (!value.includes("-")) {
      return createError({ path: this.path, message: "ハイフン区切りの数字で入力してください。" });
    }

    // Check if the number ends with a digit
    if (/-$/.test(value)) {
      return createError({ path: this.path, message: "数字で終わる番号を入力してください。" });
    }

    const isValid = PATTERN.TEL_NUMBER.test(value);
    if (isValid) {
      return true;
    }

    return createError({ path: this.path, message: message || INVALID_TEL_NUMBER });
  });
});

yup.addMethod(yup.string, "autoTransformToHalfWidth", function () {
  return this.meta({ autoTransformToHalfWidth: true });
});

yup.addMethod(yup.string, "autoTransformToFullWidth", function () {
  return this.meta({ autoTransformToFullWidth: true });
});

yup.addMethod(yup.number, "autoTransformToHalfWidth", function () {
  return this.meta({ autoTransformToHalfWidth: true });
});

export const validator = yup;
