import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { FILE_UPLOAD_FOR, MIME_TYPES } from "@/constants/file";
import { UploadFileBtnForm } from "@/features/employee/components/employee-forms/UploadFileBtnForm";
import { TEmployeeForm } from "@/features/employee/types/employeeForm";
import { useScrollToElement } from "@/hooks/useScrollToElement";
import { dayjs } from "@/utils/dayjs";
import { convertMbToByte } from "@/utils/file";
import { Grid, Stack } from "@mui/material";
import { useFormContext } from "react-hook-form";

export const PassportForm = () => {
  const { control } = useFormContext<TEmployeeForm>();

  const { elmId: passportCodeElmId } = useScrollToElement("passport_code_input");
  const { elmId: passportExpiryDateElmId } = useScrollToElement("passport_expiry_date_input");

  return (
    <Stack gap={1}>
      <InnerBox title="パスポート情報">
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={4}>
            <FormField control={control} name="passportPermanentAddress">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={4}>
            <FormField id={passportCodeElmId} control={control} name="passportCode">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField id={passportExpiryDateElmId} control={control} name="passportExpiryDate">
              <InputDatePicker minDate={dayjs("1900/01/01")} maxDate={dayjs("2099/12/31")} />
            </FormField>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <FormField control={control} name="passportFile">
              <UploadFileBtnForm
                uploadFor={FILE_UPLOAD_FOR.EMPLOYEE_PASSPORT_FILE}
                rules={{ acceptMimeTypes: [MIME_TYPES.IMAGE, MIME_TYPES.PDF, MIME_TYPES.ZIP].flat(), maxSize: convertMbToByte(20) }}
              />
            </FormField>
          </Grid>
        </Grid>
      </InnerBox>
    </Stack>
  );
};
