import { useMount } from "@/hooks/useMount";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useScrollToElement = (id: string) => {
  const { hash } = useLocation();
  const isMounted = useMount();

  useEffect(() => {
    if (!isMounted) return;
    if (hash === `#${id}`) {
      setTimeout(() => {
        const elm = document.getElementById(id);
        if (!elm) return;
        elm.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 100);
    }
  }, [isMounted]);

  return { elmId: id };
};
