import { TGetEmployeeOutputDto } from "@/api/services/main/employee/dtos/get-employee.dto";
import { FILE_STATUS } from "@/constants/file";
import { TEmployeeForm } from "@/features/employee/types/employeeForm";
import { TExtendFile } from "@/types/file";
import { dayjs } from "@/utils/dayjs";
import { getFileNameFromPath } from "@/utils/file";
import { toNumber } from "@/utils/number";
import { makeUuid } from "@/utils/pieces";
import { Dayjs } from "dayjs";

export const convertEmployeeResToFormDataDto = (data?: TGetEmployeeOutputDto): TEmployeeForm | undefined => {
  if (!data) return undefined;

  return {
    managementNumber: data.managementNumber ?? null,
    lastName: data.lastName ?? null,
    middleName: data.middleName ?? null,
    firstName: data.firstName ?? null,
    nickName: data.nickName ?? null,
    nationalityId: data.nationalityId?.toString(),
    gender: data.gender,
    birthday: dayjs(data.birthday),
    hiringDate: dayjs(data.hiringDate),
    employmentStatus: data.employmentStatus ?? null,
    retirementStatus: data.retirementStatus ?? null,
    retirementDate: data.retirementDate ? dayjs(data.retirementDate) : null,
    zipCode: data.zipCode ? data.zipCode : null,
    province: data.province ? data.province : null,
    municipality: data.municipality ? data.municipality : null,
    address: data.address ? data.address : null,
    skillOneStartDate: data.skillOneStartDate ? dayjs(data.skillOneStartDate) : null,
    blankPeriodMonthly: data.blankPeriodMonthly?.toString() ?? null,
    telNumber: data.telNumber ?? "",
    email: data.email ?? "",
    referralCompanyName: data.referralCompanyName,
    registeredSupportOrganizationName: data.registeredSupportOrganizationName,
    avatarFile: data.avatarFilePath
      ? ({ id: makeUuid(), status: FILE_STATUS.OK, name: getFileNameFromPath(data.avatarFilePath), filePath: data.avatarFilePath } as TExtendFile)
      : null,
    contractConditionFile:
      data.contractConditionFilePath?.map(
        (filePath) =>
          ({
            id: makeUuid(),
            status: FILE_STATUS.OK,
            name: getFileNameFromPath(filePath),
            filePath: filePath,
          }) as TExtendFile,
      ) ?? null,
    contractEmploymentFile:
      data.contractEmploymentFilePath?.map(
        (filePath) =>
          ({
            id: makeUuid(),
            status: FILE_STATUS.OK,
            name: getFileNameFromPath(filePath),
            filePath: filePath,
          }) as TExtendFile,
      ) ?? null,
    contractMainOccupationId: data.contractMainOccupationId?.toString() ?? "",
    contractSpecificBusinessClassificationOneId: data.contractSpecificBusinessClassificationOneId?.toString() ?? null,
    contractSpecificBusinessClassificationTwoId: data.contractSpecificBusinessClassificationTwoId?.toString() ?? null,
    contractSpecificBusinessClassificationThreeId: data.contractSpecificBusinessClassificationThreeId?.toString() ?? null,
    contractMonthlySalary: toNumber(data.contractMonthlySalary),
    contractCompanyBranchId: data.contractCompanyBranchId?.toString() ?? "",
    passportCode: data.passportCode ?? null,
    contractEndDate: data.contractEndDate ? dayjs(data.contractEndDate) : (undefined as unknown as Dayjs),
    contractStartDate: data.contractStartDate ? dayjs(data.contractStartDate) : (undefined as unknown as Dayjs),
    passportExpiryDate: data.passportExpiryDate ? dayjs(data.passportExpiryDate) : (undefined as unknown as Dayjs),
    passportFile: data.passportFilePath
      ? ({ id: makeUuid(), status: FILE_STATUS.OK, name: getFileNameFromPath(data.passportFilePath), filePath: data.passportFilePath } as TExtendFile)
      : null,
    passportPermanentAddress: data.passportPermanentAddress ?? null,
    residenceCardBackFile:
      data.residenceCardBackFilePath?.map(
        (filePath) =>
          ({
            id: makeUuid(),
            status: FILE_STATUS.OK,
            name: getFileNameFromPath(filePath),
            filePath: filePath,
          }) as TExtendFile,
      ) ?? null,
    residenceCardFrontFile:
      data.residenceCardFrontFilePath?.map(
        (filePath) =>
          ({
            id: makeUuid(),
            status: FILE_STATUS.OK,
            name: getFileNameFromPath(filePath),
            filePath: filePath,
          }) as TExtendFile,
      ) ?? null,
    residenceCode: data.residenceCode ? data.residenceCode : null,
    residencePeriodId: data.residencePeriodId?.toString() ?? null,
    residenceExpiryDate: data.residenceExpiryDate ? dayjs(data.residenceExpiryDate) : null,
    residenceStatusId: data.residenceStatusId?.toString() ?? "",
    careerResumeFile: data.careerResumeFilePath
      ? ({
          id: makeUuid(),
          status: FILE_STATUS.OK,
          name: getFileNameFromPath(data.careerResumeFilePath),
          filePath: data.careerResumeFilePath,
        } as TExtendFile)
      : null,
    careerTrainingStatus: data.careerTrainingStatus ?? null,
    careerMajorId: data.careerMajorId?.toString() ?? null,
    careerJobTypeId: data.careerJobTypeId?.toString() ?? null,
    careerCompletionProof: data.careerCompletionProof ?? null,
    careerTrainingCompletionFile: data.careerTrainingCompletionFilePath
      ? ({
          id: makeUuid(),
          status: FILE_STATUS.OK,
          name: getFileNameFromPath(data.careerTrainingCompletionFilePath),
          filePath: data.careerTrainingCompletionFilePath,
        } as TExtendFile)
      : null,
    careerSkillCertificationOne: data.careerSkillCertificationOne ?? null,
    careerSkillExamOneId: data.careerSkillExamOneId ? data.careerSkillExamOneId.toString() : null,
    careerSkillExamLocationOne: data.careerSkillExamLocationOne ?? null,
    careerSkillExamOverseaOneNationalityId: data.careerSkillExamOverseaOneNationalityId?.toString() ?? null,
    careerSkillOtherEvaluation: data.careerSkillOtherEvaluation ?? null,
    careerSkillCompletionFile: data.careerSkillCompletionFilePath
      ? ({
          id: makeUuid(),
          status: FILE_STATUS.OK,
          name: getFileNameFromPath(data.careerSkillCompletionFilePath),
          filePath: data.careerSkillCompletionFilePath,
        } as TExtendFile)
      : null,
    careerJapaneseCertificationOne: data.careerJapaneseCertificationOne ?? null,
    careerJapaneseExamOneId: data.careerJapaneseExamOneId?.toString() ?? null,
    careerJapaneseExamLocationOne: data.careerJapaneseExamLocationOne ?? null,
    careerJapaneseExamOverseaOneNationalityId: data.careerJapaneseExamOverseaOneNationalityId?.toString() ?? null,
    careerJanpaneseCompletionOneFile: data.careerJanpaneseCompletionOneFilePath
      ? ({
          id: makeUuid(),
          status: FILE_STATUS.OK,
          name: getFileNameFromPath(data.careerJanpaneseCompletionOneFilePath),
          filePath: data.careerJanpaneseCompletionOneFilePath,
        } as TExtendFile)
      : null,
    careerJapaneseCertificationTwo: data.careerJapaneseCertificationTwo ?? null,
    careerJapaneseExamTwoId: data.careerJapaneseExamTwoId?.toString() ?? null,
    careerJapaneseExamLocationTwo: data.careerJapaneseExamLocationTwo ?? null,
    careerJapaneseExamOverseaTwoNationalityId: data.careerJapaneseExamOverseaTwoNationalityId?.toString() ?? null,
    careerJanpaneseCompletionTwoFile: data.careerJanpaneseCompletionTwoFilePath
      ? ({
          id: makeUuid(),
          status: FILE_STATUS.OK,
          name: getFileNameFromPath(data.careerJanpaneseCompletionTwoFilePath),
          filePath: data.careerJanpaneseCompletionTwoFilePath,
        } as TExtendFile)
      : null,
    careerConfirmationGuidanceFile: data.careerConfirmationGuidanceFilePath
      ? ({
          id: makeUuid(),
          status: FILE_STATUS.OK,
          name: getFileNameFromPath(data.careerConfirmationGuidanceFilePath),
          filePath: data.careerConfirmationGuidanceFilePath,
        } as TExtendFile)
      : null,
    careerConfirmationOrientationFile: data.careerConfirmationOrientationFilePath
      ? ({
          id: makeUuid(),
          status: FILE_STATUS.OK,
          name: getFileNameFromPath(data.careerConfirmationOrientationFilePath),
          filePath: data.careerConfirmationOrientationFilePath,
        } as TExtendFile)
      : null,
    careerDependentNote: data.careerDependentNote ?? null,
    careerDependentFamilyFile:
      data.careerDependentFamilyFilePath?.map(
        (filePath) =>
          ({
            id: makeUuid(),
            status: FILE_STATUS.OK,
            name: getFileNameFromPath(filePath),
            filePath: filePath,
          }) as TExtendFile,
      ) ?? null,
    careerDependentRemittanceFile:
      data.careerDependentRemittanceFilePath?.map(
        (filePath) =>
          ({
            id: makeUuid(),
            status: FILE_STATUS.OK,
            name: getFileNameFromPath(filePath),
            filePath: filePath,
          }) as TExtendFile,
      ) ?? null,
    note: data.note ?? null,
    hasRetiredBefore: data.hasRetiredBefore,
  };
};
