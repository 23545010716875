import { TRenewVisaEmployeeApplicationDocument } from "@/api/entities/renew-visa-employee-application-document.entity";
import { TGetEmployeeOutputDto } from "@/api/services/main/employee/dtos/get-employee.dto";
import { caculateSpecificSkillTransitionPeriod } from "@/features/employee/utils/caculateSpecificSkillTransitionPeriod";
import { EPaymentMethod } from "@/features/renew-visa/enums/employeeApplicationForm";
import { TEmployeeApplicationForm } from "@/features/renew-visa/types/employeeApplicationForm";
import { YES_NO_AND_NOT_APPLICABLE_ANSWER, YES_NO_ANSWER } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { isNumber, toNumber } from "@/utils/number";
import { getFullName } from "@/utils/string";

export const convertResToEmployeeApplicationFormData = (
  employee?: TGetEmployeeOutputDto,
  employeeApplicationData?: TRenewVisaEmployeeApplicationDocument,
): TEmployeeApplicationForm | undefined => {
  if (!employee) return undefined;

  const { period: defaultRelatedActivitySpecificSkillTransitionPeriod } = caculateSpecificSkillTransitionPeriod({
    skillOneStartDate: employee.skillOneStartDate ? dayjs(employee.skillOneStartDate) : null,
    blankPeriodMonthly: employee.blankPeriodMonthly?.toString(),
  });

  return {
    employeeId: employee.id.toString(),
    employeeInfoFullName:
      employeeApplicationData?.employeeInfoFullName ??
      getFullName({ firstName: employee.firstName, lastName: employee.lastName, middleName: employee.middleName }),
    employeeInfoBirthday: employeeApplicationData ? dayjs(employeeApplicationData.employeeInfoBirthday) : dayjs(employee.birthday),
    employeeInfoNationalityId: employeeApplicationData
      ? employeeApplicationData.employeeInfoNationalityId?.toString()
      : employee.nationalityId?.toString(),
    employeeInfoAddress: employeeApplicationData ? employeeApplicationData.employeeInfoAddress : employee.address,
    employeeInfoGender: employeeApplicationData ? employeeApplicationData.employeeInfoGender : employee.gender,
    employeeInfoPassportExpiryDate:
      employeeApplicationData && employeeApplicationData.employeeInfoPassportExpiryDate
        ? dayjs(employeeApplicationData.employeeInfoPassportExpiryDate)
        : employee.passportExpiryDate
          ? dayjs(employee.passportExpiryDate)
          : null,
    contractMainOccupationId: employeeApplicationData
      ? employeeApplicationData.contractMainOccupationId?.toString()
      : employee.contractMainOccupationId?.toString(),
    employeeInfoTelNumber:
      employeeApplicationData?.employeeInfoTelNumber || employee?.contractCompanyBranch?.telNumber || employee?.company?.telNumber || "",
    employeeInfoEmail: employeeApplicationData ? employeeApplicationData.employeeInfoEmail : employee.email,
    employeeInfoPassportCode: employeeApplicationData ? employeeApplicationData.employeeInfoPassportCode : employee.passportCode,
    relatedActivityMajorId: employeeApplicationData ? employeeApplicationData.relatedActivityMajorId?.toString() : employee.careerMajorId?.toString(),
    relatedActivityJobTypeId: employeeApplicationData
      ? employeeApplicationData.relatedActivityJobTypeId?.toString()
      : employee.careerJobTypeId?.toString(),
    employeeInfoPassportPermanentAddress: employeeApplicationData
      ? employeeApplicationData.employeeInfoPassportPermanentAddress
      : employee.passportPermanentAddress,
    relatedActivityCompletionProof: employeeApplicationData ? employeeApplicationData.relatedActivityCompletionProof : employee.careerCompletionProof,
    relatedActivitySkillExamOneId: employeeApplicationData
      ? employeeApplicationData.relatedActivitySkillExamOneId?.toString()
      : employee.careerSkillExamOneId?.toString(),
    relatedActivityJapaneseExamTwoId: employeeApplicationData
      ? employeeApplicationData.relatedActivityJapaneseExamTwoId?.toString()
      : employee.careerJapaneseExamOneId?.toString(),
    relatedActivitySkillCertificationOne: employeeApplicationData
      ? employeeApplicationData.relatedActivitySkillCertificationOne
      : employee.careerSkillCertificationOne,
    relatedActivitySkillExamLocationOne: employeeApplicationData
      ? employeeApplicationData.relatedActivitySkillExamLocationOne
      : employee.careerSkillExamLocationOne,
    relatedActivitySkillOtherEvaluation: employeeApplicationData
      ? employeeApplicationData.relatedActivitySkillOtherEvaluation
      : employee.careerSkillOtherEvaluation,
    relatedActivitySkillExamOverseaOneNationalityId: employeeApplicationData
      ? employeeApplicationData.relatedActivitySkillExamOverseaOneNationalityId?.toString()
      : employee.careerSkillExamOverseaOneNationalityId?.toString(),
    relatedActivityJapaneseCertificationTwo: employeeApplicationData
      ? employeeApplicationData.relatedActivityJapaneseCertificationTwo
      : employee.careerJapaneseCertificationOne,
    relatedActivityJapaneseExamLocationTwo: employeeApplicationData
      ? employeeApplicationData.relatedActivityJapaneseExamLocationTwo
      : employee.careerJapaneseExamLocationOne,
    relatedActivityJapaneseExamThreeId: employeeApplicationData
      ? employeeApplicationData.relatedActivityJapaneseExamThreeId?.toString()
      : employee.careerJapaneseExamTwoId?.toString(),
    relatedActivityJapaneseExamLocationThree: employeeApplicationData
      ? employeeApplicationData.relatedActivityJapaneseExamLocationThree
      : employee.careerJapaneseExamLocationTwo,
    relatedActivityJapaneseCertificationThree: employeeApplicationData
      ? employeeApplicationData.relatedActivityJapaneseCertificationThree
      : employee.careerJapaneseCertificationTwo,
    relatedActivityJapaneseExamOverseaTwoNationalityId: employeeApplicationData
      ? employeeApplicationData.relatedActivityJapaneseExamOverseaTwoNationalityId?.toString()
      : employee.careerJapaneseExamOverseaOneNationalityId?.toString(),
    relatedActivityJapaneseExamOverseaThreeNationalityId: employeeApplicationData
      ? employeeApplicationData.relatedActivityJapaneseExamOverseaThreeNationalityId?.toString()
      : employee.careerJapaneseExamOverseaTwoNationalityId?.toString(),
    employeeInfoTwoResidenceCode: employeeApplicationData ? employeeApplicationData.employeeInfoTwoResidenceCode ?? "" : employee.residenceCode,
    employeeInfoTwoResidenceExpiryDate:
      employeeApplicationData && employeeApplicationData.employeeInfoTwoResidenceExpiryDate
        ? dayjs(employeeApplicationData.employeeInfoTwoResidenceExpiryDate)
        : employee.residenceExpiryDate
          ? dayjs(employee.residenceExpiryDate)
          : null,
    employeeInfoTwoResidenceStatusId: employeeApplicationData
      ? employeeApplicationData.employeeInfoTwoResidenceStatusId?.toString()
      : employee.residenceStatusId?.toString(),
    employeeInfoTwoResidencePeriodId: employeeApplicationData
      ? employeeApplicationData.employeeInfoTwoResidencePeriodId?.toString()
      : employee.residencePeriodId?.toString(),
    contractMonthlySalary: employeeApplicationData ? employeeApplicationData.contractMonthlySalary : employee.contractMonthlySalary,
    contractSpecificIndustryId: employeeApplicationData
      ? employeeApplicationData.contractSpecificIndustryId?.toString()
      : employee.contractSpecificBusinessClassificationOne?.specificIndustryId.toString(),
    contractSpecificBusinessClassificationId: employeeApplicationData
      ? employeeApplicationData.contractSpecificBusinessClassificationId?.toString()
      : employee.contractSpecificBusinessClassificationOneId?.toString(),
    contractSpecificIndustryTwoId: employeeApplicationData
      ? employeeApplicationData.contractSpecificIndustryTwoId?.toString()
      : employee.contractSpecificBusinessClassificationTwo?.specificIndustryId.toString(),
    contractSpecificBusinessClassificationTwoId: employeeApplicationData
      ? employeeApplicationData.contractSpecificBusinessClassificationTwoId?.toString()
      : employee.contractSpecificBusinessClassificationTwoId?.toString(),
    contractSpecificIndustryThreeId: employeeApplicationData
      ? employeeApplicationData.contractSpecificIndustryThreeId?.toString()
      : employee.contractSpecificBusinessClassificationThree?.specificIndustryId.toString(),
    contractSpecificBusinessClassificationThreeId: employeeApplicationData
      ? employeeApplicationData.contractSpecificBusinessClassificationThreeId?.toString()
      : employee.contractSpecificBusinessClassificationThreeId?.toString(),
    contractStartDate:
      employeeApplicationData && employeeApplicationData.contractStartDate
        ? dayjs(employeeApplicationData.contractStartDate)
        : employee.contractStartDate
          ? dayjs(employee.contractStartDate)
          : null,
    contractEndDate:
      employeeApplicationData && employeeApplicationData.contractEndDate
        ? dayjs(employeeApplicationData.contractEndDate)
        : employee.contractEndDate
          ? dayjs(employee.contractEndDate)
          : null,
    employeeInfoProvince: employeeApplicationData ? employeeApplicationData.employeeInfoProvince : employee.province,
    employeeInfoMunicipality: employeeApplicationData ? employeeApplicationData.employeeInfoMunicipality : employee.municipality,
    dispatchingOrganizations:
      employeeApplicationData && employeeApplicationData.dispatchingOrganizations.length > 0
        ? employeeApplicationData.dispatchingOrganizations.map((item) => ({
            organizationName: item.organizationName,
            organizationIdentificationNumber: item.organizationIdentificationNumber,
            employmentInsuranceApplicableEstablishmentNumber: item.employmentInsuranceApplicableEstablishmentNumber,
            organizationProvince: item.organizationProvince,
            organizationMunicipality: item.organizationMunicipality,
            organizationAddress: item.organizationAddress,
            organizationTelNumber: item.organizationTelNumber,
            representativeName: item.representativeName,
            startDate: item.startDate ? dayjs(item.startDate) : null,
            endDate: item.endDate ? dayjs(item.endDate) : null,
          }))
        : [{ organizationName: "" }],
    employeeFamilyMembers:
      employeeApplicationData && employeeApplicationData.employeeFamilyMembers.length > 0
        ? employeeApplicationData.employeeFamilyMembers.map((item) => ({
            employeeRelationshipId: item.employeeRelationshipId?.toString() ?? "",
            fullName: item.fullName ?? "",
            birthday: dayjs(item.birthday),
            nationalityId: item.nationalityId?.toString() ?? "",
            livingWithEmployee: item.livingWithEmployee ?? "",
            workingLocation: item.workingLocation ?? "",
            residenceCode: item.residenceCode ?? "",
          }))
        : [],
    employeeInfoLivingWithDependentPerson: employeeApplicationData
      ? employeeApplicationData.employeeInfoLivingWithDependentPerson ?? YES_NO_ANSWER.NO
      : YES_NO_ANSWER.NO,
    employeeInfoMaritalStatus: employeeApplicationData ? employeeApplicationData.employeeInfoMaritalStatus ?? "" : "",
    employeeInfoPunishmentDetailForCrime: employeeApplicationData ? employeeApplicationData.employeeInfoPunishmentDetailForCrime : "",
    employeeInfoResidenceCardReceiveMethod: employeeApplicationData ? employeeApplicationData.employeeInfoResidenceCardReceiveMethod ?? "" : "",
    relatedActivitySpecificSkillTransitionPeriod: employeeApplicationData
      ? employeeApplicationData.relatedActivitySpecificSkillTransitionPeriod
      : defaultRelatedActivitySpecificSkillTransitionPeriod,
    relatedActivityHasCollectionForSpecificSkillOne: employeeApplicationData
      ? employeeApplicationData.relatedActivityHasCollectionForSpecificSkillOne
      : YES_NO_ANSWER.NO,
    employeeInfoHasReceivedPunishmentForCrime: employeeApplicationData
      ? employeeApplicationData.employeeInfoHasReceivedPunishmentForCrime
      : YES_NO_ANSWER.NO,
    whetherEquivalentWorkingHoursToRegularEmployee: employeeApplicationData?.whetherEquivalentWorkingHoursToRegularEmployee
      ? employeeApplicationData.whetherEquivalentWorkingHoursToRegularEmployee
      : YES_NO_ANSWER.YES,
    compensationEqualOrAboveJapaneseStandard: employeeApplicationData?.compensationEqualOrAboveJapaneseStandard
      ? employeeApplicationData.compensationEqualOrAboveJapaneseStandard
      : YES_NO_ANSWER.YES,
    contractIsDiscriminatedForBeingForeigner: employeeApplicationData?.contractIsDiscriminatedForBeingForeigner
      ? employeeApplicationData.contractIsDiscriminatedForBeingForeigner
      : YES_NO_ANSWER.NO,
    contractIsDiscriminatedForBeingForeignerDetail: employeeApplicationData?.contractIsDiscriminatedForBeingForeignerDetail ?? null,
    contractHasPaidLeaveForReturningHomeTemporarily: employeeApplicationData
      ? employeeApplicationData.contractHasPaidLeaveForReturningHomeTemporarily
      : YES_NO_ANSWER.YES,
    contractHasProvisionForTravelExpensesWhenLeave: employeeApplicationData
      ? employeeApplicationData.contractHasProvisionForTravelExpensesWhenLeave
      : YES_NO_ANSWER.YES,
    complianceWithStandardsForProperResidenceInSpecificIndustry: employeeApplicationData
      ? employeeApplicationData.complianceWithStandardsForProperResidenceInSpecificIndustry
      : YES_NO_ANSWER.YES,
    relatedActivityHasUnderstandingAndAgreementOnForeignAgencyFees: employeeApplicationData
      ? employeeApplicationData.relatedActivityHasUnderstandingAndAgreementOnForeignAgencyFees
      : YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE,
    relatedActivityHasComplianceWithProceduresInHomeCountry: employeeApplicationData
      ? employeeApplicationData.relatedActivityHasComplianceWithProceduresInHomeCountry
      : YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE,
    relatedActivityWhetherRegularPayments:
      employeeApplicationData?.relatedActivityWhetherRegularPayments ?? YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE,
    relatedActivityHasEffortToTransferSkillsToHomeCountry: employeeApplicationData
      ? employeeApplicationData.relatedActivityHasEffortToTransferSkillsToHomeCountry
      : YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE,
    relatedActivityHasComplianceWithIndustrySpecificCriteria: employeeApplicationData
      ? employeeApplicationData.relatedActivityHasComplianceWithIndustrySpecificCriteria
      : YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE,
    contractHasComplianceWithEmploymentStandardsInSpecificIndustry: employeeApplicationData
      ? employeeApplicationData.contractHasComplianceWithEmploymentStandardsInSpecificIndustry
      : YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE,
    contractHasSupportForeignerHealthAndLivingConditions: employeeApplicationData
      ? employeeApplicationData.contractHasSupportForeignerHealthAndLivingConditions
      : YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE,
    relatedActivityForeignAgencyName: employeeApplicationData ? employeeApplicationData.relatedActivityForeignAgencyName : "",
    relatedActivityForeignAgencyFee: employeeApplicationData?.relatedActivityForeignAgencyFee,
    relatedActivityCollectionOrManagementAgencyName: employeeApplicationData
      ? employeeApplicationData.relatedActivityCollectionOrManagementAgencyName
      : null,
    relatedActivityCollectionAmount: employeeApplicationData?.relatedActivityCollectionAmount,
    employeeInfoResidenceCardReceiveGovernmentCounterId: employeeApplicationData
      ? employeeApplicationData.employeeInfoResidenceCardReceiveGovernmentCounterId?.toString()
      : null,
    employeeInfoRecentResidenceTax: employeeApplicationData ? employeeApplicationData.employeeInfoRecentResidenceTax : null,
    employeeInfoTwoResidenceDesiredPeriodId: employeeApplicationData
      ? employeeApplicationData.employeeInfoTwoResidenceDesiredPeriodId?.toString() ?? ""
      : "",
    employeeInfoTwoResidenceChangeReason: employeeApplicationData
      ? employeeApplicationData.employeeInfoTwoResidenceChangeReason ?? ""
      : "引き続き特定技能1号として就労するため",
    employeeInfoTwoRasImmiFullName:
      employeeApplicationData && employeeApplicationData.employeeInfoTwoRasImmiFullName
        ? employeeApplicationData.employeeInfoTwoRasImmiFullName
        : employee?.company?.governmentOnlineSystemAccount?.rasImmiFullName ?? "",
    contractHourlySalary: toNumber(employeeApplicationData?.contractHourlySalary),
    employeeInfoMobileNumber: employeeApplicationData ? employeeApplicationData.employeeInfoMobileNumber : null,
    contractWeeklyWorkingHours: employeeApplicationData?.contractWeeklyWorkingHours
      ? toNumber(employeeApplicationData?.contractWeeklyWorkingHours)
      : toNumber(employee.contractCompanyBranch?.weeklyWorkingHours),
    contractSalaryPaymentMethod: employeeApplicationData?.contractSalaryPaymentMethod?.toString()
      ? employeeApplicationData.contractSalaryPaymentMethod
      : EPaymentMethod.BANKING,
    employeeInfoCareer: employeeApplicationData
      ? employeeApplicationData.employeeInfoCareer
      : `${employee?.contractSpecificBusinessClassificationOne?.companySpecificBusinessClassification?.companySpecificIndustry?.companySpecificIndustry ?? ""}`,
    contractMonthlyWorkingHours: employeeApplicationData?.contractMonthlyWorkingHours
      ? toNumber(employeeApplicationData?.contractMonthlyWorkingHours)
      : toNumber(employee.contractCompanyBranch?.monthWorkingHours),
    contractMonthlySalaryForJapanesePerson: isNumber(employeeApplicationData?.contractMonthlySalaryForJapanesePerson)
      ? toNumber(employeeApplicationData?.contractMonthlySalaryForJapanesePerson)
      : null,
    workingHistories:
      employeeApplicationData && employeeApplicationData.workingHistories.length > 0
        ? employeeApplicationData.workingHistories.map((item) => ({
            jobName: item.jobName,
            workingStartYear: item.workingStartYear ? dayjs().date(1).year(item.workingStartYear) : null,
            workingStartMonth: item.workingStartMonth ? dayjs().date(1).month(item.workingStartMonth) : null,
            workingEndYear: item.workingEndYear ? dayjs().date(1).year(item.workingEndYear) : null,
            workingEndMonth: item.workingEndMonth ? dayjs().date(1).month(item.workingEndMonth) : null,
          }))
        : [{ jobName: "" }],
  };
};
