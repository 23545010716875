import { INVALID_NUMBER_MAX, INVALID_NUMBER_MIN } from "@/constants/invalids";
import { replaceStr } from "@/utils/string";
import { validator } from "@/utils/validator";
import { floorToTwoDecimals } from "@/utils/number"; // Assuming this utility function exists

export const CompanyBranchSchema = validator.object().shape({
  branchName: validator.string().trim().emptyToNull().max(60).autoTransformToFullWidth().label("勤務先　事業所名").required(),
  zipCode: validator.string().trim().emptyToNull().autoTransformToHalfWidth().zipCode().label("勤務先　郵便番号").required(),
  province: validator.string().isSelection().trim().emptyToNull().label("勤務先　都道府県").required(),
  municipality: validator.string().isSelection().trim().emptyToNull().label("勤務先　市区町村").required(),
  address: validator.string().trim().emptyToNull().max(80).autoTransformToFullWidth().label("勤務先　都道府県・市区町村以降").required(),
  telNumber: validator.string().trim().emptyToNull().autoTransformToHalfWidth().isTelNumber().label("勤務先　電話番号").notRequired(),
  weeklyWorkingHours: validator
    .number()
    .commas()
    .emptyToNull()
    .autoTransformToHalfWidth()
    .min(0)
    .max(100)
    .transform((value) => floorToTwoDecimals(value))
    .label("所定労働時間（週平均）")
    .required(),
  monthWorkingHours: validator
    .number()
    .commas()
    .emptyToNull()
    .autoTransformToHalfWidth()
    .min(0)
    .max(1000)
    .transform((value) => floorToTwoDecimals(value))
    .label("所定労働時間（月平均）")
    .required(),
  businessInsuranceNumber: validator
    .string()
    .trim()
    .emptyToNull()
    .autoTransformToHalfWidth()
    .numbersOnly()
    .length(11)
    .label("雇用保険適用事業所番号（11桁）")
    .notRequired(),
  employmentInsuranceNumber: validator
    .string()
    .trim()
    .emptyToNull()
    .autoTransformToHalfWidth()
    .numbersOnly()
    .min(11, replaceStr(INVALID_NUMBER_MIN, { path: "労働保険番号", min: 11 }))
    .max(18, replaceStr(INVALID_NUMBER_MAX, { path: "労働保険番号", max: 18 }))
    .label("労働保険番号")
    .required(),
});
