import { useGetMainOccupationList } from "@/api/services/global/master";
import { createEmployeeApi } from "@/api/services/main/employee";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { TopPage } from "@/components/elements/TopPage";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { EmployeeForm } from "@/features/employee/components/employee-forms";
import { useGetEmployeeSchema } from "@/features/employee/hook/useGetEmployeeSchema";
import { TEmployeeForm } from "@/features/employee/types/employeeForm";
import { convertEmployeeFormDataToReqDto } from "@/features/employee/utils/convertFormDataToReqDto";
import { AppRoutes } from "@/routes/config";
import { background } from "@/theme/colors";
import { EMPLOYEE_TRAINING_STATUS, EMPLOYMENT_STATUS, RETIREMENT_STATUS } from "@/types/enum";
import { setErrorForm, showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, styled } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

type TEmployeeCreatePageProps = {};

export const EmployeeCreatePage: FC<TEmployeeCreatePageProps> = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: mainOccupation } = useGetMainOccupationList();

  const { employeeSchema } = useGetEmployeeSchema();

  const methods = useForm<TEmployeeForm>({
    resolver: yupResolver(employeeSchema),
    mode: "onBlur",
    defaultValues: {
      residenceStatusId: "",
      retirementStatus: RETIREMENT_STATUS.ENROLLMENT,
      employmentStatus: EMPLOYMENT_STATUS.OFFERED,
      careerTrainingStatus: EMPLOYEE_TRAINING_STATUS.UN_SELECTED,
      contractMainOccupationId: mainOccupation.find((item) => item.mainOccupation === "その他")?.id.toString() ?? "",
      hasRetiredBefore: false,
    },
  });

  const onSubmit = async (data: TEmployeeForm) => {
    try {
      const payload = convertEmployeeFormDataToReqDto(data);
      const rs = await createEmployeeApi(payload);
      navigate(AppRoutes.employeeList);
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EMPLOYEE.FETCH_LIST] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EMPLOYEE.FETCH_DETAIL, rs.data.id] });
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Form methods={methods} onSubmit={onSubmit} schema={employeeSchema}>
      <TopPageWrapper>
        <TopPage
          title="特定技能外国人"
          backUrl={AppRoutes.employeeList}
          rightContent={
            <Button type="submit" variant="primary" size="lg">
              保存して閉じる
            </Button>
          }
        />
      </TopPageWrapper>
      <EmployeeForm />
    </Form>
  );
};

const TopPageWrapper = styled(Box)`
  position: sticky;
  top: 80px;
  z-index: 100;
  background-color: ${background.primary};
  height: 156px;
  padding-top: 24px;
  transform: translateY(-24px);
`;
