import { TGetEmployeeListOutputDto } from "@/api/services/main/employee/dtos/get-employee-list.dto";
import { DATE_FORMAT } from "@/constants/datetime";
import { PLAIN_TEXT } from "@/features/regular-document/constants/paycheckPlainText";
import { dayjs } from "@/utils/dayjs";
import { downloadJSONByCSV } from "@/utils/export";
import { newRef, pick } from "@/utils/object";
import { getFullName } from "@/utils/string";

export const downloadPaycheckSampleFile = (employees: TGetEmployeeListOutputDto[], quarter: number) => {
  const initData = {
    [PLAIN_TEXT.numberOrder]: "",
    [PLAIN_TEXT.employeeFullName]: "",
    [PLAIN_TEXT.employeeBirthday]: "",
    [PLAIN_TEXT.isChangeWorkingLocation]: PLAIN_TEXT.noChange,
    [PLAIN_TEXT.isChangeWorkingDetail]: PLAIN_TEXT.noChange,
    [PLAIN_TEXT.isChangeDispatchPlace]: PLAIN_TEXT.noChange,
    [PLAIN_TEXT.workingDay]: "0",
    [PLAIN_TEXT.totalAmountPaid]: "0",
    [PLAIN_TEXT.netAmountPaid]: "0",
    [PLAIN_TEXT.legalDeductionAmount]: "0",
    [PLAIN_TEXT.hasComparisonEmployee]: PLAIN_TEXT.yes,
  };
  const employeesFormatter = employees.flatMap((employee, index) => {
    return Array(3)
      .fill(null)
      .map((_, i) => ({
        no: index + 1,
        birthday: dayjs(employee.birthday).format(DATE_FORMAT),
        fullName: getFullName(pick(employee, "firstName", "lastName", "middleName")),
        month: quarter * 3 - 2 + i,
      }));
  });

  const csvData = employeesFormatter.map((employee) => {
    const data = newRef(initData);
    data[PLAIN_TEXT.numberOrder] = employee.no.toString();
    data[PLAIN_TEXT.employeeFullName] = employee.fullName;
    data[PLAIN_TEXT.employeeBirthday] = employee.birthday ?? "";
    data[PLAIN_TEXT.month] = employee.month.toString();
    return data;
  });
  downloadJSONByCSV(csvData, `paycheck-${dayjs().valueOf()}.csv`, "utf-8", { excelBOM: true });
};
