import { useEnum } from "@/api/services/global/enum";
import { useGetMainIndustryList } from "@/api/services/global/master";
import { fetchEmployeeInfoApi } from "@/api/services/main/employee";
import { Button } from "@/components/elements/Button";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { RadioGroup } from "@/components/elements/RadioGroup";
import { Select } from "@/components/elements/Select";
import { TCompanyApplicationForm } from "@/features/renew-visa/types/companyApplicationForm";
import { text } from "@/theme/colors";
import { YES_NO_ANSWER } from "@/types/enum";
import { showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionDetails, AccordionSummary, Divider, Grid, Accordion as MuiAccordion, styled, Typography } from "@mui/material";
import { FC, useRef } from "react";
import { useFormContext } from "react-hook-form";

type TCompanyInfoFormProps = {
  readonly?: boolean;
};

export const CompanyInfoForm: FC<TCompanyInfoFormProps> = ({ readonly }) => {
  const methods = useFormContext<TCompanyApplicationForm>();
  const { control, setValue } = methods;

  const { isLoading: isLoadingMainIndustry, mainIndustryOptions } = useGetMainIndustryList();

  const { data: yesNoAnswerOptions } = useEnum("EYesNoAnswer");
  const employmentInsuranceNumberRef = useRef(methods.getValues("employmentInsuranceNumber"));

  const refreshCompanyInfo = async () => {
    const employeeId = methods.getValues("employeeId");
    try {
      const rs = await fetchEmployeeInfoApi(employeeId);
      const { company: companyData, contractCompanyBranch } = rs.data;
      if (!companyData) return;
      setValue("companyName", companyData.companyName, { shouldDirty: true });
      setValue("companyIdentificationNumber", companyData.companyIdentificationNumber ?? null, { shouldDirty: true });
      setValue("businessInsuranceNumber", contractCompanyBranch?.businessInsuranceNumber ?? companyData.businessInsuranceNumber ?? null, {
        shouldDirty: true,
      });
      setValue("mainIndustryId", companyData.mainIndustryId?.toString() ?? null, { shouldDirty: true });
      setValue("province", companyData.province ?? null, { shouldDirty: true });
      setValue("municipality", companyData.municipality ?? null, { shouldDirty: true });
      setValue("address", companyData.address ?? null, { shouldDirty: true });
      setValue("capital", companyData.capital ?? null, { shouldDirty: true });
      setValue("annualRevenue", Math.floor(toNumber(companyData.annualRevenue) / 10000), { shouldDirty: true });
      setValue("employeeCount", companyData.employeeCount ?? null, { shouldDirty: true });
      setValue("representativeName", companyData.representativeName ?? null, { shouldDirty: true });
      setValue("branchName", contractCompanyBranch?.branchName ?? null, { shouldDirty: true });
      setValue("branchProvince", contractCompanyBranch?.province ?? "", { shouldDirty: true });
      setValue("branchMunicipality", contractCompanyBranch?.municipality ?? "", { shouldDirty: true });
      setValue("branchAddress", contractCompanyBranch?.address ?? "", { shouldDirty: true });
      setValue("branchTelNumber", contractCompanyBranch?.telNumber ?? null, { shouldDirty: true });
      setValue("employmentInsuranceNumber", contractCompanyBranch?.employmentInsuranceNumber ?? "", { shouldDirty: true });
    } catch (error) {
      showError(error);
    }
  };

  const getEmploymentInsuranceNumber = async () => {
    if (employmentInsuranceNumberRef.current) {
      setValue("employmentInsuranceNumber", employmentInsuranceNumberRef.current, { shouldDirty: true });
      return;
    }

    const employeeId = methods.getValues("employeeId");
    try {
      const rs = await fetchEmployeeInfoApi(employeeId);
      const { contractCompanyBranch } = rs.data;
      setValue("employmentInsuranceNumber", contractCompanyBranch?.employmentInsuranceNumber ?? null, { shouldDirty: true });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <InnerBox
      title="特定技能所属機関"
      bordered
      p={3}
      required
      rightContent={
        readonly ? undefined : (
          <Button size="sm" onClick={refreshCompanyInfo}>
            企業・事業所情報を再取得する
          </Button>
        )
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormField control={control} name="companyName">
            <Input readonly />
          </FormField>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <FormField control={control} name="companyIdentificationNumber">
            <Input readonly />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="businessInsuranceNumber">
            <Input readonly />
          </FormField>
        </Grid>
        <Grid item xs={12}>
          <MuiAccordionWrapper sx={{ boxShadow: "none", width: "100%" }}>
            <AccordionSummaryWrapper
              sx={{ p: 0, justifyContent: "center", alignItems: "center", width: "160px", marginLeft: "auto" }}
              expandIcon={<ExpandMoreIcon sx={{ color: text.primary, width: "40px", height: "40px" }} />}
            >
              省略項目を表示
            </AccordionSummaryWrapper>
            <AccordionDetails>
              <Divider sx={{ mb: 5 }} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormField control={control} name="mainIndustryId">
                    <Select loading={isLoadingMainIndustry} options={mainIndustryOptions} readonly />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="mainIndustryOther">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="province">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="municipality">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="address">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="telNumber">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6}>
                  <FormField control={control} name="capital">
                    <Input endAdornment="円" readonly clearable={false} />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="annualRevenue">
                    <Input readonly endAdornment="万円" clearable={false} />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="employeeCount">
                    <Input endAdornment="人" readonly clearable={false} />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="representativeName">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <Typography component="h2" variant="sub16Bold" mb={2}>
                    勤務させる事業所情報
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="branchName">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="branchProvince">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="branchMunicipality">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="branchAddress">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="branchTelNumber">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="companyHasComplianceWithHealthAndWelfarePensionInsurance">
                    <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="complianceWithWorkersCompensationAndEmploymentInsurance">
                    <RadioGroup
                      direction="row"
                      options={yesNoAnswerOptions}
                      disabled={readonly}
                      onChange={(value) => {
                        if (value !== YES_NO_ANSWER.YES) {
                          methods.setValue("employmentInsuranceNumber", "");
                        } else {
                          getEmploymentInsuranceNumber();
                        }
                      }}
                    />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="employmentInsuranceNumber">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6} />
              </Grid>
            </AccordionDetails>
          </MuiAccordionWrapper>
        </Grid>
      </Grid>
    </InnerBox>
  );
};

const MuiAccordionWrapper = styled(MuiAccordion)`
  .Mui-expanded {
    min-height: 0;
  }
  &::before {
    display: none;
  }
  & .MuiAccordionDetails-root {
    padding: 0;
  }
`;

const AccordionSummaryWrapper = styled(AccordionSummary)`
  & .MuiAccordionSummary-content {
    margin: 0;
    justify-content: end;
  }
  & .Mui-expanded {
    margin: 0 !important;
  }
`;
