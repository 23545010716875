import {
  fetchRenewVisaBasicInformationDocumentApi,
  fetchRenewVisaDocumentInfoApi,
  fetchRenewVisaUploadFileDocumentApi,
} from "@/api/services/main/renew-visa";
import { notoSansJP } from "@/assets/fonts/notoSansJp";
import { TRenewVisaUploadFileForm } from "@/features/renew-visa/types/renewVisaUploadFileForm";
import { mergePdfs } from "@/utils/file";
import { PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { getFullName } from "@/utils/string";
import { TGetEmployeeOutputDto } from "@/api/services/main/employee/dtos/get-employee.dto";
import { generateFileFromFilePath } from "@/utils/export";
import { COMPANY_DIVISION, DOCUMENT_STEP_KEYS } from "@/types/enum";
import { TCompany } from "@/api/entities/company.entity";

export type TMergeRenewVisaFile = {
  order: number;
  key: keyof TRenewVisaUploadFileForm;
  filePath?: string | string[] | null;
  existed?: boolean | null;
};

type TCheckFileField = {
  key: keyof (TRenewVisaUploadFileForm & { unknown?: string | null });
  fixedValue?: "yes" | "no";
  companySpecificIndustryId?: number;
  page: number;
  yes: { x: number; y: number; size: number };
  no: { x: number; y: number; size: number };
  children?: (keyof (TRenewVisaUploadFileForm & { unknown?: string | null }))[];
};

type TCheckFileFieldResult = TCheckFileField & { value: "yes" | "no" };

type TTableKey = "table_1" | "table_2_1" | "table_2_2" | "table_2_3" | "table_3_1" | "table_3_2" | "table_3_3";

export const useMergeRenewVisaFile = (renewVisaDocumentId: number) => {
  const handleGetFilesGroupByTable = async (uploadFileFormData?: TRenewVisaUploadFileForm) => {
    const renewVisaDocument = await fetchRenewVisaDocumentInfoApi(renewVisaDocumentId).then((res) => res.data);
    const employee = renewVisaDocument.employee;
    const company = renewVisaDocument.employee.company;
    const step2 = renewVisaDocument.steps.find((step) => step.masterDocumentStep.stepKey === DOCUMENT_STEP_KEYS.RENEW_VISA_CREATE_DOCUMENT)!;
    const uploadFileData = uploadFileFormData ?? (await fetchRenewVisaUploadFileDocumentApi(renewVisaDocumentId, step2.id).then((res) => res.data));
    const step1 = renewVisaDocument.steps.find((step) => step.masterDocumentStep.stepKey === DOCUMENT_STEP_KEYS.RENEW_VISA_BASIC_INFORMATION)!;
    const basicInfoData = await fetchRenewVisaBasicInformationDocumentApi(renewVisaDocumentId, step1.id).then((res) => res.data);

    const foreignWorkerCompensationStatementFile = uploadFileData?.foreignWorkerCompensationStatementFilePaths;

    const employmentContractCopyFile = uploadFileData?.employmentContractCopyFilePath;

    const employmentConditionsCopyFile = uploadFileData?.employmentConditionsCopyFilePaths;

    const wagePaymentFile = uploadFileData?.wagePaymentFilePath;

    const applicantIndividualResidentTaxCertFile = uploadFileData?.applicantIndividualResidentTaxCertFilePath;

    const applicantResidentTaxPaymentCertFile = uploadFileData?.applicantResidentTaxPaymentCertFilePath;

    const applicantWithholdingSlipCopyFile = uploadFileData?.applicantWithholdingSlipCopyFilePath;

    const applicantHealthInsuranceCardCopyFile = uploadFileData?.applicantHealthInsuranceCardCopyFilePath;

    const applicantHealthInsurancePaymentCertFile = uploadFileData?.applicantHealthInsurancePaymentCertFilePath;

    const applicantPensionReceiptCopy1File = uploadFileData?.applicantPensionReceiptCopy1FilePath;

    const applicantPensionRecordInquiry2File = uploadFileData?.applicantPensionRecordInquiry2FilePath;

    const previousApplicationDocumentsFile = uploadFileData?.previousApplicationDocumentsFilePath;

    const publicDutyPledgeFile = uploadFileData?.publicDutyPledgeFilePath;

    const publicDutyComplianceFilePath = uploadFileData?.publicDutyComplianceFilePath;

    const quarterlyReportCopyFile = uploadFileData?.quarterlyReportCopyFilePath;

    const innovationCompanyProofFile = uploadFileData?.innovationCompanyProofFilePath;

    const certifiedCompanyProofFile = uploadFileData?.certifiedCompanyProofFilePath;

    const annualSalaryReportCopyFile = uploadFileData?.annualSalaryReportCopyFilePath;

    const immigrationElectronicSystemPledgeFile = uploadFileData?.immigrationElectronicSystemPledgeFilePath;

    const documentOmissionPledgeFile = uploadFileData?.documentOmissionPledgeFilePath;

    const specificSkillsInstitutionOverviewDocumentFile = uploadFileData?.specificSkillsInstitutionOverviewDocumentFilePath;

    const registrationCertFile = uploadFileData?.registrationCertFilePath;

    const executiveResidentRecordCopyFile = uploadFileData?.executiveResidentRecordCopyFilePath;

    const specificSkillsInstitutionOfficerAffidavitFile = uploadFileData?.specificSkillsInstitutionOfficerAffidavitFilePath;

    const laborInsuranceNoDelegationFile = uploadFileData?.laborInsuranceNoDelegationFilePath;

    const laborInsuranceDelegationFile = uploadFileData?.laborInsuranceDelegationFilePath;

    const socialInsurancePaymentStatusCertFile = uploadFileData?.socialInsurancePaymentStatusCertFilePath;

    const taxOfficePaymentCertNo3File = uploadFileData?.taxOfficePaymentCertNo3FilePath;

    const corporateResidentTaxPaymentCertFile = uploadFileData?.corporateResidentTaxPaymentCertFilePath;

    const individualBusinessOwnerResidentCertFile = uploadFileData?.individualBusinessOwnerResidentCertFilePath;

    const healthInsuranceWelfarePensionCertFile = uploadFileData?.healthInsuranceWelfarePensionCertFilePath;

    const nonHealthInsuranceWelfarePensionCertFile = uploadFileData?.nonHealthInsuranceWelfarePensionCertFilePath;

    const individualHealthInsurancePaymentCertFile = uploadFileData?.individualHealthInsurancePaymentCertFilePath;

    const individualPensionReceiptCopyFile = uploadFileData?.individualPensionReceiptCopyFilePath;

    const individualBusinessOwnerTaxOfficePaymentCertNo3File = uploadFileData?.individualBusinessOwnerTaxOfficePaymentCertNo3FilePath;

    const individualResidentTaxCertFile = uploadFileData?.individualResidentTaxCertFilePath;

    const careWorkplaceOverviewFile = uploadFileData?.careWorkplaceOverviewFilePath;

    const councilMembershipCertFile = uploadFileData?.councilMembershipCertFilePath;

    const hotelBusinessLicenseFile = uploadFileData?.hotelBusinessLicenseFilePath;

    const specificSkillOrganizationOverviewFile = uploadFileData?.specificSkillOrganizationOverviewFilePath;

    const buildingEnvHealthMgmtRegistrationCertFile = uploadFileData?.buildingEnvHealthMgmtRegistrationCertFilePath;

    const shipbuildingIndustryConfirmationFile = uploadFileData?.shipbuildingIndustryConfirmationFilePath;

    const businessLicenseOrNotificationFormFile = uploadFileData?.businessLicenseOrNotificationFormFilePath;

    const specifiedSkillAutomobileMaintenanceCertFile = uploadFileData?.specifiedSkillAutomobileMaintenanceCertFilePath;

    const specifiedSkillAutomobileMaintenanceIssuranceFile = uploadFileData?.specifiedSkillAutomobileMaintenanceIssuranceFilePath;

    const fishingLicenseCopy1File = uploadFileData?.fishingLicenseCopy1FilePath;

    const fishingLicenseCopy2File = uploadFileData?.fishingLicenseCopy2FilePath;

    const fishingLicenseCopy3File = uploadFileData?.fishingLicenseCopy3FilePath;

    const fishingCooperativeRightsDocumentFile = uploadFileData?.fishingCooperativeRightsDocumentFilePath;

    const fishingCooperativeMembershipDocumentFile = uploadFileData?.fishingCooperativeMembershipDocumentFilePath;

    const fishingBoatRegistryCopyFile = uploadFileData?.fishingBoatRegistryCopyFilePath;

    const fishingBoatRegistrationCertCopyFile = uploadFileData?.fishingBoatRegistrationCertCopyFilePath;

    const residenceApplicationsForEmployeeTable2And2File = uploadFileData?.residenceApplicationsForEmployeeTable2And2FilePath;

    const residenceApplicationsForEmployeeTable2And3File = uploadFileData?.residenceApplicationsForEmployeeTable2And3FilePath;

    const table1Files: TMergeRenewVisaFile[] = [
      // 特定技能外国人の在留諸申請に係る提出書類一覧表(第１表)
      // order: 1,

      // 在留期間更新許可申請書
      // {
      //   order: 2,
      //   key: "unknown",
      // },

      // 特定技能外国人の報酬に関する説明書
      {
        order: 3,
        key: "foreignWorkerCompensationStatementFilePaths",
        filePath: foreignWorkerCompensationStatementFile,
      },

      // 特定技能雇用契約書の写し
      {
        order: 4,
        key: "employmentContractCopyFilePath",
        filePath: employmentContractCopyFile,
        existed: uploadFileData?.isExistEmploymentContractCopyFilePath,
      },

      // 雇用条件書の写し
      {
        order: 5,
        key: "employmentConditionsCopyFilePaths",
        filePath: employmentConditionsCopyFile,
        existed: uploadFileData?.isExistEmploymentConditionsCopyFilePaths,
      },

      // 金の支払
      {
        order: 6,
        key: "wagePaymentFilePath",
        filePath: wagePaymentFile,
        existed: uploadFileData?.isExistWagePaymentFilePath,
      },

      // 申請人の個人住民税の課税証明書
      {
        order: 7,
        key: "applicantIndividualResidentTaxCertFilePath",
        filePath: applicantIndividualResidentTaxCertFile,
      },

      // 申請人の住民税の納税証明書
      {
        order: 8,
        key: "applicantResidentTaxPaymentCertFilePath",
        filePath: applicantResidentTaxPaymentCertFile,
      },

      // 申請人の給与所得の源泉徴収票の写し
      {
        order: 9,
        key: "applicantWithholdingSlipCopyFilePath",
        filePath: applicantWithholdingSlipCopyFile,
      },

      // 申請人の国民健康保険被保険者証の写し
      {
        order: 10,
        key: "applicantHealthInsuranceCardCopyFilePath",
        filePath: applicantHealthInsuranceCardCopyFile,
      },

      // 申請人の国民健康保険料(税)納付証明書
      {
        order: 11,
        key: "applicantHealthInsurancePaymentCertFilePath",
        filePath: applicantHealthInsurancePaymentCertFile,
      },

      // ①申請人の国民年金保険料領収証書の写し
      {
        order: 12,
        key: "applicantPensionReceiptCopy1FilePath",
        filePath: applicantPensionReceiptCopy1File,
      },

      // ②申請人の被保険者記録照会(納付Ⅱ)(被保険者記録照会回答票を含む。)
      {
        order: 13,
        key: "applicantPensionRecordInquiry2FilePath",
        filePath: applicantPensionRecordInquiry2File,
      },

      // 前回申請時に履行すべきであった公的義務に係る書類
      {
        order: 14,
        key: "previousApplicationDocumentsFilePath",
        filePath: previousApplicationDocumentsFile,
      },

      // 公的義務履行に関する誓約書
      {
        order: 15,
        key: "publicDutyPledgeFilePath",
        filePath: publicDutyPledgeFile,
      },
    ];

    const table2And1Files: TMergeRenewVisaFile[] = [
      // 特定技能外国人の在留諸申請に係る提出書類一覧表(第2表の１)
      // order: 16,

      // 四季報の写し又は日本の証券取引所に上場していることを証明する文書（写し）
      {
        order: 17,
        key: "quarterlyReportCopyFilePath",
        filePath: quarterlyReportCopyFile,
      },

      // 高度専門職省令第１条第１項各号の表の特別加算の項の中欄イ又はロの対象企業（イノベーション創出企業）であることを証明する文書
      {
        order: 18,
        key: "innovationCompanyProofFilePath",
        filePath: innovationCompanyProofFile,
      },

      // 「一定の条件を満た企業等」であることを証明する文書（例えば、認定証等の写し）
      {
        order: 19,
        key: "certifiedCompanyProofFilePath",
        filePath: certifiedCompanyProofFile,
      },

      // 前年分の職員の給与所得の源泉徴収票等の法定調書合計表（受付印のあるものの写し）
      {
        order: 20,
        key: "annualSalaryReportCopyFilePath",
        filePath: annualSalaryReportCopyFile,
      },

      // 出入国在留管理庁電子届出システムに関する誓約書
      {
        order: 21,
        key: "immigrationElectronicSystemPledgeFilePath",
        filePath: immigrationElectronicSystemPledgeFile,
      },

      // 書類の省略に当たっての誓約書
      {
        order: 22,
        key: "documentOmissionPledgeFilePath",
        filePath: documentOmissionPledgeFile,
      },
    ];

    const table2And2Files: TMergeRenewVisaFile[] = [
      // 特定技能外国人の在留諸申請に係る提出書類一覧表(第2表の2)
      // order: 23,

      // 特定技能所属機関概要書
      {
        order: 24,
        key: "specificSkillsInstitutionOverviewDocumentFilePath",
        filePath: specificSkillsInstitutionOverviewDocumentFile,
      },

      // 登記事項証明書
      {
        order: 25,
        key: "registrationCertFilePath",
        filePath: registrationCertFile,
      },

      // 業務執行に関与する役員の住民票の写し
      {
        order: 26,
        key: "executiveResidentRecordCopyFilePath",
        filePath: executiveResidentRecordCopyFile,
      },

      // 特定技能所属機関の役員に関する誓約書
      {
        order: 27,
        key: "specificSkillsInstitutionOfficerAffidavitFilePath",
        filePath: specificSkillsInstitutionOfficerAffidavitFile,
      },

      // 労働保険事務組合に事務委託していない場合
      {
        order: 28,
        key: "laborInsuranceNoDelegationFilePath",
        filePath: laborInsuranceNoDelegationFile,
      },

      // 労働保険事務組合に事務委託している場合
      {
        order: 29,
        key: "laborInsuranceDelegationFilePath",
        filePath: laborInsuranceDelegationFile,
      },

      // 社会保険料納入状況回答票又は健康保険・厚生年金保険料領収証書の写し
      {
        order: 30,
        key: "socialInsurancePaymentStatusCertFilePath",
        filePath: socialInsurancePaymentStatusCertFile,
      },

      // 税務署発行の納税証明書(その３)
      {
        order: 31,
        key: "taxOfficePaymentCertNo3FilePath",
        filePath: taxOfficePaymentCertNo3File,
      },

      // 法人住民税の市町村発行の納税証明書
      {
        order: 32,
        key: "corporateResidentTaxPaymentCertFilePath",
        filePath: corporateResidentTaxPaymentCertFile,
      },

      // 公的義務履行に関する説明書
      {
        order: 33,
        key: "publicDutyComplianceFilePath",
        filePath: publicDutyComplianceFilePath,
      },
    ];

    const table2And3Files: TMergeRenewVisaFile[] = [
      // 特定技能外国人の在留諸申請に係る提出書類一覧表(第2表の3)
      // order: 34,

      // 特定技能所属機関概要書
      {
        order: 35,
        key: "specificSkillsInstitutionOverviewDocumentFilePath",
        filePath: specificSkillsInstitutionOverviewDocumentFile,
      },

      // 個人事業主の住民票の写し
      {
        order: 36,
        key: "individualBusinessOwnerResidentCertFilePath",
        filePath: individualBusinessOwnerResidentCertFile,
      },

      // 労働保険事務組合に事務委託していない場合
      {
        order: 37,
        key: "laborInsuranceNoDelegationFilePath",
        filePath: laborInsuranceNoDelegationFile,
      },

      // 労働保険事務組合に事務委託している場合
      {
        order: 38,
        key: "laborInsuranceDelegationFilePath",
        filePath: laborInsuranceDelegationFile,
      },

      // 健康保険・厚生年金保険の適用事業所の場合
      {
        order: 39,
        key: "healthInsuranceWelfarePensionCertFilePath",
        filePath: healthInsuranceWelfarePensionCertFile,
      },

      // 健康保険・厚生年金保険の適用事業所でない場合
      {
        order: 40,
        key: "nonHealthInsuranceWelfarePensionCertFilePath",
        filePath: nonHealthInsuranceWelfarePensionCertFile,
      },

      // 個人事業主の国民健康保険料(税)納付証明書
      {
        order: 41,
        key: "individualHealthInsurancePaymentCertFilePath",
        filePath: individualHealthInsurancePaymentCertFile,
      },

      // 個人事業主の国民年金保険料領収証書の写し又は被保険者記録照会(納付Ⅱ)
      {
        order: 42,
        key: "individualPensionReceiptCopyFilePath",
        filePath: individualPensionReceiptCopyFile,
      },

      // 個人事業主の税務署発行の納税証明書(その３)
      {
        order: 43,
        key: "individualBusinessOwnerTaxOfficePaymentCertNo3FilePath",
        filePath: individualBusinessOwnerTaxOfficePaymentCertNo3File,
      },

      // 個人事業主の個人住民税の市町村発行の納税証明書
      {
        order: 44,
        key: "individualResidentTaxCertFilePath",
        filePath: individualResidentTaxCertFile,
      },

      // 公的義務履行に関する説明書
      {
        order: 45,
        key: "publicDutyComplianceFilePath",
        filePath: publicDutyComplianceFilePath,
      },
    ];

    const table3And1Files: TMergeRenewVisaFile[] = [
      // 特定技能外国人の在留諸申請に係る提出書類一覧表(第3表の1)
      // order: 46,

      // 介護分野における業務を行わせる事業所の概要書
      {
        order: 47,
        key: "careWorkplaceOverviewFilePath",
        filePath: careWorkplaceOverviewFile,
      },

      // 協議会の構成員であることの証明書
      {
        order: 48,
        key: "councilMembershipCertFilePath",
        filePath: councilMembershipCertFile,
      },

      // ①建築物清掃業登録証明書
      {
        order: 49,
        key: "specificSkillOrganizationOverviewFilePath",
        filePath: specificSkillOrganizationOverviewFile,
      },

      // ②建築物環境衛生総合管理業登録証明書
      {
        order: 49,
        key: "buildingEnvHealthMgmtRegistrationCertFilePath",
        filePath: buildingEnvHealthMgmtRegistrationCertFile,
      },

      // 協議会の構成員であることの証明書
      {
        order: 50,
        key: "councilMembershipCertFilePath",
        filePath: councilMembershipCertFile,
      },

      // 協議会の構成員であることの証明書
      {
        order: 52,
        key: "councilMembershipCertFilePath",
        filePath: councilMembershipCertFile,
      },

      // 造船・舶用工業事業者の確認通知書
      {
        order: 53,
        key: "shipbuildingIndustryConfirmationFilePath",
        filePath: shipbuildingIndustryConfirmationFile,
      },

      // 協議会の構成員であることの証明書
      {
        order: 54,
        key: "councilMembershipCertFilePath",
        filePath: councilMembershipCertFile,
      },

      // 造船・舶用工業分野における特定技能外国人の受入れに関する誓約書(登録支援機関)
      // {
      //   key: "rsoForeignWorkerAcceptancePledgeFilePath",
      //   order: 55,
      //   filePath: rsoForeignWorkerAcceptancePledgeFile,
      // },

      // 協議会の構成員であることの証明書(登録支援機関)
      // {
      //   key: "rsoCouncilMembershipCertFilePath",
      //   order: 56,
      //   filePath: rsoCouncilMembershipCertFile,
      // },

      // ①自動車整備分野特定技能協議会入会届出書兼構成員資格証明書（受付印があるもの）
      {
        order: 57,
        key: "specifiedSkillAutomobileMaintenanceCertFilePath",
        filePath: specifiedSkillAutomobileMaintenanceCertFile,
      },

      // ②自動車整備分野特定技能協議会構成員資格証明書発行申請書（受付印があるもの）
      {
        order: 58,
        key: "specifiedSkillAutomobileMaintenanceIssuranceFilePath",
        filePath: specifiedSkillAutomobileMaintenanceIssuranceFile,
      },

      // 自動車整備分野における特定技能外国人の受入れに関する誓約書(登録支援機関)
      // {
      //   order: 59,
      //   key: "rsoForeignWorkerAcceptancePledgeFilePath",
      //   filePath: rsoForeignWorkerAcceptancePledgeFile,
      // },

      // ①自動車整備分野特定技能協議会入会届出書兼構成員資格証明書（受付印があるもの）= 協議会の構成員であることの証明書(登録支援機関)
      // {
      //   order: 60,
      //   key: "rsoCouncilMembershipCertFilePath",
      //   filePath: rsoCouncilMembershipCertFile,
      // },

      // ②自動車整備分野特定技能協議会構成員資格証明書発行申請書（受付印があるもの）= 協議会の構成員であることの証明書(登録支援機関)
      // {
      //   order: 61,
      //   key: "rsoCouncilMembershipCertFilePath",
      //   filePath: rsoCouncilMembershipCertFile,
      // },

      // ①自動車整備士技能検定１級又は２級の合格証の写し
      // {
      //   order: 62,
      //   key: "automobileCertLevel1Or2CopyFilePath",
      //   filePath: automobileCertLevel1Or2CopyFile,
      // },

      // ②実務経験証明書 (注)支援責任者、支援担当者などの外国人の支援を行う者
      // {
      //   order: 63,
      //   key: "automobileCertLevel1Or2CopyFilePath", // same file with ①自動車整備士技能検定１級又は２級の合格証の写し
      //   filePath: automobileCertLevel1Or2CopyFile,
      // },

      // 協議会の構成員であることの証明書(特定技能所属機関)
      {
        order: 64,
        key: "councilMembershipCertFilePath",
        filePath: councilMembershipCertFile,
      },

      // 航空分野における特定技能外国人の受入れに関する誓約書(登録支援機関)
      // {
      //   key: "rsoForeignWorkerAcceptancePledgeFilePath",
      //   order: 65,
      //   filePath: rsoForeignWorkerAcceptancePledgeFile,
      // },

      // 協議会の構成員であることの証明書(登録支援機関)
      // {
      //   key: "rsoCouncilMembershipCertFilePath",
      //   order: 66,
      //   filePath: rsoCouncilMembershipCertFile,
      // },

      // 旅館業許可証(旅館・ホテル営業許可書)の写し
      {
        order: 67,
        key: "hotelBusinessLicenseFilePath",
        filePath: hotelBusinessLicenseFile,
      },

      // 協議会の構成員であることの証明書(特定技能所属機関)
      {
        order: 68,
        key: "councilMembershipCertFilePath",
        filePath: councilMembershipCertFile,
      },

      // 宿泊分野における特定技能外国人の受入れに関する誓約書(登録支援機関)
      // {
      //   key: "rsoForeignWorkerAcceptancePledgeFilePath",
      //   order: 69,
      //   filePath: rsoForeignWorkerAcceptancePledgeFile,
      // },

      // 協議会の構成員であることの証明書(登録支援機関)
      // {
      //   key: "rsoCouncilMembershipCertFilePath",
      //   order: 70,
      //   filePath: rsoCouncilMembershipCertFile,
      // },

      // 協議会の構成員であることの証明書(特定技能所属機関)
      {
        order: 71,
        key: "councilMembershipCertFilePath",
        filePath: councilMembershipCertFile,
      },

      // 飲食料品製造業分野における特定技能外国人の受入れに関する誓約書(登録支援機関)
      // {
      //   key: "rsoForeignWorkerAcceptancePledgeFilePath",
      //   order: 72,
      //   filePath: rsoForeignWorkerAcceptancePledgeFile,
      // },

      // 協議会の構成員であることの証明書(登録支援機関)
      // {
      //   key: "rsoCouncilMembershipCertFilePath",
      //   order: 73,
      //   filePath: rsoCouncilMembershipCertFile,
      // },

      // 保健所長の営業許可証又は届出書の写し
      {
        order: 74,
        key: "businessLicenseOrNotificationFormFilePath",
        filePath: businessLicenseOrNotificationFormFile,
      },

      // 協議会の構成員であることの証明書
      {
        order: 75,
        key: "councilMembershipCertFilePath",
        filePath: councilMembershipCertFile,
      },

      // 外食業分野における特定技能外国人の受入れに関する誓約書(登録支援機関)
      // {
      //   key: "rsoForeignWorkerAcceptancePledgeFilePath",
      //   order: 76,
      //   filePath: rsoForeignWorkerAcceptancePledgeFile,
      // },

      // 協議会の構成員であることの証明書(登録支援機関)
      // {
      //   key: "rsoCouncilMembershipCertFilePath",
      //   order: 77,
      //   filePath: rsoCouncilMembershipCertFile,
      // },
    ];

    const table3And2Files: TMergeRenewVisaFile[] = [
      // 特定技能外国人の在留諸申請に係る提出書類一覧表(第3表の2)
      // order: 78,

      // 協議会の構成員であることの証明書(特定技能所属機関)
      {
        order: 79,
        key: "councilMembershipCertFilePath",
        filePath: councilMembershipCertFile,
      },

      // 農業分野における特定技能外国人の受入れに関する誓約書(登録支援機関)
      // {
      //   order: 80,
      //   key: "rsoForeignWorkerAcceptancePledgeFilePath",
      //   filePath: rsoForeignWorkerAcceptancePledgeFile,
      // },
    ];

    const table3And3Files: TMergeRenewVisaFile[] = [
      // 特定技能外国人の在留諸申請に係る提出書類一覧表(第3表の3)
      // order: 81,

      // ①許可証の写し
      {
        order: 82,
        key: "fishingLicenseCopy1FilePath",
        filePath: fishingLicenseCopy1File,
      },

      // ②免許の指令書の写し
      {
        order: 83,
        key: "fishingLicenseCopy2FilePath",
        filePath: fishingLicenseCopy2File,
      },

      // ③その他許可または免許を受け漁業又は養殖業を営んでいることが確認できる公的な書類の写し
      {
        order: 84,
        key: "fishingLicenseCopy3FilePath",
        filePath: fishingLicenseCopy3File,
      },

      // ①当該組合の漁業権の内容たる漁業又は養殖業を営むことを確認できる当該組合が発行した書類の写し
      {
        order: 85,
        key: "fishingCooperativeRightsDocumentFilePath",
        filePath: fishingCooperativeRightsDocumentFile,
      },

      // ②その他当該組合に所属して漁業又は養殖業を営んでいることが確認できる書類の写し
      {
        order: 86,
        key: "fishingCooperativeMembershipDocumentFilePath",
        filePath: fishingCooperativeMembershipDocumentFile,
      },

      // ①漁船原簿謄本の写し
      {
        order: 87,
        key: "fishingBoatRegistryCopyFilePath",
        filePath: fishingBoatRegistryCopyFile,
      },

      // ②漁船登録票の写し
      {
        order: 88,
        key: "fishingBoatRegistrationCertCopyFilePath",
        filePath: fishingBoatRegistrationCertCopyFile,
      },

      // 協議会の構成員であることの証明書(特定技能所属機関)
      {
        order: 89,
        key: "councilMembershipCertFilePath",
        filePath: councilMembershipCertFile,
      },

      // 漁業分野における特定技能外国人の受入れに関する誓約書(登録支援機関)
      // {
      //   key: "rsoForeignWorkerAcceptancePledgeFilePath",
      //   order: 90,
      //   filePath: rsoForeignWorkerAcceptancePledgeFile,
      // },
    ];

    const { yesCount: table1YesCount } = handleCheckFileListTable(employee, company, "table_1", table1Files);
    const { yesCount: table2And1YesCount } = handleCheckFileListTable(employee, company, "table_2_1", table2And1Files);
    const { yesCount: table2And2YesCount } = handleCheckFileListTable(employee, company, "table_2_2", table2And2Files);
    const { yesCount: table2And3YesCount } = handleCheckFileListTable(employee, company, "table_2_3", table2And3Files);
    const { yesCount: table3And1YesCount } = handleCheckFileListTable(employee, company, "table_3_1", table3And1Files);
    const { yesCount: table3And2YesCount } = handleCheckFileListTable(employee, company, "table_3_2", table3And2Files);
    const { yesCount: table3And3YesCount } = handleCheckFileListTable(employee, company, "table_3_3", table3And3Files);

    const isSelectOneOfAToE = basicInfoData?.conditions.length > 0;

    return {
      table1Files,
      table2And1Files,
      table2And2Files,
      table2And2FilesUploadedByUser: residenceApplicationsForEmployeeTable2And2File,
      table2And3Files,
      table2And3FilesUploadedByUser: residenceApplicationsForEmployeeTable2And3File,
      table3And1Files,
      table3And2Files,
      table3And3Files,
      isShowTable1: table1YesCount > 0,
      isShowTable2And1: table2And1YesCount > 0,
      isShowTable2And2: isSelectOneOfAToE ? table2And2YesCount > 0 : company?.division === COMPANY_DIVISION.CORPORATE,
      isShowTable2And3: isSelectOneOfAToE ? table2And3YesCount > 0 : company?.division === COMPANY_DIVISION.SOLE_PROPRIETOR,
      isShowTable3And1: table3And1YesCount > 0,
      isShowTable3And2: table3And2YesCount > 0,
      isShowTable3And3: table3And3YesCount > 0,
    };
  };

  const handleCreateCoverPagePdf = async (company: TCompany, employee: TGetEmployeeOutputDto) => {
    const arrayBuffer = await fetch("/pdf/renew_visa_cover_page.pdf").then((res) => res.arrayBuffer());
    const bytes = new Uint8Array(arrayBuffer);
    const templatePdf = await PDFDocument.load(bytes);
    const editedPdf = await PDFDocument.create();

    // Copy pages from raw pdf into final pdf
    const copiedPages = await editedPdf.copyPages(templatePdf, templatePdf.getPageIndices());
    copiedPages.forEach((page) => editedPdf.addPage(page));

    editedPdf.registerFontkit(fontkit);
    const customFont = await editedPdf.embedFont(notoSansJP);
    const page = editedPdf.getPage(0);

    // Add employee full name
    const employeeFullName = getFullName({
      firstName: employee?.firstName || "",
      middleName: employee?.middleName,
      lastName: employee?.lastName,
    });
    page.drawText(employeeFullName, {
      x: 300,
      y: 120,
      size: 10,
      font: customFont,
    });

    // Add company name
    page.drawText(company?.companyName || "", {
      x: 300,
      y: 87,
      size: 10,
      font: customFont,
    });

    const pdfBytes = await editedPdf.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const file = new File([blob], `cover.pdf`, { type: blob.type });
    return file;
  };

  const handleCheckFileListTable = (
    employee: TGetEmployeeOutputDto,
    company: TCompany,
    tableKey: "table_1" | "table_2_1" | "table_2_2" | "table_2_3" | "table_3_1" | "table_3_2" | "table_3_3",
    files: TMergeRenewVisaFile[],
  ) => {
    files = files.sort((a, b) => a.order - b.order);
    let yesCount = 0;
    let noCount = 0;
    let templatePdfUrl: string | undefined = "";
    let fileFields: TCheckFileField[] = [];
    let divisions: string[] | undefined = undefined;

    const companySpecificIndustryId =
      employee?.contractSpecificBusinessClassificationOne?.companySpecificBusinessClassification?.companySpecificIndustryId;
    const companyDivision = company.division;

    if (employee?.residenceStatusId?.toString() === "1") {
      switch (tableKey) {
        case "table_1":
          templatePdfUrl = "/pdf/renew_visa_table_1_skill_1.pdf";
          divisions = [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR];
          fileFields = [
            {
              key: "unknown", // 特定技能外国人の在留諸申請に係る提出書類一覧表(本表)
              page: 1,
              yes: { x: 433, y: 741, size: 6 },
              no: { x: 451, y: 741, size: 6 },
              fixedValue: "yes",
            },
            {
              key: "unknown", // 在留期間更新許可申請書
              page: 1,
              yes: { x: 433, y: 690, size: 6 },
              no: { x: 451, y: 690, size: 6 },
              fixedValue: "no",
            },
            {
              key: "foreignWorkerCompensationStatementFilePaths", // 特定技能外国人の報酬に関する説明書
              page: 1,
              yes: { x: 433, y: 649, size: 6 },
              no: { x: 451, y: 649, size: 6 },
            },
            {
              key: "employmentContractCopyFilePath", // 特定技能雇用契約書の写し
              page: 1,
              yes: { x: 433, y: 619, size: 6 },
              no: { x: 451, y: 619, size: 6 },
            },
            {
              key: "employmentConditionsCopyFilePaths", // 雇用条件書の写し
              page: 1,
              yes: { x: 433, y: 572, size: 6 },
              no: { x: 451, y: 572, size: 6 },
            },
            {
              key: "wagePaymentFilePath", // 賃金の支払
              page: 1,
              yes: { x: 433, y: 524, size: 6 },
              no: { x: 451, y: 524, size: 6 },
            },
            {
              key: "applicantIndividualResidentTaxCertFilePath", // 申請人の個人住民税の課税証明書
              page: 1,
              yes: { x: 433, y: 498, size: 6 },
              no: { x: 451, y: 498, size: 6 },
            },
            {
              key: "applicantResidentTaxPaymentCertFilePath", // 申請人の住民税の納税証明書
              page: 1,
              yes: { x: 433, y: 456, size: 6 },
              no: { x: 451, y: 456, size: 6 },
            },
            {
              key: "applicantWithholdingSlipCopyFilePath", // 申請人の給与所得の源泉徴収票の写し
              page: 1,
              yes: { x: 433, y: 368, size: 6 },
              no: { x: 451, y: 368, size: 6 },
            },
            {
              key: "applicantHealthInsuranceCardCopyFilePath", // 申請人の国民健康保険被保険者証の写し
              page: 1,
              yes: { x: 433, y: 289, size: 6 },
              no: { x: 451, y: 289, size: 6 },
            },
            {
              key: "applicantHealthInsurancePaymentCertFilePath", // 申請人の国民健康保険料(税)納付証明書
              page: 1,
              yes: { x: 433, y: 248, size: 6 },
              no: { x: 451, y: 248, size: 6 },
            },
            {
              key: "applicantPensionReceiptCopy1FilePath",
              page: 1,
              yes: { x: 433, y: 167, size: 6 },
              no: { x: 451, y: 167, size: 6 },
              children: [
                "applicantPensionReceiptCopy1FilePath", // ①申請人の国民年金保険料領収証書の写し
                "applicantPensionRecordInquiry2FilePath", //②申請人の被保険者記録照会(納付Ⅱ)(被保険者記録照会回答票を含む。)
              ],
            },
            {
              key: "previousApplicationDocumentsFilePath", // 前回申請時に履行すべきであった公的義務に係る書類
              page: 1,
              yes: { x: 433, y: 83, size: 6 },
              no: { x: 451, y: 83, size: 6 },
            },
            {
              key: "publicDutyPledgeFilePath", // 公的義務履行に関する誓約書
              page: 1,
              yes: { x: 433, y: 39, size: 6 },
              no: { x: 451, y: 39, size: 6 },
            },
          ];
          break;
        case "table_2_1":
          templatePdfUrl = "/pdf/renew_visa_table_2_1_skill_1.pdf";
          divisions = [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR];
          fileFields = [
            {
              key: "quarterlyReportCopyFilePath", //四季報の写し又は日本の証券取引所に上場していることを証明する文書（写し）
              page: 1,
              yes: { x: 436.5, y: 707, size: 6 },
              no: { x: 454.5, y: 707, size: 6 },
            },
            {
              key: "innovationCompanyProofFilePath", //高度専門職省令第１条第１項各
              page: 1,
              yes: { x: 436.5, y: 642, size: 6 },
              no: { x: 454.5, y: 642, size: 6 },
            },
            {
              // key: "innovationCompanyProofFilePath", //高度専門職省令第１条第１項各
              key: "certifiedCompanyProofFilePath", //「一定の条件を満たす企業等
              page: 1,
              yes: { x: 436.5, y: 564.5, size: 6 },
              no: { x: 454.5, y: 564.5, size: 6 },
            },
            {
              key: "annualSalaryReportCopyFilePath", // 前年分の職員の給与所得の源泉
              page: 1,
              yes: { x: 436.5, y: 478.5, size: 6 },
              no: { x: 454.5, y: 478.5, size: 6 },
            },
            {
              key: "immigrationElectronicSystemPledgeFilePath", //出入国在留管理庁電子届出シス
              page: 1,
              yes: { x: 436.5, y: 381.5, size: 6 },
              no: { x: 454.5, y: 381.5, size: 6 },
            },
            {
              key: "documentOmissionPledgeFilePath", //書類の省略に当たっての誓約書
              page: 1,
              yes: { x: 436.5, y: 307, size: 6 },
              no: { x: 454.5, y: 307, size: 6 },
            },
          ];
          break;
        case "table_2_2":
          templatePdfUrl = "/pdf/renew_visa_table_2_2_skill_1.pdf";
          divisions = [COMPANY_DIVISION.CORPORATE];
          fileFields = [
            {
              key: "specificSkillsInstitutionOverviewDocumentFilePath", // 特定技能所属機関概要書
              page: 1,
              yes: { x: 436.5, y: 713.5, size: 6 },
              no: { x: 454, y: 713.5, size: 6 },
            },
            {
              key: "registrationCertFilePath", // 登記事項証明書
              page: 1,
              yes: { x: 436.5, y: 644.5, size: 6 },
              no: { x: 454, y: 644.5, size: 6 },
            },
            {
              key: "executiveResidentRecordCopyFilePath", // 業務執行に関与する役員の住民票の写し
              page: 1,
              yes: { x: 436.5, y: 589, size: 6 },
              no: { x: 454, y: 589, size: 6 },
            },
            {
              key: "specificSkillsInstitutionOfficerAffidavitFilePath", // 特定技能所属機関の役員に関する誓約書
              page: 1,
              yes: { x: 436.5, y: 533, size: 6 },
              no: { x: 454, y: 533, size: 6 },
            },
            {
              key: "laborInsuranceNoDelegationFilePath", // 労働保険事務組合に事務委託していない場合

              page: 1,
              yes: { x: 436.5, y: 448, size: 6 },
              no: { x: 454, y: 448, size: 6 },
            },
            {
              key: "laborInsuranceDelegationFilePath", // 労働保険事務組合に事務委託している場合
              page: 1,
              yes: { x: 436.5, y: 372, size: 6 },
              no: { x: 454, y: 372, size: 6 },
            },
            {
              key: "socialInsurancePaymentStatusCertFilePath", // 社会保険料納入状況回答票又は健康保険・厚生年金保険料領収証書の写し
              page: 1,
              yes: { x: 436.5, y: 302, size: 6 },
              no: { x: 454, y: 302, size: 6 },
            },
            {
              key: "taxOfficePaymentCertNo3FilePath", // 税務署発行の納税証明書(その３)
              page: 1,
              yes: { x: 436.5, y: 235.5, size: 6 },
              no: { x: 454, y: 235.5, size: 6 },
            },
            {
              key: "corporateResidentTaxPaymentCertFilePath", // 法人住民税の市町村発行の納税証明書
              page: 1,
              yes: { x: 436.5, y: 171, size: 6 },
              no: { x: 454, y: 171, size: 6 },
            },
            {
              key: "publicDutyComplianceFilePath", // 公的義務履行に関する説明書
              page: 1,
              yes: { x: 436.5, y: 118, size: 6 },
              no: { x: 454, y: 118, size: 6 },
            },
          ];
          break;
        case "table_2_3":
          templatePdfUrl = "/pdf/renew_visa_table_2_3_skill_1.pdf";
          divisions = [COMPANY_DIVISION.SOLE_PROPRIETOR];
          fileFields = [
            {
              key: "specificSkillsInstitutionOverviewDocumentFilePath", // 特定技能所属機関概要書
              page: 1,
              yes: { x: 436.5, y: 711, size: 6 },
              no: { x: 454, y: 711, size: 6 },
            },
            {
              key: "individualBusinessOwnerResidentCertFilePath", // 個人事業主の住民票の写し
              page: 1,
              yes: { x: 436.5, y: 640.5, size: 6 },
              no: { x: 454, y: 640.5, size: 6 },
            },
            {
              key: "laborInsuranceNoDelegationFilePath", // 労働保険事務組合に事務委託していない場合
              page: 1,
              yes: { x: 436.5, y: 560, size: 6 },
              no: { x: 454, y: 560, size: 6 },
            },
            {
              key: "laborInsuranceDelegationFilePath", // 労働保険事務組合に事務委託している場合
              page: 1,
              yes: { x: 436.5, y: 484, size: 6 },
              no: { x: 454, y: 484, size: 6 },
            },
            {
              key: "healthInsuranceWelfarePensionCertFilePath", // 健康保険・厚生年金保険の適用事業所の場合
              page: 1,
              yes: { x: 436.5, y: 391.5, size: 6 },
              no: { x: 454, y: 391.5, size: 6 },
            },
            {
              key: "nonHealthInsuranceWelfarePensionCertFilePath", // 健康保険・厚生年金保険の適用事業所でない場合
              page: 1,
              yes: { x: 436.5, y: 320, size: 6 },
              no: { x: 454, y: 320, size: 6 },
            },
            {
              key: "individualHealthInsurancePaymentCertFilePath", // 個人事業主の国民健康保険料(税)納付証明書
              page: 1,
              yes: { x: 436.5, y: 233.5, size: 6 },
              no: { x: 454, y: 233.5, size: 6 },
            },
            {
              key: "individualPensionReceiptCopyFilePath", // 個人事業主の国民年金保険料領収証書の写し又は被保険者記録照会(納付Ⅱ)
              page: 1,
              yes: { x: 436.5, y: 128.5, size: 6 },
              no: { x: 454, y: 128.5, size: 6 },
            },
            {
              key: "individualBusinessOwnerTaxOfficePaymentCertNo3FilePath", // 個人事業主の税務署発行の納税証明書(その３)
              page: 2,
              yes: { x: 436.5, y: 720, size: 6 },
              no: { x: 454, y: 720, size: 6 },
            },
            {
              key: "individualResidentTaxCertFilePath", // 個人事業主の個人住民税の市町村発行の納税証明書
              page: 2,
              yes: { x: 436.5, y: 654, size: 6 },
              no: { x: 454, y: 654, size: 6 },
            },
            {
              key: "publicDutyComplianceFilePath", // 公的義務履行に関する説明書
              page: 2,
              yes: { x: 436.5, y: 599, size: 6 },
              no: { x: 454, y: 599, size: 6 },
            },
          ];
          break;
        case "table_3_1":
          templatePdfUrl = "/pdf/renew_visa_table_3_1_skill_1.pdf";
          divisions = [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR];
          fileFields = [
            {
              key: "careWorkplaceOverviewFilePath", // 介護分野における業務を行わせる事業所の概要書
              page: 1,
              companySpecificIndustryId: 12, // 介護
              yes: { x: 442, y: 741.2, size: 6 },
              no: { x: 459.5, y: 741.2, size: 6 },
            },
            {
              key: "councilMembershipCertFilePath", // 協議会の構成員であることの証明書
              page: 1,
              companySpecificIndustryId: 12, // 介護
              yes: { x: 442, y: 700, size: 6 },
              no: { x: 459.5, y: 700, size: 6 },
            },
            {
              key: "specificSkillOrganizationOverviewFilePath",
              page: 1,
              companySpecificIndustryId: 11, // ビルクリーニング
              yes: { x: 442, y: 646.6, size: 6 },
              no: { x: 459.5, y: 646.6, size: 6 },
              children: [
                "specificSkillOrganizationOverviewFilePath", //  ①建築物清掃業登録証明書
                "buildingEnvHealthMgmtRegistrationCertFilePath", // ②建築物環境衛生総合管理業登録証明書
              ],
            },
            {
              key: "councilMembershipCertFilePath", // 協議会の構成員であることの証明書
              page: 1,
              companySpecificIndustryId: 11, // ビルクリーニング
              yes: { x: 442, y: 598, size: 6 },
              no: { x: 459.5, y: 598, size: 6 },
            },
            {
              key: "councilMembershipCertFilePath", // 協議会の構成員であることの証明書
              page: 1,
              companySpecificIndustryId: 10, // 工業製品製造業 = 素形材・産業機械・電気電子情報関連製造業
              yes: { x: 442, y: 536, size: 6 },
              no: { x: 459.5, y: 536, size: 6 },
            },
            {
              key: "shipbuildingIndustryConfirmationFilePath", // 造船・舶用工業事業者の確認通知書
              page: 1,
              companySpecificIndustryId: 8, // 造船・舶用工業
              yes: { x: 442, y: 476.5, size: 6 },
              no: { x: 459.5, y: 476.5, size: 6 },
            },
            {
              key: "councilMembershipCertFilePath", // 協議会の構成員であることの証明書
              page: 1,
              companySpecificIndustryId: 8, // 造船・舶用工業
              yes: { x: 442, y: 444, size: 6 },
              no: { x: 459.5, y: 444, size: 6 },
            },
            {
              // rsoForeignWorkerAcceptancePledgeFilePath
              key: "unknown", // 造船・舶用工業分野における特定技能外国人の受入れに関する誓約書(登録支援機関)
              page: 1,
              companySpecificIndustryId: 8, // 造船・舶用工業
              yes: { x: 442, y: 399, size: 6 },
              no: { x: 459.5, y: 399, size: 6 },
              fixedValue: "no",
            },
            {
              // rsoCouncilMembershipCertFilePath
              key: "unknown", // 協議会の構成員であることの証明書(登録支援機関)
              page: 1,
              companySpecificIndustryId: 8, // 造船・舶用工業
              yes: { x: 442, y: 368, size: 6 },
              no: { x: 459.5, y: 368, size: 6 },
              fixedValue: "no",
            },
            {
              key: "specifiedSkillAutomobileMaintenanceCertFilePath",
              page: 1,
              companySpecificIndustryId: 7, // 自動車整備
              yes: { x: 442, y: 315, size: 6 },
              no: { x: 459.5, y: 315, size: 6 },
              children: [
                "specifiedSkillAutomobileMaintenanceCertFilePath", //①自動車整備分野特定技能協議会入会届出書兼構成員資格証明書（受付印があるもの）
                "specifiedSkillAutomobileMaintenanceIssuranceFilePath", //②自動車整備分野特定技能協議会構成員資格証明書発行申請書（受付印があるもの）
              ],
            },
            {
              // rsoForeignWorkerAcceptancePledgeFilePath
              key: "unknown", // 自動車整備分野における特定技能外国人の受入れに関する誓約書(登録支援機関)
              page: 1,
              companySpecificIndustryId: 7, // 自動車整備
              yes: { x: 442, y: 237.5, size: 6 },
              no: { x: 459.5, y: 237.5, size: 6 },
              fixedValue: "no",
            },
            {
              /**
               * 次の①又は②のいずれか
              ①自動車整備分野特定技能協議会入会届出書兼構成員資格証明書（受付印があるもの）
              ②自動車整備分野特定技能協議会構成員資格証明書発行申請書（受付印があるもの）
              = 協議会の構成員であることの証明書(登録支援機関)
               */
              // rsoCouncilMembershipCertFilePath
              key: "unknown", // 協議会の構成員であることの証明書(登録支援機関)
              page: 1,
              companySpecificIndustryId: 7, // 自動車整備
              yes: { x: 442, y: 185, size: 6 },
              no: { x: 459.5, y: 185, size: 6 },
              fixedValue: "no",
              // children: [
              //   "specifiedSkillAutomobileMaintenanceCertFilePath", // ①自動車整備分野特定技能協議会入会届出書兼構成員資格証明書（受付印があるもの）
              //   "specifiedSkillAutomobileMaintenanceIssuranceFilePath", // ②自動車整備分野特定技能協議会構成員資格証明書発行申請書（受付印があるもの）
              // ],
            },
            {
              // rsoCouncilMembershipCertFilePath
              key: "unknown", // ①自動車整備士技能検定１級又は２級の合格証の写し or ②実務経験証明書 (注)支援責任者、支援担当者などの外国人の支援を行う者
              page: 1,
              companySpecificIndustryId: 7, // 自動車整備
              yes: { x: 442, y: 110, size: 6 },
              no: { x: 459.5, y: 110, size: 6 },
              fixedValue: "no",
            },
            // page 2
            {
              key: "councilMembershipCertFilePath", // 協議会の構成員であることの証明書(特定技能所属機関)
              page: 2,
              companySpecificIndustryId: 6, // 航空
              yes: { x: 442, y: 741, size: 6 },
              no: { x: 459.5, y: 741, size: 6 },
            },
            {
              // rsoForeignWorkerAcceptancePledgeFilePath
              key: "unknown", // 航空分野における特定技能外国人の受入れに関する誓約書(登録支援機関)
              page: 2,
              companySpecificIndustryId: 6, // 航空
              yes: { x: 442, y: 696, size: 6 },
              no: { x: 459.5, y: 696, size: 6 },
              fixedValue: "no",
            },
            {
              // rsoCouncilMembershipCertFilePath
              key: "unknown", // 協議会の構成員であることの証明書(登録支援機関)
              page: 2,
              companySpecificIndustryId: 6, // 航空
              yes: { x: 442, y: 665, size: 6 },
              no: { x: 459.5, y: 665, size: 6 },
              fixedValue: "no",
            },
            {
              key: "hotelBusinessLicenseFilePath", // 旅館業許可証(旅館・ホテル営業許可書)の写し
              page: 2,
              companySpecificIndustryId: 5, // 宿泊
              yes: { x: 442, y: 637, size: 6 },
              no: { x: 459.5, y: 637, size: 6 },
            },
            {
              key: "councilMembershipCertFilePath", // 協議会の構成員であることの証明書(特定技能所属機関)
              page: 2,
              companySpecificIndustryId: 5, // 宿泊
              yes: { x: 442, y: 604.2, size: 6 },
              no: { x: 459.5, y: 604.2, size: 6 },
            },
            {
              // rsoForeignWorkerAcceptancePledgeFilePath
              key: "unknown", // 宿泊分野における特定技能外国人の受入れに関する誓約書(登録支援機関)
              page: 2,
              companySpecificIndustryId: 5, // 宿泊
              yes: { x: 442, y: 559.7, size: 6 },
              no: { x: 459.5, y: 559.7, size: 6 },
              fixedValue: "no",
            },
            {
              // rsoCouncilMembershipCertFilePath
              key: "unknown", // 協議会の構成員であることの証明書(登録支援機関)
              page: 2,
              companySpecificIndustryId: 5, // 宿泊
              yes: { x: 442, y: 527.7, size: 6 },
              no: { x: 459.5, y: 527.7, size: 6 },
              fixedValue: "no",
            },
            {
              key: "councilMembershipCertFilePath", // 協議会の構成員であることの証明書(特定技能所属機関)
              page: 2,
              companySpecificIndustryId: -1, // (Missing master data) // 鉄道
              yes: { x: 442, y: 495.9, size: 6 },
              no: { x: 459.5, y: 495.9, size: 6 },
            },
            {
              // rsoForeignWorkerAcceptancePledgeFilePath
              key: "unknown", // 鉄道分野における特定技能外国人の受入れに関する誓約書(登録支援機関)
              page: 2,
              companySpecificIndustryId: -1, // (Missing master data) // 鉄道
              yes: { x: 442, y: 453.9, size: 6 },
              no: { x: 459.5, y: 453.9, size: 6 },
              fixedValue: "no",
            },
            {
              // rsoCouncilMembershipCertFilePath
              key: "unknown", // 協議会の構成員であることの証明書(登録支援機関)
              page: 2,
              companySpecificIndustryId: -1, // (Missing master data) // 鉄道
              yes: { x: 442, y: 422, size: 6 },
              no: { x: 459.5, y: 422, size: 6 },
              fixedValue: "no",
            },
            // page 3
            {
              key: "councilMembershipCertFilePath", // 協議会の構成員であることの証明書(特定技能所属機関)
              page: 3,
              companySpecificIndustryId: 2, // 飲食料品製造業
              yes: { x: 442, y: 741, size: 6 },
              no: { x: 459.5, y: 741, size: 6 },
            },
            {
              // rsoForeignWorkerAcceptancePledgeFilePath
              key: "unknown", // 飲食料品製造業分野における特定技能外国人の受入れに関する誓約書(登録支援機関)
              page: 3,
              companySpecificIndustryId: 2, // 飲食料品製造業
              yes: { x: 442, y: 697, size: 6 },
              no: { x: 459.5, y: 697, size: 6 },
              fixedValue: "no",
            },
            {
              // rsoCouncilMembershipCertFilePath
              key: "unknown", // 協議会の構成員であることの証明書(登録支援機関)
              page: 3,
              companySpecificIndustryId: 2, // 飲食料品製造業
              yes: { x: 442, y: 667, size: 6 },
              no: { x: 459.5, y: 667, size: 6 },
              fixedValue: "no",
            },
            {
              key: "businessLicenseOrNotificationFormFilePath", // 保健所長の営業許可証又は届出書の写し
              page: 3,
              companySpecificIndustryId: 1, // 外食業
              yes: { x: 442, y: 590, size: 6 },
              no: { x: 459.5, y: 590, size: 6 },
            },
            {
              key: "councilMembershipCertFilePath", // 協議会の構成員であることの証明書(特定技能所属機関)
              page: 3,
              companySpecificIndustryId: 1, // 外食業
              yes: { x: 442, y: 507.4, size: 6 },
              no: { x: 459.5, y: 507.4, size: 6 },
            },
            {
              // rsoForeignWorkerAcceptancePledgeFilePath
              key: "unknown", // 外食業分野における特定技能外国人の受入れに関する誓約書(登録支援機関)
              page: 3,
              companySpecificIndustryId: 1, // 外食業
              yes: { x: 442, y: 454.3, size: 6 },
              no: { x: 459.5, y: 454.3, size: 6 },
              fixedValue: "no",
            },
            {
              // rsoCouncilMembershipCertFilePath
              key: "unknown", // 協議会の構成員であることの証明書(登録支援機関)
              page: 3,
              companySpecificIndustryId: 1, // 外食業
              yes: { x: 442, y: 423.2, size: 6 },
              no: { x: 459.5, y: 423.2, size: 6 },
              fixedValue: "no",
            },
            {
              key: "councilMembershipCertFilePath", // 協議会の構成員であることの証明書
              page: 3,
              companySpecificIndustryId: -1, // (Missing master data) 林業
              yes: { x: 442, y: 393.6, size: 6 },
              no: { x: 459.5, y: 393.6, size: 6 },
            },
            {
              key: "councilMembershipCertFilePath", // 協議会の構成員であることの証明書
              page: 3,
              companySpecificIndustryId: -1, // (Missing master data) 林業
              yes: { x: 442, y: 356, size: 6 },
              no: { x: 459.5, y: 356, size: 6 },
            },
          ];
          break;
        case "table_3_2":
          templatePdfUrl = "/pdf/renew_visa_table_3_2_skill_1.pdf";
          divisions = [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR];
          fileFields = [
            {
              key: "councilMembershipCertFilePath", // 協議会の構成員であることの証明書(特定技能所属機関)
              page: 1,
              yes: { x: 437, y: 741.2, size: 6 },
              no: { x: 455, y: 741.2, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              // rsoForeignWorkerAcceptancePledgeFilePath
              key: "unknown", // 農業分野における特定技能外国人の受入れに関する誓約書(登録支援機関)
              page: 1,
              yes: { x: 437, y: 695, size: 6 },
              no: { x: 455, y: 695, size: 6 },
              companySpecificIndustryId: 4, // 農業
              fixedValue: "no",
            },
            // page 2
            {
              key: "unknown", // 農業又は農業に関連する業務を行っている場合
              page: 2,
              yes: { x: 436.5, y: 652, size: 6 },
              no: { x: 454.5, y: 652, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 地方公共団体等が出資（資本金の過半数）している機関である場合
              page: 2,
              yes: { x: 436.5, y: 569.7, size: 6 },
              no: { x: 454.5, y: 569.7, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 地方公共団体の職員等が役員として在籍している場合
              page: 2,
              yes: { x: 436.5, y: 496, size: 6 },
              no: { x: 454.5, y: 496, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 地方公共団体等が実質的に業務執行に関与している場合
              page: 2,
              yes: { x: 436.5, y: 424, size: 6 },
              no: { x: 454.5, y: 424, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 国家戦略特別区域法に規定する特定機関である場合
              page: 2,
              yes: { x: 436.5, y: 336, size: 6 },
              no: { x: 454.5, y: 336, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 農業分野において派遣形態で特定技能外国人の受入れ
              page: 2,
              yes: { x: 436.5, y: 266, size: 6 },
              no: { x: 454.5, y: 266, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 労働者派遣事業許可証の写し
              page: 2,
              yes: { x: 436.5, y: 230.2, size: 6 },
              no: { x: 454.5, y: 230.2, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 派遣計画書
              page: 2,
              yes: { x: 436.5, y: 190, size: 6 },
              no: { x: 454.5, y: 190, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 労働者派遣契約書の写し
              page: 2,
              yes: { x: 436.5, y: 151.5, size: 6 },
              no: { x: 454.5, y: 151.5, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 就業条件明示書の写し
              page: 2,
              yes: { x: 436.5, y: 114, size: 6 },
              no: { x: 454.5, y: 114, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            // page 3
            {
              key: "unknown", // 派遣先の概要書（農業分野）
              page: 3,
              yes: { x: 436.5, y: 713, size: 6 },
              no: { x: 454.5, y: 713, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 派遣先事業者誓約書
              page: 3,
              yes: { x: 436.5, y: 680, size: 6 },
              no: { x: 454.5, y: 680, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 労働保険料等納付証明書(未納なし証明)
              page: 3,
              yes: { x: 436.5, y: 610, size: 6 },
              no: { x: 454.5, y: 610, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 労働保険概算・増加概算・確定
              page: 3,
              yes: { x: 436.5, y: 554.2, size: 6 },
              no: { x: 454.5, y: 554.2, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 労働保険事務組合が発行した直
              page: 3,
              yes: { x: 436.5, y: 477.5, size: 6 },
              no: { x: 454.5, y: 477.5, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 社会保険料納入状況回答票又は健康保険・厚生年金保
              page: 3,
              yes: { x: 436.5, y: 374, size: 6 },
              no: { x: 454.5, y: 374, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 税務署発行の納税証明書(その３)
              page: 3,
              yes: { x: 436.5, y: 305.5, size: 6 },
              no: { x: 454.5, y: 305.5, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 法人住民税の市町村発行の納税証明書
              page: 3,
              yes: { x: 436.5, y: 232, size: 6 },
              no: { x: 454.5, y: 232, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 公的義務履行に関する説明書
              page: 3,
              yes: { x: 436.5, y: 189.8, size: 6 },
              no: { x: 454.5, y: 189.8, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 公的義務履行に関する説明書
              page: 3,
              yes: { x: 436.5, y: 145.5, size: 6 },
              no: { x: 454.5, y: 145.5, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            // page 4
            {
              key: "unknown", // 派遣先の概要書（農業分野）
              page: 4,
              yes: { x: 436.5, y: 714.5, size: 6 },
              no: { x: 454.5, y: 714.5, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 派遣先事業者誓約書
              page: 4,
              yes: { x: 436.5, y: 683, size: 6 },
              no: { x: 454.5, y: 683, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 労働保険料等納付証明書
              page: 4,
              yes: { x: 436.5, y: 614, size: 6 },
              no: { x: 454.5, y: 614, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 労働保険概算・増加概算・確定
              page: 4,
              yes: { x: 436.5, y: 561, size: 6 },
              no: { x: 454.5, y: 561, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 労働保険事務組合が発行した直
              page: 4,
              yes: { x: 436.5, y: 486.8, size: 6 },
              no: { x: 454.5, y: 486.8, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 社会保険料納入状況回答票又は
              page: 4,
              yes: { x: 436.5, y: 368, size: 6 },
              no: { x: 454.5, y: 368, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 個人事業主の国民健康保険被保
              page: 4,
              yes: { x: 436.5, y: 301, size: 6 },
              no: { x: 454.5, y: 301, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 個人事業主の国民健康保険料
              page: 4,
              yes: { x: 436.5, y: 219.5, size: 6 },
              no: { x: 454.5, y: 219.5, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 個人事業主の国民年金保険料領
              page: 4,
              yes: { x: 436.5, y: 122, size: 6 },
              no: { x: 454.5, y: 122, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            // page 5
            {
              key: "unknown", // 個人事業主の税務署発行の納税証明書
              page: 5,
              yes: { x: 436.5, y: 698.5, size: 6 },
              no: { x: 454.5, y: 698.5, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 個人事業主の個人住民税の市町村発行の納税証明書(注)直近１年分が必要
              page: 5,
              yes: { x: 436.5, y: 620.2, size: 6 },
              no: { x: 454.5, y: 620.2, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 個人事業主の個人住民税の市町村発行の納税証明書(注)直近２年分が必要
              page: 5,
              yes: { x: 436.5, y: 568, size: 6 },
              no: { x: 454.5, y: 568, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 公的義務履行に関する説明書
              page: 5,
              yes: { x: 436.5, y: 516.5, size: 6 },
              no: { x: 454.5, y: 516.5, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
          ];
          break;
        case "table_3_3":
          templatePdfUrl = "/pdf/renew_visa_table_3_3_skill_1_edit.pdf";
          divisions = [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR];
          fileFields = [
            {
              key: "fishingLicenseCopy1FilePath",
              page: 1,
              yes: { x: 437, y: 705.3, size: 6 },
              no: { x: 455, y: 705.3, size: 6 },
              children: [
                "fishingLicenseCopy1FilePath", // ①許可証の写し
                "fishingLicenseCopy2FilePath", // ②免許の指令書の写し
                "fishingLicenseCopy3FilePath", // ③その他許可または免許を受け
              ],
              companySpecificIndustryId: 3, // 漁業
            },
            {
              key: "fishingCooperativeRightsDocumentFilePath",
              page: 1,
              yes: { x: 437, y: 601.2, size: 6 },
              no: { x: 455, y: 601.2, size: 6 },
              children: [
                "fishingCooperativeRightsDocumentFilePath", // ①当該組合の漁業権の内容たる漁業又は養殖業を営むことを確認できる当該組合が発行した書類の写し
                "fishingCooperativeMembershipDocumentFilePath", // ②その他当該組合に所属して漁業又は養殖業を営んでいることが確認できる書類の写し
              ],
              companySpecificIndustryId: 3, // 漁業
            },
            {
              key: "fishingBoatRegistryCopyFilePath",
              page: 1,
              yes: { x: 437, y: 519.5, size: 6 },
              no: { x: 455, y: 519.5, size: 6 },
              children: [
                "fishingBoatRegistryCopyFilePath", // ①漁船原簿謄本の写し
                "fishingBoatRegistrationCertCopyFilePath", // ②漁船登録票の写し
              ],
              companySpecificIndustryId: 3, // 漁業
            },
            {
              key: "councilMembershipCertFilePath", // 協議会の構成員であることの証明書(特定技能所属機関)
              page: 1,
              yes: { x: 437, y: 475.6, size: 6 },
              no: { x: 455, y: 475.6, size: 6 },
              companySpecificIndustryId: 3, // 漁業
            },
            {
              // rsoForeignWorkerAcceptancePledgeFilePath
              key: "unknown", // 漁業分野における特定技能外国人の受入れに関する誓約書(登録支援機関)
              page: 1,
              yes: { x: 437, y: 416.2, size: 6 },
              no: { x: 455, y: 416.2, size: 6 },
              companySpecificIndustryId: 3, // 漁業
              fixedValue: "no",
            },
            // page 2
            // page 3
            // page 4
            // page 5
          ];
          break;
      }
    } else if (employee?.residenceStatusId?.toString() === "2") {
      switch (tableKey) {
        case "table_1":
          templatePdfUrl = "/pdf/renew_visa_table_1_skill_1.pdf";
          divisions = [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR];
          fileFields = [
            {
              key: "unknown", // 特定技能外国人の在留諸申請に係る提出書類一覧表(本表)
              page: 1,
              yes: { x: 433, y: 741, size: 6 },
              no: { x: 451, y: 741, size: 6 },
              fixedValue: "yes",
            },
            {
              key: "unknown", // 在留期間更新許可申請書
              page: 1,
              yes: { x: 433, y: 690, size: 6 },
              no: { x: 451, y: 690, size: 6 },
              fixedValue: "no",
            },
            {
              key: "foreignWorkerCompensationStatementFilePaths", // 特定技能外国人の報酬に関する説明書
              page: 1,
              yes: { x: 433, y: 649, size: 6 },
              no: { x: 451, y: 649, size: 6 },
            },
            {
              key: "employmentContractCopyFilePath", // 特定技能雇用契約書の写し
              page: 1,
              yes: { x: 433, y: 619, size: 6 },
              no: { x: 451, y: 619, size: 6 },
            },
            {
              key: "employmentConditionsCopyFilePaths", // 雇用条件書の写し
              page: 1,
              yes: { x: 433, y: 572, size: 6 },
              no: { x: 451, y: 572, size: 6 },
            },
            {
              key: "wagePaymentFilePath", // 賃金の支払
              page: 1,
              yes: { x: 433, y: 524, size: 6 },
              no: { x: 451, y: 524, size: 6 },
            },
            {
              key: "applicantIndividualResidentTaxCertFilePath", // 申請人の個人住民税の課税証明書
              page: 1,
              yes: { x: 433, y: 498, size: 6 },
              no: { x: 451, y: 498, size: 6 },
            },
            {
              key: "applicantResidentTaxPaymentCertFilePath", // 申請人の住民税の納税証明書
              page: 1,
              yes: { x: 433, y: 456, size: 6 },
              no: { x: 451, y: 456, size: 6 },
            },
            {
              key: "applicantWithholdingSlipCopyFilePath", // 申請人の給与所得の源泉徴収票の写し
              page: 1,
              yes: { x: 433, y: 368, size: 6 },
              no: { x: 451, y: 368, size: 6 },
            },
            {
              key: "applicantHealthInsuranceCardCopyFilePath", // 申請人の国民健康保険被保険者証の写し
              page: 1,
              yes: { x: 433, y: 289, size: 6 },
              no: { x: 451, y: 289, size: 6 },
            },
            {
              key: "applicantHealthInsurancePaymentCertFilePath", // 申請人の国民健康保険料(税)納付証明書
              page: 1,
              yes: { x: 433, y: 248, size: 6 },
              no: { x: 451, y: 248, size: 6 },
            },
            {
              key: "applicantPensionReceiptCopy1FilePath",
              page: 1,
              yes: { x: 433, y: 167, size: 6 },
              no: { x: 451, y: 167, size: 6 },
              children: [
                "applicantPensionReceiptCopy1FilePath", // ①申請人の国民年金保険料領収証書の写し
                "applicantPensionRecordInquiry2FilePath", //②申請人の被保険者記録照会(納付Ⅱ)(被保険者記録照会回答票を含む。)
              ],
            },
            {
              key: "previousApplicationDocumentsFilePath", // 前回申請時に履行すべきであった公的義務に係る書類
              page: 1,
              yes: { x: 433, y: 83, size: 6 },
              no: { x: 451, y: 83, size: 6 },
            },
            {
              key: "publicDutyPledgeFilePath", // 公的義務履行に関する誓約書
              page: 1,
              yes: { x: 433, y: 39, size: 6 },
              no: { x: 451, y: 39, size: 6 },
            },
          ];
          break;
        case "table_2_1":
          templatePdfUrl = "/pdf/renew_visa_table_2_1_skill_1.pdf";
          divisions = [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR];
          fileFields = [
            {
              key: "quarterlyReportCopyFilePath", //四季報の写し又は日本の証券取引所に上場していることを証明する文書（写し）
              page: 1,
              yes: { x: 436.5, y: 707, size: 6 },
              no: { x: 454.5, y: 707, size: 6 },
            },
            {
              key: "innovationCompanyProofFilePath", //高度専門職省令第１条第１項各
              page: 1,
              yes: { x: 436.5, y: 642, size: 6 },
              no: { x: 454.5, y: 642, size: 6 },
            },
            {
              // key: "innovationCompanyProofFilePath", //高度専門職省令第１条第１項各
              key: "certifiedCompanyProofFilePath", //「一定の条件を満たす企業等」
              page: 1,
              yes: { x: 436.5, y: 564.5, size: 6 },
              no: { x: 454.5, y: 564.5, size: 6 },
            },
            {
              key: "annualSalaryReportCopyFilePath", // 前年分の職員の給与所得の源泉
              page: 1,
              yes: { x: 436.5, y: 478.5, size: 6 },
              no: { x: 454.5, y: 478.5, size: 6 },
            },
            {
              key: "immigrationElectronicSystemPledgeFilePath", //出入国在留管理庁電子届出シス
              page: 1,
              yes: { x: 436.5, y: 381.5, size: 6 },
              no: { x: 454.5, y: 381.5, size: 6 },
            },
            {
              key: "documentOmissionPledgeFilePath", //書類の省略に当たっての誓約書
              page: 1,
              yes: { x: 436.5, y: 307, size: 6 },
              no: { x: 454.5, y: 307, size: 6 },
            },
          ];
          break;
        case "table_2_2":
          templatePdfUrl = "/pdf/renew_visa_table_2_2_skill_2.pdf";
          divisions = [COMPANY_DIVISION.CORPORATE];
          fileFields = [
            {
              key: "specificSkillsInstitutionOverviewDocumentFilePath", // 特定技能所属機関概要書
              page: 1,
              yes: { x: -1, y: -1, size: 6 },
              no: { x: -1, y: -1, size: 6 },
            },
            {
              key: "registrationCertFilePath", // 登記事項証明書
              page: 1,
              yes: { x: -1, y: -1, size: 6 },
              no: { x: -1, y: -1, size: 6 },
            },
            {
              key: "executiveResidentRecordCopyFilePath", // 業務執行に関与する役員の住民票の写し
              page: 1,
              yes: { x: -1, y: -1, size: 6 },
              no: { x: -1, y: -1, size: 6 },
            },
            {
              key: "specificSkillsInstitutionOfficerAffidavitFilePath", // 特定技能所属機関の役員に関する誓約書
              page: 1,
              yes: { x: -1, y: -1, size: 6 },
              no: { x: -1, y: -1, size: 6 },
            },
            {
              key: "laborInsuranceNoDelegationFilePath", // 労働保険事務組合に事務委託していない場合
              page: 1,
              yes: { x: -1, y: -1, size: 6 },
              no: { x: -1, y: -1, size: 6 },
            },
            {
              key: "laborInsuranceDelegationFilePath", // 労働保険事務組合に事務委託している場合
              page: 1,
              yes: { x: -1, y: -1, size: 6 },
              no: { x: -1, y: -1, size: 6 },
            },
            {
              key: "socialInsurancePaymentStatusCertFilePath", // 社会保険料納入状況回答票又は健康保険・厚生年金保険料領収証書の写し
              page: 1,
              yes: { x: -1, y: -1, size: 6 },
              no: { x: -1, y: -1, size: 6 },
            },
            {
              key: "taxOfficePaymentCertNo3FilePath", // 税務署発行の納税証明書(その３)
              page: 1,
              yes: { x: -1, y: -1, size: 6 },
              no: { x: -1, y: -1, size: 6 },
            },
            {
              key: "corporateResidentTaxPaymentCertFilePath", // 法人住民税の市町村発行の納税証明書
              page: 1,
              yes: { x: -1, y: -1, size: 6 },
              no: { x: -1, y: -1, size: 6 },
            },
            {
              key: "publicDutyComplianceFilePath", // 公的義務履行に関する説明書
              page: 1,
              yes: { x: -1, y: -1, size: 6 },
              no: { x: -1, y: -1, size: 6 },
            },
          ];
          break;
        case "table_2_3":
          templatePdfUrl = "/pdf/renew_visa_table_2_3_skill_2.pdf";
          divisions = [COMPANY_DIVISION.SOLE_PROPRIETOR];
          fileFields = [
            {
              key: "specificSkillsInstitutionOverviewDocumentFilePath", // 特定技能所属機関概要書
              page: 1,
              yes: { x: -1, y: -1, size: 6 },
              no: { x: -1, y: -1, size: 6 },
            },
            {
              key: "individualBusinessOwnerResidentCertFilePath", // 個人事業主の住民票の写し
              page: 1,
              yes: { x: -1, y: -1, size: 6 },
              no: { x: -1, y: -1, size: 6 },
            },
            {
              key: "laborInsuranceNoDelegationFilePath", // 労働保険事務組合に事務委託していない場合
              page: 1,
              yes: { x: -1, y: -1, size: 6 },
              no: { x: -1, y: -1, size: 6 },
            },
            {
              key: "laborInsuranceDelegationFilePath", // 労働保険事務組合に事務委託している場合
              page: 1,
              yes: { x: -1, y: -1, size: 6 },
              no: { x: -1, y: -1, size: 6 },
            },
            {
              key: "healthInsuranceWelfarePensionCertFilePath", // 健康保険・厚生年金保険の適用事業所の場合
              page: 1,
              yes: { x: -1, y: -1, size: 6 },
              no: { x: -1, y: -1, size: 6 },
            },
            {
              key: "nonHealthInsuranceWelfarePensionCertFilePath", // 個人事業主の国民健康保険被保険者証の写し
              page: 1,
              yes: { x: -1, y: -1, size: 6 },
              no: { x: -1, y: -1, size: 6 },
            },
            {
              key: "individualHealthInsurancePaymentCertFilePath", // 個人事業主の国民健康保険料(税)納付証明書
              page: 1,
              yes: { x: -1, y: -1, size: 6 },
              no: { x: -1, y: -1, size: 6 },
            },
            {
              key: "individualPensionReceiptCopyFilePath", // 個人事業主の国民年金保険料領収証書の写し又は被保険者記録照会(納付Ⅱ)
              page: 1,
              yes: { x: -1, y: -1, size: 6 },
              no: { x: -1, y: -1, size: 6 },
            },
            // page 2
            {
              key: "individualBusinessOwnerTaxOfficePaymentCertNo3FilePath", // 個人事業主の税務署発行の納税証明書(その３)
              page: 2,
              yes: { x: -1, y: -1, size: 6 },
              no: { x: -1, y: -1, size: 6 },
            },
            {
              key: "individualResidentTaxCertFilePath", // 個人事業主の個人住民税の市町村発行の納税証明書
              page: 2,
              yes: { x: -1, y: -1, size: 6 },
              no: { x: -1, y: -1, size: 6 },
            },
            {
              key: "publicDutyComplianceFilePath", // 公的義務履行に関する説明書
              page: 2,
              yes: { x: -1, y: -1, size: 6 },
              no: { x: -1, y: -1, size: 6 },
            },
          ];
          break;
        case "table_3_1":
          templatePdfUrl = "/pdf/renew_visa_table_3_1_skill_2.pdf";
          divisions = [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR];
          fileFields = [
            {
              key: "specificSkillOrganizationOverviewFilePath",
              page: 1,
              companySpecificIndustryId: 11, // ビルクリーニング
              yes: { x: 442, y: 725.5, size: 6 },
              no: { x: 459.5, y: 725.5, size: 6 },
              children: [
                "specificSkillOrganizationOverviewFilePath", // ①建築物清掃業登録証明書
                "buildingEnvHealthMgmtRegistrationCertFilePath", // ②建築物環境衛生総合管理業登録証明書
              ],
            },
            {
              key: "councilMembershipCertFilePath", // 協議会の構成員であることの証明書
              page: 1,
              companySpecificIndustryId: 11, // ビルクリーニング
              yes: { x: 442, y: 681, size: 6 },
              no: { x: 459.5, y: 681, size: 6 },
            },
            {
              key: "councilMembershipCertFilePath", // 協議会の構成員であることの証明書
              page: 1,
              companySpecificIndustryId: 10, //  工業製品製造業 = 素形材・産業機械・電気電子情報関連製造業
              yes: { x: 442, y: 634.8, size: 6 },
              no: { x: 459.5, y: 634.8, size: 6 },
            },
            {
              key: "shipbuildingIndustryConfirmationFilePath", // 造船・舶用工業事業者の確認通知書
              page: 1,
              companySpecificIndustryId: 8, // 造船・舶用工業
              yes: { x: 442, y: 590, size: 6 },
              no: { x: 459.5, y: 590, size: 6 },
            },
            {
              key: "councilMembershipCertFilePath", // 協議会の構成員であることの証明書
              page: 1,
              companySpecificIndustryId: 8, // 造船・舶用工業
              yes: { x: 442, y: 558, size: 6 },
              no: { x: 459.5, y: 558, size: 6 },
            },
            {
              key: "specifiedSkillAutomobileMaintenanceCertFilePath",
              page: 1,
              companySpecificIndustryId: 7, // 自動車整備
              yes: { x: 442, y: 512, size: 6 },
              no: { x: 459.5, y: 512, size: 6 },
              children: [
                "specifiedSkillAutomobileMaintenanceCertFilePath", //①自動車整備分野特定技能協議会入会届出書兼構成員資格証明書（受付印があるもの）
                "specifiedSkillAutomobileMaintenanceIssuranceFilePath", //②自動車整備分野特定技能協議会構成員資格証明書発行申請書（受付印があるもの）
              ],
            },
            {
              key: "councilMembershipCertFilePath", // 協議会の構成員であることの証明書
              page: 1,
              companySpecificIndustryId: 6, // 航空
              yes: { x: 442, y: 467, size: 6 },
              no: { x: 459.5, y: 467, size: 6 },
            },
            {
              key: "hotelBusinessLicenseFilePath", // 旅館業許可証(旅館・ホテル営業許可書)の写し
              page: 1,
              companySpecificIndustryId: 5, // 宿泊
              yes: { x: 442, y: 438, size: 6 },
              no: { x: 459.5, y: 438, size: 6 },
            },
            {
              key: "councilMembershipCertFilePath", // 協議会の構成員であることの証明書
              page: 1,
              companySpecificIndustryId: 5, // 宿泊
              yes: { x: 442, y: 405.5, size: 6 },
              no: { x: 459.5, y: 405.5, size: 6 },
            },
            {
              key: "councilMembershipCertFilePath", // 協議会の構成員であることの証明書
              page: 1,
              companySpecificIndustryId: 2, // 飲食料品製造業
              yes: { x: 442, y: 372, size: 6 },
              no: { x: 459.5, y: 372, size: 6 },
            },
            {
              key: "businessLicenseOrNotificationFormFilePath", // 保健所長の営業許可証又は届出書の写し
              page: 1,
              companySpecificIndustryId: 1, // 外食業
              yes: { x: 442, y: 289, size: 6 },
              no: { x: 459.5, y: 289, size: 6 },
            },
            {
              key: "councilMembershipCertFilePath", // 協議会の構成員であることの証明書
              page: 1,
              companySpecificIndustryId: 1, // 外食業
              yes: { x: 442, y: 208.5, size: 6 },
              no: { x: 459.5, y: 208.5, size: 6 },
            },
          ];
          break;
        case "table_3_2":
          templatePdfUrl = "/pdf/renew_visa_table_3_2_skill_2.pdf";
          divisions = [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR];
          fileFields = [
            {
              key: "councilMembershipCertFilePath", // 協議会の構成員であることの証明書(特定技能所属機関)
              page: 1,
              yes: { x: 437, y: 741.2, size: 6 },
              no: { x: 455, y: 741.2, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            // page 2
            {
              key: "unknown", // 農業又は農業に関連する業務を行っている場合
              page: 2,
              yes: { x: 436.5, y: 651, size: 6 },
              no: { x: 454.5, y: 651, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 地方公共団体等が出資（資本金の過半数）している機関である場合
              page: 2,
              yes: { x: 436.5, y: 569.7, size: 6 },
              no: { x: 454.5, y: 569.7, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 地方公共団体の職員等が役員として在籍している場合
              page: 2,
              yes: { x: 436.5, y: 496, size: 6 },
              no: { x: 454.5, y: 496, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 地方公共団体等が実質的に業務執行に関与している場合
              page: 2,
              yes: { x: 436.5, y: 424.5, size: 6 },
              no: { x: 454.5, y: 424.5, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 国家戦略特別区域法に規定する特定機関である場合
              page: 2,
              yes: { x: 436.5, y: 336, size: 6 },
              no: { x: 454.5, y: 336, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 農業分野において派遣形態で特定技能外国人の受入れ
              page: 2,
              yes: { x: 436.5, y: 266, size: 6 },
              no: { x: 454.5, y: 266, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 労働者派遣事業許可証の写し
              page: 2,
              yes: { x: 436.5, y: 230.5, size: 6 },
              no: { x: 454.5, y: 230.5, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 派遣計画書
              page: 2,
              yes: { x: 436.5, y: 190, size: 6 },
              no: { x: 454.5, y: 190, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 労働者派遣契約書の写し
              page: 2,
              yes: { x: 436.5, y: 152, size: 6 },
              no: { x: 454.5, y: 152, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 就業条件明示書の写し
              page: 2,
              yes: { x: 436.5, y: 115.5, size: 6 },
              no: { x: 454.5, y: 115.5, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            // page 3
            {
              key: "unknown", // 派遣先の概要書（農業分野）
              page: 3,
              yes: { x: 436.5, y: 713, size: 6 },
              no: { x: 454.5, y: 713, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 派遣先事業者誓約書
              page: 3,
              yes: { x: 436.5, y: 680, size: 6 },
              no: { x: 454.5, y: 680, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 労働保険料等納付証明書(未納なし証明)
              page: 3,
              yes: { x: 436.5, y: 610, size: 6 },
              no: { x: 454.5, y: 610, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 労働保険概算・増加概算・確定
              page: 3,
              yes: { x: 436.5, y: 554.5, size: 6 },
              no: { x: 454.5, y: 554.5, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 労働保険事務組合が発行した直
              page: 3,
              yes: { x: 436.5, y: 476.7, size: 6 },
              no: { x: 454.5, y: 476.7, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 社会保険料納入状況回答票又は健康保険・厚生年金保
              page: 3,
              yes: { x: 436.5, y: 374.6, size: 6 },
              no: { x: 454.5, y: 374.6, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 税務署発行の納税証明書(その３)
              page: 3,
              yes: { x: 436.5, y: 305.5, size: 6 },
              no: { x: 454.5, y: 305.5, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 法人住民税の市町村発行の納税証明書
              page: 3,
              yes: { x: 436.5, y: 232, size: 6 },
              no: { x: 454.5, y: 232, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 公的義務履行に関する説明書
              page: 3,
              yes: { x: 436.5, y: 189.4, size: 6 },
              no: { x: 454.5, y: 189.4, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 公的義務履行に関する説明書
              page: 3,
              yes: { x: 436.5, y: 146.6, size: 6 },
              no: { x: 454.5, y: 146.6, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            // page 4
            {
              key: "unknown", // 派遣先の概要書（農業分野）
              page: 4,
              yes: { x: 436.5, y: 714.5, size: 6 },
              no: { x: 454.5, y: 714.5, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 派遣先事業者誓約書
              page: 4,
              yes: { x: 436.5, y: 683, size: 6 },
              no: { x: 454.5, y: 683, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 労働保険料等納付証明書
              page: 4,
              yes: { x: 436.5, y: 614, size: 6 },
              no: { x: 454.5, y: 614, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 労働保険概算・増加概算・確定
              page: 4,
              yes: { x: 436.5, y: 561, size: 6 },
              no: { x: 454.5, y: 561, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 労働保険事務組合が発行した直
              page: 4,
              yes: { x: 436.5, y: 486.3, size: 6 },
              no: { x: 454.5, y: 486.3, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 社会保険料納入状況回答票又は
              page: 4,
              yes: { x: 436.5, y: 368, size: 6 },
              no: { x: 454.5, y: 368, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 個人事業主の国民健康保険被保
              page: 4,
              yes: { x: 436.5, y: 301, size: 6 },
              no: { x: 454.5, y: 301, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 個人事業主の国民健康保険料
              page: 4,
              yes: { x: 436.5, y: 218.5, size: 6 },
              no: { x: 454.5, y: 218.5, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 個人事業主の国民年金保険料領
              page: 4,
              yes: { x: 436.5, y: 122, size: 6 },
              no: { x: 454.5, y: 122, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            // page 5
            {
              key: "unknown", // 個人事業主の税務署発行の納税証明書
              page: 5,
              yes: { x: 436.5, y: 699, size: 6 },
              no: { x: 454.5, y: 699, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 個人事業主の個人住民税の市町村発行の納税証明書(注)直近１年分が必要
              page: 5,
              yes: { x: 436.5, y: 619.7, size: 6 },
              no: { x: 454.5, y: 619.7, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 個人事業主の個人住民税の市町村発行の納税証明書(注)直近２年分が必要
              page: 5,
              yes: { x: 436.5, y: 568, size: 6 },
              no: { x: 454.5, y: 568, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
            {
              key: "unknown", // 公的義務履行に関する説明書
              page: 5,
              yes: { x: 436.5, y: 515.8, size: 6 },
              no: { x: 454.5, y: 515.8, size: 6 },
              companySpecificIndustryId: 4, // 農業
            },
          ];
          break;
        case "table_3_3":
          templatePdfUrl = "/pdf/renew_visa_table_3_3_skill_2_edit.pdf";
          divisions = [COMPANY_DIVISION.CORPORATE, COMPANY_DIVISION.SOLE_PROPRIETOR];
          fileFields = [
            {
              key: "fishingLicenseCopy1FilePath",
              page: 1,
              yes: { x: 437, y: 705.3, size: 6 },
              no: { x: 455, y: 705.3, size: 6 },
              children: [
                "fishingLicenseCopy1FilePath", // ①許可証の写し
                "fishingLicenseCopy2FilePath", // ②免許の指令書の写し
                "fishingLicenseCopy3FilePath", // ③その他許可または免許を受け
              ],
              companySpecificIndustryId: 3, // 漁業
            },
            {
              key: "fishingCooperativeRightsDocumentFilePath",
              page: 1,
              yes: { x: 437, y: 601.2, size: 6 },
              no: { x: 455, y: 601.2, size: 6 },
              children: [
                "fishingCooperativeRightsDocumentFilePath", // ①当該組合の漁業権の内容たる漁業又は養殖業を営むことを確認できる当該組合が発行した書類の写し
                "fishingCooperativeMembershipDocumentFilePath", // ②その他当該組合に所属して漁業又は養殖業を営んでいることが確認できる書類の写し
              ],
              companySpecificIndustryId: 3, // 漁業
            },
            {
              key: "fishingBoatRegistryCopyFilePath",
              page: 1,
              yes: { x: 437, y: 519.5, size: 6 },
              no: { x: 455, y: 519.5, size: 6 },
              children: [
                "fishingBoatRegistryCopyFilePath", // ①漁船原簿謄本の写し
                "fishingBoatRegistrationCertCopyFilePath", // ②漁船登録票の写し
              ],
              companySpecificIndustryId: 3, // 漁業
            },
            {
              key: "councilMembershipCertFilePath", // 協議会の構成員であることの証明書
              page: 1,
              yes: { x: 437, y: 475.6, size: 6 },
              no: { x: 455, y: 475.6, size: 6 },
              companySpecificIndustryId: 3, // 漁業
            },

            // page 2
            // page 3
            // page 4
            // page 5
          ];
          break;
      }
    }

    const circles: TCheckFileFieldResult[] = [];
    const mergedFiles: TMergeRenewVisaFile[] = [];
    for (const fileField of fileFields) {
      const isMatched = files.some((file) => {
        const isUpdated = !!file.filePath || file.existed;
        const isMatchedKey = file.key === fileField.key;
        const notFixedNo = fileField.fixedValue !== "no";
        let isMatchedChildrenKey = false;
        if (fileField.children && fileField.children.length > 0) {
          isMatchedChildrenKey = fileField.children.some((child) => child == file.key);
        }
        let isMatchedCompanySpecificIndustry = true;
        if (fileField.companySpecificIndustryId) {
          isMatchedCompanySpecificIndustry = fileField.companySpecificIndustryId === Number(companySpecificIndustryId);
        }
        let isMatchedDivision = true;
        if (divisions && !divisions.includes(companyDivision)) {
          isMatchedDivision = false;
        }
        return isUpdated && (isMatchedChildrenKey || isMatchedKey) && isMatchedCompanySpecificIndustry && isMatchedDivision && notFixedNo;
      });

      if (fileField.fixedValue === "yes" || isMatched) {
        circles.push({ ...fileField, value: "yes" });
        yesCount++;
        if (fileField.children && fileField.children.length > 0) {
          for (const childKey of fileField.children) {
            const file = files.find((item) => item.key === childKey);
            if (file) {
              mergedFiles.push(file);
            }
          }
        } else {
          const file = files.find((item) => item.key === fileField.key);
          mergedFiles.push(file!);
        }
      } else if (fileField.fixedValue === "no" || !isMatched) {
        circles.push({ ...fileField, value: "no" });
        noCount++;
      }
    }

    return {
      templatePdfUrl,
      circles,
      mergedFiles,
      yesCount,
      noCount,
    };
  };

  const handleCreateFileListTablesPdf = async (
    tableKey: "table_1" | "table_2_1" | "table_2_2" | "table_2_3" | "table_3_1" | "table_3_2" | "table_3_3",
    templatePdfUrl: string,
    circles: TCheckFileFieldResult[],
  ) => {
    const hasMergedFile = circles.find((item) => item.value === "yes");
    if (templatePdfUrl && hasMergedFile) {
      const arrayBuffer = await fetch(templatePdfUrl).then((res) => res.arrayBuffer());
      const bytes = new Uint8Array(arrayBuffer);
      const templatePdf = await PDFDocument.load(bytes);
      const editedPdf = await PDFDocument.create();
      // Copy pages from raw pdf into final pdf
      const copiedPages = await editedPdf.copyPages(templatePdf, templatePdf.getPageIndices());
      copiedPages.forEach((page) => editedPdf.addPage(page));
      for (const circle of circles) {
        const page = editedPdf.getPage(circle.page - 1);
        if (circle.value === "yes") {
          page.drawCircle({
            x: circle.yes.x,
            y: circle.yes.y,
            size: circle.yes.size,
            borderWidth: 1,
            borderColor: rgb(0, 0, 0),
          });
        } else {
          page.drawCircle({
            x: circle.no.x,
            y: circle.no.y,
            size: circle.no.size,
            borderWidth: 1,
            borderColor: rgb(0, 0, 0),
          });
        }
      }
      const pdfBytes = await editedPdf.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const file = new File([blob], `${tableKey}.pdf`, { type: blob.type });
      return file;
    }
    return null;
  };

  const handleMergeUploadedPdfs = async (files: TMergeRenewVisaFile[]) => {
    const filePaths = files.map((item) => item?.filePath).filter((item) => !!item);
    const uploadedFiles = await Promise.all(
      filePaths
        .map((filePath) => {
          if (typeof filePath === "string") {
            return generateFileFromFilePath(filePath!);
          } else if (Array.isArray(filePath)) {
            return filePath?.map((childFilePath) => generateFileFromFilePath(childFilePath!));
          }
        })
        .flat(),
    );
    if (uploadedFiles.length === 0) return null;
    return mergePdfs(uploadedFiles, "merged.pdf");
  };

  const generateMergedPdf = async () => {
    const renewVisaDocument = await fetchRenewVisaDocumentInfoApi(renewVisaDocumentId).then((res) => res.data);
    if (!renewVisaDocument) return null;

    const employee = renewVisaDocument.employee;
    if (!employee) return null;

    const company = employee.company;
    if (!company) return null;

    const basicInfoStepData = renewVisaDocument?.steps?.find(
      (step) => step.masterDocumentStep.stepKey === DOCUMENT_STEP_KEYS.RENEW_VISA_BASIC_INFORMATION,
    );
    if (!basicInfoStepData) return null;
    const { data: basicInfoData } = await fetchRenewVisaBasicInformationDocumentApi(renewVisaDocument.id, basicInfoStepData.id);

    const coverPdf = await handleCreateCoverPagePdf(company, employee);

    const {
      table1Files,
      table2And1Files,
      table2And2Files,
      table2And2FilesUploadedByUser,
      table2And3Files,
      table2And3FilesUploadedByUser,
      table3And1Files,
      table3And2Files,
      table3And3Files,
    } = await handleGetFilesGroupByTable();

    const tableFileGroups: {
      tableFiles: TMergeRenewVisaFile[];
      tableKey: TTableKey;
    }[] = [
      {
        tableFiles: table1Files,
        tableKey: "table_1",
      },
      {
        tableFiles: table2And1Files,
        tableKey: "table_2_1",
      },
      {
        tableFiles: table2And2Files,
        tableKey: "table_2_2",
      },
      {
        tableFiles: table2And3Files,
        tableKey: "table_2_3",
      },
      {
        tableFiles: table3And1Files,
        tableKey: "table_3_1",
      },

      {
        tableFiles: table3And2Files,
        tableKey: "table_3_2",
      },
      {
        tableFiles: table3And3Files,
        tableKey: "table_3_3",
      },
    ];

    const [table1, table2And1, table2And2, table2And3, table3And1, table3And2, table3And3] = await Promise.all(
      tableFileGroups.map(async (item) => {
        const { templatePdfUrl, circles, mergedFiles } = handleCheckFileListTable(employee, company, item.tableKey, item.tableFiles);
        const tablePdf = await handleCreateFileListTablesPdf(item.tableKey, templatePdfUrl, circles);
        const mergedPdf = tablePdf ? await handleMergeUploadedPdfs(mergedFiles) : null;
        return {
          tablePdf,
          mergedPdf,
        };
      }),
    );

    const table2And2UpdatedByUserPdf = table2And2FilesUploadedByUser ? await generateFileFromFilePath(table2And2FilesUploadedByUser) : null;
    const table2And3UpdatedByUserPdf = table2And3FilesUploadedByUser ? await generateFileFromFilePath(table2And3FilesUploadedByUser) : null;

    let mergedList = [];
    if (basicInfoData.conditions.length > 0) {
      // Select one of A~E
      mergedList = [
        coverPdf,

        table1.tablePdf,
        table1.mergedPdf,

        table2And1.tablePdf,
        table2And1.mergedPdf,

        table2And2.tablePdf,
        table2And2.mergedPdf,

        table2And3.tablePdf,
        table2And3.mergedPdf,

        table3And1.tablePdf,
        table3And1.mergedPdf,

        table3And2.tablePdf,
        table3And2.mergedPdf,

        table3And3.tablePdf,
        table3And3.mergedPdf,
      ];
    } else {
      // Don't select any A~E
      mergedList = [
        coverPdf,

        table1.tablePdf,
        table1.mergedPdf,

        table2And1.tablePdf,
        table2And1.mergedPdf,

        table2And2UpdatedByUserPdf,

        table2And3UpdatedByUserPdf,

        table3And1.tablePdf,
        table3And1.mergedPdf,

        table3And2.tablePdf,
        table3And2.mergedPdf,

        table3And3.tablePdf,
        table3And3.mergedPdf,
      ];
    }

    const mergedPdf = await mergePdfs(mergedList, "提出書類の一覧表.pdf");

    return mergedPdf;
  };

  return { generateMergedPdf, getFilesGroupByTable: handleGetFilesGroupByTable, checkFileListTable: handleCheckFileListTable };
};
