import { validator } from "@/utils/validator";
export const companySchema = validator.object().shape({
  companyName: validator.string().trim().emptyToNull().label("氏名又は名称").required(),
  companySpecificIndustryId: validator.number().isSelection().commas().emptyToNull().label("特定産業分野").notRequired(),
  companyFuriganaName: validator.string().trim().emptyToNull().autoTransformToFullWidth().fullWidthOnly().notKanji().label("フリガナ").notRequired(),
  companyIdentificationNumber: validator.string().trim().emptyToNull().autoTransformToHalfWidth().label("法人番号").notRequired(),
  companyZipCode: validator.string().trim().emptyToNull().autoTransformToHalfWidth().zipCode().label("郵便番号").notRequired(),
  companyProvince: validator.string().isSelection().trim().emptyToNull().label("都道府県").notRequired(),
  companyAddress: validator.string().trim().emptyToNull().label("住所（都道府県以外）").notRequired(),
  companyTelNumber: validator.string().trim().emptyToNull().label("電話番号").notRequired(),
});
