import { convertFullWidthToHalfWidth } from "@/utils/pieces";
import * as wanakana from "wanakana";

export const handleKatakanaToFurigana = (value: string) => {
  return wanakana.toHiragana(value, { passRomaji: true });
};

export const convertNumberToZipCode = (value: string) => {
  if (/^[0-9]+$/.test(value)) {
    let formattedValue = value.replace(/[^\d０-９]/g, "");

    if (formattedValue.length > 3) {
      formattedValue = formattedValue.slice(0, 3) + "-" + formattedValue.slice(3);
    }

    if (formattedValue.length > 8) {
      formattedValue = formattedValue.slice(0, 8);
    }

    return formattedValue;
  }
  return value;
};

export const convertZipCodeToNumber = (zipCode: string) => {
  if (!zipCode) return;
  return zipCode.replace("-", "");
};

export const convertNumberToCommas = (value: string) => {
  if (value) {
    const normalizedValue = convertFullWidthToHalfWidth(value.toString());
    if (/^-?[0-9]+(\.[0-9]*)?$/.test(normalizedValue)) {
      const [integerPart, decimalPart] = normalizedValue.split(".");
      const formattedIntegerPart = parseInt(integerPart.replace(/,/g, "")).toLocaleString("en-GB");
      return decimalPart !== undefined ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
    }
  }
  return value;
};

export const convertCommasToNumber = (value: string) => {
  return value.replace(/,/g, "");
};
