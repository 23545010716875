import { TEmployeeRenewVisaDocumentStep } from "@/api/entities/employee-renew-visa-document-step.entity";
import { useEnum } from "@/api/services/global/enum";
import { uploadFileApi } from "@/api/services/global/file";
import { useMe } from "@/api/services/main/auth";
import { fetchEmployeeInfoApi } from "@/api/services/main/employee";
import {
  createRenewVisaUploadAvatarFileDocumentApi,
  updateRenewVisaUploadAvatarFileDocumentApi,
  useGetRenewVisaUploadAvatarFileDocumentInfo,
} from "@/api/services/main/renew-visa";
import { Button } from "@/components/elements/Button";
import { Linkable } from "@/components/elements/Linkable";
import { LoadingArea } from "@/components/elements/Loading/Loading";
import { StatusTag } from "@/components/elements/StatusTag";
import { DATE_TIME_FORMAT } from "@/constants/datetime";
import { FILE_UPLOAD_FOR, MIME_TYPES } from "@/constants/file";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { EMPTY_STR } from "@/constants/string";
import { PreviewAvatarModal } from "@/features/renew-visa/components/applicationStep/UploadAvatar/PreviewAvatarModal";
import { useVisible } from "@/hooks/useVisible";
import { state } from "@/theme/colors";
import { DOCUMENT_STATUS } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { showError } from "@/utils/error";
import { openInNewTab } from "@/utils/export";
import { convertKbToByte } from "@/utils/file";
import { findLabelByValue } from "@/utils/object";
import { toast } from "@/utils/toast";
import { createUrlWithParams } from "@/utils/url";
import { Stack, styled, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";

type TUploadAvatarStepProps = {
  stepData: TEmployeeRenewVisaDocumentStep;
  employeeId: number;
  readonly: boolean;
};

export const UploadAvatarStep: FC<TUploadAvatarStepProps> = ({ stepData, employeeId, readonly }) => {
  const { id: documentStepId } = stepData;

  const [uploading, setUploading] = useState<boolean>(false);

  const { renewVisaDocumentId } = useParams();
  const previewAvatarModal = useVisible();
  const queryClient = useQueryClient();
  const { data: renewVisaDocumentStatuses } = useEnum("ERenewVisaDocumentStatus");

  const { data: uploadAvatarFileData, isLoading: isLoadingUploadAvatarFile } = useGetRenewVisaUploadAvatarFileDocumentInfo({
    documentStepId,
  });

  const { data: meResult } = useMe();

  const handleGetLinkAvatar = async () => {
    if (!employeeId || !meResult) return "";
    const rs = await fetchEmployeeInfoApi(employeeId);
    const url = createUrlWithParams(`${window.location.origin}/employee/${rs?.data.id}/upload-avatar`, {
      token: rs?.data.secretToken,
      companyId: rs?.data.companyId,
      renewVisaDocumentStepId: documentStepId,
      userId: meResult.data.id,
    });
    openInNewTab(url);
  };

  const handleUploadFile = async (file: File) => {
    if (file.size > convertKbToByte(50)) {
      toast.error("ファイルサイズは50KB以内でお願いします。");
      return;
    }
    if (!renewVisaDocumentId || !documentStepId) return;
    try {
      setUploading(true);
      const rs = await uploadFileApi({ file, uploadFor: FILE_UPLOAD_FOR.EMPLOYEE_AVATAR_FILE });
      if (uploadAvatarFileData?.updater) {
        await updateRenewVisaUploadAvatarFileDocumentApi(renewVisaDocumentId, documentStepId, {
          avatarFilePath: rs.data.filePath,
        });
      } else {
        await createRenewVisaUploadAvatarFileDocumentApi(renewVisaDocumentId, documentStepId, {
          avatarFilePath: rs.data.filePath,
        });
      }
      toast.success("画像がアップロードされました。");
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_DETAIL] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_UPLOAD_AVATAR_FILE] });
    } catch (error) {
      showError(error);
    } finally {
      setUploading(false);
    }
  };

  if (isLoadingUploadAvatarFile) return <LoadingArea />;

  return (
    <>
      <Stack p="16px" sx={{ border: "1px solid #E6E7E8", borderRadius: "8px", marginTop: "16px" }}>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" gap={10}>
          <Typography variant="body14Bold">本人の顔写真</Typography>
          <Stack flexDirection="row" gap={2}>
            <Button size="sm" component="label" isLoading={uploading} disabled={readonly || uploading}>
              アップロード
              <VisuallyHiddenInput
                type="file"
                accept={MIME_TYPES.JPEG.join(",")}
                onChange={(event) => event.target.files && handleUploadFile(event.target.files[0])}
                onClick={(event) => {
                  event.currentTarget.value = "";
                }}
                multiple={false}
              />
            </Button>
            <Button size="sm" onClick={() => handleGetLinkAvatar()} disabled={readonly}>
              URLから提出依頼
            </Button>
          </Stack>
        </Stack>
        <Stack mt={2} flexDirection="row" justifyContent="space-between">
          <Stack flexDirection="row" alignItems="flex-start" gap={10}>
            <Stack gap={1}>
              <Typography variant="body14Bold">更新日時</Typography>
              <Typography variant="body14">
                {uploadAvatarFileData?.updatedAt ? dayjs(uploadAvatarFileData.updatedAt).format(DATE_TIME_FORMAT) : EMPTY_STR.DATE}
              </Typography>
            </Stack>
            <Stack gap={1}>
              <Typography variant="body14Bold">最終更新者</Typography>
              <Typography variant="body14">{uploadAvatarFileData?.updater?.fullName || EMPTY_STR.TEXT}</Typography>
            </Stack>
            <Stack gap={1}>
              <Typography variant="body14Bold">ステータス</Typography>
              <StatusTag
                variant="bold"
                status={uploadAvatarFileData?.status ?? DOCUMENT_STATUS.IN_PROGRESS}
                title={findLabelByValue(renewVisaDocumentStatuses, uploadAvatarFileData?.status ?? DOCUMENT_STATUS.IN_PROGRESS) || EMPTY_STR.TEXT}
              />
            </Stack>
          </Stack>
          <Button variant="outline" size="sm" disabled={!uploadAvatarFileData?.avatarFilePath} onClick={previewAvatarModal.open}>
            顔写真のプレビュー
          </Button>
        </Stack>
        <Typography component="p" variant="body14" mt={2}>
          3ヶ月以内の正面を向いた写真を用意してください。画像を直接アップロード、またはURLから提出依頼より本人にアップロードを依頼することもできます。
          <br />
          顔写真の詳細については、
          <Linkable
            component="span"
            sx={{ color: state.link_1, textDecoration: "underline" }}
            href="https://www.moj.go.jp/isa/applications/status/photo_info_00002.html"
            target="_blank"
            width="100%"
          >
            出入国在留管理庁「提出写真の規格」
          </Linkable>
          をご確認ください。
        </Typography>
      </Stack>

      <PreviewAvatarModal
        avatarFilePath={uploadAvatarFileData?.avatarFilePath}
        isOpen={previewAvatarModal.visible}
        onClose={previewAvatarModal.close}
      />
    </>
  );
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
