import { useGetRenewVisaDocumentInfo, useGetRenewVisaEmployeeApplicationDocumentInfo } from "@/api/services/main/renew-visa";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { TopPage } from "@/components/elements/TopPage";
import { EmployeeApplicationForm } from "@/features/renew-visa/components/applicationStep/formApplication/employeeApplication/EmployeeApplicationForm";
import { convertResToEmployeeApplicationFormData } from "@/features/renew-visa/utils/convertResToEmployeeApplicationFormData";
import { AppRoutes } from "@/routes/config";
import { FCC } from "@/types/common";
import { DOCUMENT_STATUS, RENEW_VISA_DOCUMENT_STATUS } from "@/types/enum";
import { getFullName } from "@/utils/string";
import { getDynamicRoute } from "@/utils/url";
import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

type TRenewVisaPresentEmployeeApplicationPageProps = {};

export const RenewVisaPresentEmployeeApplicationPage: FCC<TRenewVisaPresentEmployeeApplicationPageProps> = ({}) => {
  const { renewVisaDocumentId } = useParams();

  const { data: renewVisaDocument, isLoading: isLoadingRenewVisaDocument } = useGetRenewVisaDocumentInfo();

  const { data: employeeApplicationData, isLoading: isLoadingEmployeeApplication } = useGetRenewVisaEmployeeApplicationDocumentInfo();

  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (!employeeApplicationData) {
      setIsEditable(true);
    } else {
      if (employeeApplicationData.status === DOCUMENT_STATUS.IN_PROGRESS) {
        setIsEditable(true);
      } else if (employeeApplicationData.status === DOCUMENT_STATUS.COMPLETE) {
        setIsEditable(false);
      }
    }
  }, [employeeApplicationData]);

  const isLoading = useMemo(
    () => isLoadingRenewVisaDocument || isLoadingEmployeeApplication,
    [isLoadingRenewVisaDocument, isLoadingEmployeeApplication],
  );

  const isFinishRenewVisaDocument = useMemo(() => renewVisaDocument?.status === RENEW_VISA_DOCUMENT_STATUS.COMPLETE, [renewVisaDocument]);

  if (isLoading) return <LoadingPage />;
  return (
    <Box maxWidth={946}>
      <TopPage
        title={`${getFullName({ firstName: renewVisaDocument?.employee.firstName ?? "", middleName: renewVisaDocument?.employee.middleName, lastName: renewVisaDocument?.employee.lastName ?? "" })}さんの申請データの作成`}
        backUrl={getDynamicRoute(AppRoutes.renewVisaPresent, { renewVisaDocumentId })}
        isDraft={employeeApplicationData?.isDraft}
      />
      <EmployeeApplicationForm
        readonly={isFinishRenewVisaDocument}
        updater={employeeApplicationData?.updater}
        isDraft={Boolean(employeeApplicationData?.isDraft)}
        isEditable={isEditable}
        setIsEditable={setIsEditable}
        previousValues={convertResToEmployeeApplicationFormData(renewVisaDocument?.employee, employeeApplicationData)}
      />
    </Box>
  );
};
