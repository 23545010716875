import { Button } from "@/components/elements/Button";
import { Modal } from "@/components/elements/Modal";
import { RefreshEmployeeInfoBtn } from "@/features/renew-visa/components/applicationStep/formApplication/employeeApplication/RefreshEmployeeInfoBtn";
import { TEmployeeApplicationForm } from "@/features/renew-visa/types/employeeApplicationForm";
import { AppRoutes } from "@/routes/config";
import { getDynamicRoute } from "@/utils/url";
import { Box, Stack, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Link } from "react-router-dom";

type TPreventEmployeeApplicationModalProps = {};

export const PreventEmployeeApplicationModal: FC<TPreventEmployeeApplicationModalProps> = () => {
  const methods = useFormContext<TEmployeeApplicationForm>();

  const { control } = methods;

  const employeeIdWatchField = useWatch({ control, name: "employeeId" });
  const employeeInfoPassportCode = useWatch({ control, name: "employeeInfoPassportCode" });
  const employeeInfoPassportExpiryDateWatchField = useWatch({ control, name: "employeeInfoPassportExpiryDate" });
  const contractStartDateWatchField = useWatch({ control, name: "contractStartDate" });
  const contractEndDateWatchField = useWatch({ control, name: "contractEndDate" });
  const relatedActivityJapaneseCertificationTwoWatchField = useWatch({ control, name: "relatedActivityJapaneseCertificationTwo" });

  const alwaysOpen =
    !employeeInfoPassportCode ||
    !employeeInfoPassportExpiryDateWatchField ||
    !contractStartDateWatchField ||
    !contractEndDateWatchField ||
    !relatedActivityJapaneseCertificationTwoWatchField;

  const employeeEditRouteWithHash = useMemo(() => {
    if (!employeeInfoPassportCode) return `${getDynamicRoute(AppRoutes.employeeEdit, { id: employeeIdWatchField })}#passport_code_input`;
    else if (!employeeInfoPassportExpiryDateWatchField)
      return `${getDynamicRoute(AppRoutes.employeeEdit, { id: employeeIdWatchField })}#passport_expiry_date_input`;
    else if (!contractStartDateWatchField)
      return `${getDynamicRoute(AppRoutes.employeeEdit, { id: employeeIdWatchField })}#contract_start_date_input`;
    else if (!contractEndDateWatchField) return `${getDynamicRoute(AppRoutes.employeeEdit, { id: employeeIdWatchField })}#contract_end_date_input`;
    else if (!relatedActivityJapaneseCertificationTwoWatchField)
      return `${getDynamicRoute(AppRoutes.employeeEdit, { id: employeeIdWatchField })}#career_japanese_certification_one_input`;
  }, [
    employeeInfoPassportCode,
    employeeInfoPassportExpiryDateWatchField,
    contractStartDateWatchField,
    contractEndDateWatchField,
    relatedActivityJapaneseCertificationTwoWatchField,
  ]);

  return (
    <Modal
      isOpen={alwaysOpen}
      enableFooter={false}
      title="在留期間更新には、次の特定技能外国人に関する情報をご入力ください。"
      enableCloseIcon={false}
    >
      <Box component="ul" paddingTop={2} paddingBottom={4}>
        {!employeeInfoPassportCode && (
          <Typography component="li" variant="body14">
            旅券番号
          </Typography>
        )}
        {!employeeInfoPassportExpiryDateWatchField && (
          <Typography component="li" variant="body14">
            旅券有効期限
          </Typography>
        )}
        {!contractStartDateWatchField && (
          <Typography component="li" variant="body14">
            雇用契約期間（始期）
          </Typography>
        )}
        {!contractEndDateWatchField && (
          <Typography component="li" variant="body14">
            雇用契約期間（終期）
          </Typography>
        )}
        {!relatedActivityJapaneseCertificationTwoWatchField && (
          <Typography component="li" variant="body14">
            日本語能力　証明１
          </Typography>
        )}
      </Box>
      <Stack flexDirection="row" justifyContent="center" alignItems="center" gap={2}>
        <RefreshEmployeeInfoBtn />
        <Button variant="primary" target="_blank" size="sm" component={Link} to={employeeEditRouteWithHash}>
          特定技能外国人の編集へ
        </Button>
      </Stack>
    </Modal>
  );
};
