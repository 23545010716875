import { useGetMainOccupationList, useGetSpecificIndustryList } from "@/api/services/global/master";
import { useCompanyBranchList, useCompanyInfo } from "@/api/services/main/company";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { Select } from "@/components/elements/Select";
import { FILE_UPLOAD_FOR, MIME_TYPES } from "@/constants/file";
import { UploadFileBtnForm } from "@/features/employee/components/employee-forms/UploadFileBtnForm";
import { TEmployeeForm } from "@/features/employee/types/employeeForm";
import { useScrollToElement } from "@/hooks/useScrollToElement";
import { dayjs } from "@/utils/dayjs";
import { toNumber } from "@/utils/number";
import { uniqueArray } from "@/utils/pieces";
import { Grid, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";

export const ContractForm = () => {
  const { control } = useFormContext<TEmployeeForm>();

  const { elmId: contractStartDateElmId } = useScrollToElement("contract_start_date_input");
  const { elmId: contractEndDateElmId } = useScrollToElement("contract_end_date_input");

  const residenceStatusIdWatchField = useWatch({
    control,
    name: "residenceStatusId",
  });

  const contractSpecificBusinessClassificationThreeIdWatchField = useWatch({
    control,
    name: "contractSpecificBusinessClassificationThreeId",
  });

  const contractSpecificBusinessClassificationTwoIdWatchField = useWatch({
    control,
    name: "contractSpecificBusinessClassificationTwoId",
  });

  const contractSpecificBusinessClassificationOneIdWatchField = useWatch({
    control,
    name: "contractSpecificBusinessClassificationOneId",
  });

  const { data: companyData } = useCompanyInfo();

  const { contractCompanyBranchOptions, isLoading: isLoadingCompanyBranch } = useCompanyBranchList();

  const { isLoading: isLoadingMainOccupation, contractMainOccupationOptions } = useGetMainOccupationList();

  const { data: specificIndustry, isLoading: isLoadingSpecificIndustry } = useGetSpecificIndustryList();

  const contractSpecificBusinessClassificationOptions = useMemo(() => {
    if (!specificIndustry || !companyData) return [];

    const companySpecificIndustryIds = [
      companyData.data.specificIndustryId,
      companyData.data.specificIndustryTwoId,
      companyData.data.specificIndustryThreeId,
    ];

    const companySpecificBusinessClassificationIds = uniqueArray([
      ...(companyData.data.specificBusinessClassificationIds ? companyData.data.specificBusinessClassificationIds.map((item) => toNumber(item)) : []),
      ...(companyData.data.specificBusinessClassificationTwoIds
        ? companyData.data.specificBusinessClassificationTwoIds.map((item) => toNumber(item))
        : []),
      ...(companyData.data.specificBusinessClassificationThreeIds
        ? companyData.data.specificBusinessClassificationThreeIds.map((item) => toNumber(item))
        : []),
    ]).filter((item) => !!item);

    const specificIndustryWithCompanyAndResidenceStatusFilter = specificIndustry
      .filter(
        (item) =>
          toNumber(residenceStatusIdWatchField) === toNumber(item.residenceStatusId) &&
          companySpecificIndustryIds.includes(item.companySpecificIndustryId),
      )
      .sort(
        (a, b) => companySpecificIndustryIds.indexOf(a.companySpecificIndustryId) - companySpecificIndustryIds.indexOf(b.companySpecificIndustryId),
      );
    const options = specificIndustryWithCompanyAndResidenceStatusFilter.flatMap((item) =>
      item
        ? item.specificBusinessClassifications
            .filter((item) => companySpecificBusinessClassificationIds.includes(toNumber(item.companySpecificBusinessClassificationId)))
            .map((sbc) => ({
              label: `${item.companySpecificIndustry.companySpecificIndustry} - ${sbc.companySpecificBusinessClassification.companySpecificBusinessClassificationName}`,
              value: sbc.id,
              hidden: sbc.isHidden,
            }))
        : [],
    );
    return options;
  }, [specificIndustry, residenceStatusIdWatchField, companyData]);

  const contractSpecificBusinessClassificationOneOptionsWithFilter = useMemo(
    () =>
      contractSpecificBusinessClassificationOptions.filter(
        (item) =>
          toNumber(item.value) !== toNumber(contractSpecificBusinessClassificationTwoIdWatchField) &&
          toNumber(item.value) !== toNumber(contractSpecificBusinessClassificationThreeIdWatchField),
      ),
    [
      contractSpecificBusinessClassificationOptions,
      contractSpecificBusinessClassificationOneIdWatchField,
      contractSpecificBusinessClassificationTwoIdWatchField,
      contractSpecificBusinessClassificationThreeIdWatchField,
    ],
  );

  const contractSpecificBusinessClassificationTwoOptionsWithFilter = useMemo(
    () =>
      contractSpecificBusinessClassificationOptions.filter(
        (item) =>
          toNumber(item.value) !== toNumber(contractSpecificBusinessClassificationOneIdWatchField) &&
          toNumber(item.value) !== toNumber(contractSpecificBusinessClassificationThreeIdWatchField),
      ),
    [
      contractSpecificBusinessClassificationOptions,
      contractSpecificBusinessClassificationOneIdWatchField,
      contractSpecificBusinessClassificationTwoIdWatchField,
      contractSpecificBusinessClassificationThreeIdWatchField,
    ],
  );

  const contractSpecificBusinessClassificationThreeOptionsWithFilter = useMemo(
    () =>
      contractSpecificBusinessClassificationOptions.filter(
        (item) =>
          toNumber(item.value) !== toNumber(contractSpecificBusinessClassificationOneIdWatchField) &&
          toNumber(item.value) !== toNumber(contractSpecificBusinessClassificationTwoIdWatchField),
      ),
    [
      contractSpecificBusinessClassificationOptions,
      contractSpecificBusinessClassificationOneIdWatchField,
      contractSpecificBusinessClassificationTwoIdWatchField,
      contractSpecificBusinessClassificationThreeIdWatchField,
    ],
  );

  return (
    <Stack gap={1}>
      <InnerBox title="特定技能契約情報">
        <Grid item xs={4}>
          <Typography mb={2}>契約書関係</Typography>
        </Grid>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={4}>
            <FormField control={control} name="contractEmploymentFile">
              <UploadFileBtnForm
                multiple
                uploadFor={FILE_UPLOAD_FOR.EMPLOYEE_CONTRACT_EMPLOYMENT_FILE}
                rules={{ acceptMimeTypes: [MIME_TYPES.IMAGE, MIME_TYPES.PDF, MIME_TYPES.EXCEL].flat(), maxFile: 5 }}
              />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="contractConditionFile">
              <UploadFileBtnForm
                multiple
                uploadFor={FILE_UPLOAD_FOR.EMPLOYEE_CONTRACT_EMPLOYMENT_FILE}
                rules={{ acceptMimeTypes: [MIME_TYPES.IMAGE, MIME_TYPES.PDF, MIME_TYPES.EXCEL].flat(), maxFile: 5 }}
              />
            </FormField>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={8}>
            <FormField control={control} name="contractCompanyBranchId">
              <Select loading={isLoadingCompanyBranch} options={contractCompanyBranchOptions} />
            </FormField>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <FormField id={contractStartDateElmId} control={control} name="contractStartDate">
              <InputDatePicker minDate={dayjs("1900/01/01")} maxDate={dayjs("2099/12/31")} />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField id={contractEndDateElmId} control={control} name="contractEndDate">
              <InputDatePicker minDate={dayjs("1900/01/01")} maxDate={dayjs("2099/12/31")} />
            </FormField>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={8}>
            <FormField control={control} required={residenceStatusIdWatchField !== "3"} name="contractSpecificBusinessClassificationOneId">
              <Select options={contractSpecificBusinessClassificationOneOptionsWithFilter} loading={isLoadingSpecificIndustry} />
            </FormField>
          </Grid>
          <Grid item xs={8}>
            <FormField control={control} name="contractSpecificBusinessClassificationTwoId">
              <Select options={contractSpecificBusinessClassificationTwoOptionsWithFilter} loading={isLoadingSpecificIndustry} />
            </FormField>
          </Grid>
          <Grid item xs={8}>
            <FormField control={control} name="contractSpecificBusinessClassificationThreeId">
              <Select options={contractSpecificBusinessClassificationThreeOptionsWithFilter} loading={isLoadingSpecificIndustry} />
            </FormField>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <FormField control={control} name="contractMainOccupationId">
              <Select options={contractMainOccupationOptions} loading={isLoadingMainOccupation} />
            </FormField>
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={4}>
            <FormField control={control} name="contractMonthlySalary">
              <Input />
            </FormField>
          </Grid>
        </Grid>
      </InnerBox>
    </Stack>
  );
};
